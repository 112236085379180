import { InputUpdateSavedArea } from "@biggeo/bg-server-lib/datascape-ai";
import { Switch } from "@biggeo/bg-ui/lab";
import includes from "lodash/includes";

interface MapUseViewProps {
    readonly savedAreaId: number;
    readonly mapUse: boolean;
    readonly selectedRows: number[];
    readonly toggleMapUse: (
        value: boolean,
        savedArea: InputUpdateSavedArea
    ) => Promise<void>;
}

const MapUseView = ({
    savedAreaId,
    mapUse,
    selectedRows,
    toggleMapUse,
}: MapUseViewProps) => {
    return (
        <Switch
            disabled={includes(selectedRows, savedAreaId)}
            size="small"
            switched={mapUse}
            onSwitchChange={(v) => toggleMapUse(!v, { id: savedAreaId })}
        />
    );
};

export default MapUseView;
