import {
    AvatarGroup,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Link } from "react-router-dom";
import { Routes } from "../navigation/redux/model";

const SlackIntegrationCard = ({
    isTemporary,
    startAvatarURL,
    endAvatarURL,
}: {
    readonly isTemporary?: boolean;
    readonly startAvatarURL: string;
    readonly endAvatarURL: string;
}) => {
    return (
        isTemporary && (
            <Link to={Routes.joinBigGeoSlack} target="_blank">
                <Stack
                    gap={2}
                    sx={{
                        padding: 3,
                        backgroundColor: (theme) =>
                            theme.palette.background.main,

                        borderRadius: (theme) => theme.radius.xs3,
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],

                        "&:hover": {
                            boxShadow: (theme) => theme.shadows.allAround,
                        },
                    }}
                >
                    <AvatarGroup size="xs">
                        <ThumbnailAvatar src={startAvatarURL} />
                        <ThumbnailAvatar src={endAvatarURL} />
                    </AvatarGroup>
                    <Stack>
                        <Typography variant={"body4"} fontWeight={"semibold"}>
                            Join BigGeo Slack Community
                        </Typography>
                        <Typography
                            variant={"body4"}
                            color={"disabled"}
                            colorSet={"container"}
                            invertColors
                        >
                            Get help from our team and freely share your ideas
                        </Typography>
                    </Stack>
                </Stack>
            </Link>
        )
    );
};

export default SlackIntegrationCard;
