import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isString from "lodash/isString";
import omit from "lodash/omit";
import some from "lodash/some";
import mapboxgl, { GeoJSONSource, PointLike } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    MapEngineSelectInput,
    commonActions,
} from "../../../common/redux/model";
import { setLayersFilter } from "../../utils/style-utils";
import { getMapFeatures } from "../../utils/utils";
import { useMap } from "../context";
import { InitializeMapProps } from "./hooks";
import { CustomShapeSource } from "./style-hooks";

export type SelectModeHookProps = {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    draw: React.MutableRefObject<MapboxDraw | null>;
    onShapeStyleLoad: (i: {
        map: mapboxgl.Map;
        features?: GeoJSON.FeatureCollection<
            GeoJSON.Geometry,
            GeoJSON.GeoJsonProperties
        >;
    }) => GeoJSONSource;
    isLoaded: boolean;
    isDrawMode: boolean;
} & Pick<InitializeMapProps, "handleSelectedShapes">;

export type SelectModeHookReturnType = {
    select: boolean;
    selectMode: (mode: boolean) => void;
    isDragging: boolean;
};

export const useSelectMode = ({
    map,
    draw,
    handleSelectedShapes,
    onShapeStyleLoad,
    isLoaded,
    isDrawMode,
}: SelectModeHookProps): SelectModeHookReturnType => {
    const dispatch = useDispatch();
    const { dispatch: mapDispatch } = useMap();
    const [select, setSelect] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const setReduxSelectMode = (p: MapEngineSelectInput) => {
        dispatch(commonActions.setSelectMode({ engineId: "1", selectMode: p }));
    };

    const sourceName = CustomShapeSource.select;
    const current = map.current;

    const features: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    > = pipe(
        getMapFeatures(draw, isLoaded),
        (
            data: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[]
        ) => {
            // We're passing featureId & geometry to properties so we can catch
            // it in the event and pass it to selectedPolygons.
            return pipe(
                data,
                A.map((feature) => ({
                    ...feature,
                    properties: {
                        ...feature.properties,
                        featureId: feature.id,
                        geometry: feature.geometry,
                        bbox: feature.bbox,
                    },
                }))
            );
        },
        (data) => ({ type: "FeatureCollection", features: data })
    );

    const exitSelectMode = (previousMode: boolean) => {
        const emptyFeatures: GeoJSON.FeatureCollection<
            GeoJSON.Geometry,
            GeoJSON.GeoJsonProperties
        > = {
            type: "FeatureCollection",
            features: [],
        };

        const source = map?.current?.getSource(sourceName) as GeoJSONSource;

        source?.setData(emptyFeatures);
        handleSelectedShapes(emptyFeatures);

        if (map.current && isLoaded) {
            setLayersFilter({ map: map.current, isSelectMode: false });
            map.current.getCanvas().style.cursor = "";

            const hasSelectedShapes = some(
                getMapFeatures(draw, isLoaded),
                (f) => isEqual(f.properties?.selected, true)
            );

            if (
                draw.current &&
                !isEmpty(features.features) &&
                isEqual(previousMode, true) &&
                hasSelectedShapes
            ) {
                draw.current?.add({
                    type: "FeatureCollection",
                    features: pipe(
                        features.features,
                        A.map((f) => ({
                            ...f,
                            properties: {
                                ...f.properties,
                                selected: false,
                            },
                        }))
                    ),
                });
            }
        }
    };

    const selectMode = (mode: boolean) => {
        const previousMode = select;
        setSelect(mode);
        setReduxSelectMode({ mode });

        mapDispatch?.({
            type: "SET_MAP",
            values: {
                modes: {
                    select: {
                        isSelectMode: mode,
                        selectMode: (m) => {
                            if (isEqual(m, false)) {
                                exitSelectMode(previousMode);
                            }

                            setSelect(m);
                            setReduxSelectMode({ mode: m });
                        },
                    },
                },
            },
        });

        if (isEqual(mode, false)) {
            exitSelectMode(previousMode);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            current &&
            isEqual(select, true) &&
            isLoaded &&
            !isEmpty(features.features)
        ) {
            // Add layers to style the shapes and catch the event on it
            const source = onShapeStyleLoad({
                map: current,
                features,
            });

            const canvas = map.current?.getCanvasContainer();

            const onClick = (
                e: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();

                const bbox: [PointLike, PointLike] = [
                    [e.point.x - 5, e.point.y - 5],
                    [e.point.x + 5, e.point.y + 5],
                ];

                // Find features intersecting the bounding box.
                const selectedFeatures = current.queryRenderedFeatures(bbox, {
                    layers: [`map-shapes-fill-${sourceName}`],
                });

                const selectedFeature: GeoJSON.Feature<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                > = {
                    id:
                        selectedFeatures[0].id ||
                        selectedFeatures[0].properties?.featureId,
                    type: MapboxDraw.constants.geojsonTypes.FEATURE,
                    properties: {
                        ...omit(selectedFeatures[0].properties, [
                            "geometry",
                            "bbox",
                        ]),
                    },
                    geometry: JSON.parse(
                        selectedFeatures[0].properties?.geometry
                    ),
                };

                const selectedId = selectedFeature.id;
                const selectedProperty = selectedFeature.properties?.selected;
                const selected = isNil(selectedProperty)
                    ? true
                    : !selectedProperty;

                if (isString(selectedId)) {
                    draw.current?.changeMode("simple_select", {
                        featureIds: [selectedId],
                    });

                    draw.current?.setFeatureProperty(
                        selectedId,
                        "selected",
                        selected
                    );

                    const updatedFeatures: GeoJSON.FeatureCollection<
                        GeoJSON.Geometry,
                        GeoJSON.GeoJsonProperties
                    > = {
                        type: "FeatureCollection",
                        features: pipe(
                            features.features,
                            A.map((f) =>
                                f.id === selectedId
                                    ? {
                                          ...f,
                                          properties: {
                                              ...f.properties,
                                              selected,
                                          },
                                      }
                                    : f
                            )
                        ),
                    };

                    draw.current?.set(updatedFeatures);

                    source.setData(updatedFeatures);

                    handleSelectedShapes(selectedFeature);

                    if (map.current) {
                        setLayersFilter({
                            map: map.current,
                            isSelectMode: true,
                        });
                    }
                    setReduxSelectMode({ selectedShapeId: selectedId });
                }
            };

            const onMouseEnter = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();
                current.getCanvas().style.cursor = "pointer";

                const shapeId = e.features?.[0].id;

                if (isString(shapeId)) {
                    draw.current?.changeMode("simple_select", {
                        featureIds: [shapeId],
                    });
                }
            };

            const onMouseLeave = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();
                current.getCanvas().style.cursor = "";
            };

            const onTouchStart = (
                e: mapboxgl.MapTouchEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();

                const selected = draw.current?.getSelected().features?.[0];
                const shapeId = selected?.id;

                // Deleted outside saved area.
                const isDeleted = selected?.properties?.deleted || false;

                setIsDragging(false);

                if (isString(shapeId) && !isDeleted) {
                    draw.current?.changeMode("simple_select", {
                        featureIds: [shapeId],
                    });
                }
            };

            const onTouchEnd = (
                e: mapboxgl.MapTouchEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const shapeId = e.features?.[0].id;

                // Deleted outside saved area.
                const isDeleted = e.features?.[0].properties?.deleted || false;

                if (isString(shapeId) && !isDeleted) {
                    draw.current?.changeMode("simple_select", {
                        featureIds: [shapeId],
                    });
                }

                // This was a tap
                if (!isDragging) {
                    onClick(e);
                }

                setIsDragging(false);
            };

            const onTouchMove = () => {
                setIsDragging(true);
            };

            current.on("click", `map-shapes-fill-${sourceName}`, onClick);
            current.on(
                "mouseenter",
                `map-shapes-fill-${sourceName}`,
                onMouseEnter
            );
            current.on(
                "mouseleave",
                `map-shapes-fill-${sourceName}`,
                onMouseLeave
            );

            current.on(
                "touchstart",
                `map-shapes-fill-${sourceName}`,
                onTouchStart
            );
            canvas?.addEventListener("touchmove", onTouchMove);
            current.on("touchend", `map-shapes-fill-${sourceName}`, onTouchEnd);

            return () => {
                if (current) {
                    current.off(
                        "click",
                        `map-shapes-fill-${sourceName}`,
                        onClick
                    );
                    current.off(
                        "mouseenter",
                        `map-shapes-fill-${sourceName}`,
                        onMouseEnter
                    );
                    current.off(
                        "mouseleave",
                        `map-shapes-fill-${sourceName}`,
                        onMouseLeave
                    );

                    current.off(
                        "touchstart",
                        `map-shapes-fill-${sourceName}`,
                        onTouchStart
                    );
                    canvas?.removeEventListener("touchmove", onTouchMove);
                    current.off(
                        "touchend",
                        `map-shapes-fill-${sourceName}`,
                        onTouchEnd
                    );
                }
            };
        }
    }, [current, features, select, isLoaded, draw.current, isDragging]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // Change cursor's UI to default since we can't drag outside of select mode.
        if (
            current &&
            isEqual(select, false) &&
            isEqual(isDrawMode, false) &&
            !isEmpty(features.features)
        ) {
            const onClick = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const features = map.current?.queryRenderedFeatures(e.point, {
                    layers: [
                        "gl-map-shapes-fill.hot",
                        "gl-map-shapes-fill.cold",
                    ],
                });

                e.preventDefault();

                if (!isEmpty(features)) {
                    current.getCanvas().style.cursor = "default";
                }
            };

            current.on("click", onClick);

            return () => {
                if (current) {
                    current.off("click", onClick);
                }
            };
        }
    }, [select, current, isDrawMode, features.features.length]);

    return { select, selectMode, isDragging };
};
