import {
    VerifiedAccessKeyResponse,
    VerifiedAccessKeyStatus,
} from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    Button,
    Stack,
    StickyFabPlacementHelper,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { BillingType } from "../containers/MarketplaceUpgradeContainer.tsx";
import MarketplaceUpgradeFormView from "./MarketplaceUpgradeFormView.tsx";

interface IUpgradeForm {
    readonly navigate: (to?: string) => void;
    readonly confirm: (input: UpgradeInfo) => void;
    readonly loading: boolean;
    readonly verifyAccessKey: (
        accessKey: string,
        onSuccess: (data: VerifiedAccessKeyResponse) => void
    ) => void;
    readonly billingType: BillingType;
    readonly setBillingType: (type: BillingType) => void;
}

export type UpgradeInfo = {
    status: VerifiedAccessKeyStatus;
    accessKey: string;
    message: string;
};

const MarketplaceUpgradeForm = ({
    confirm,
    navigate,
    loading,
    verifyAccessKey,
    billingType,
    setBillingType,
}: IUpgradeForm) => {
    const initialValues: UpgradeInfo = {
        status: VerifiedAccessKeyStatus.Failed,
        accessKey: "",
        message: "",
    };

    return (
        <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={(values) => {
                confirm(values);
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object(
                    billingType === "AccessKey"
                        ? {
                              accessKey: Zod.string(),
                              status: Zod.nativeEnum(
                                  VerifiedAccessKeyStatus
                              ).refine(
                                  (val) =>
                                      val === VerifiedAccessKeyStatus.Success
                              ),
                          }
                        : {}
                )
            )}
        >
            {({
                values,
                setValues,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting,
            }) => {
                const onChange = (i: Partial<UpgradeInfo>) => {
                    setValues((p) => ({ ...p, ...i }));
                };

                return (
                    <CenteredNestedLayoutWithHeader>
                        <Stack gap={4}>
                            <Stack gap={0.5}>
                                <Typography variant="title1" fontWeight="bold">
                                    Your Order
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                >
                                    See the details of your order below
                                </Typography>
                            </Stack>
                            <MarketplaceUpgradeFormView
                                values={values}
                                onChange={onChange}
                                verifyAccessKey={verifyAccessKey}
                                billingType={billingType}
                                setBillingType={setBillingType}
                            />
                            <StickyFabPlacementHelper
                                placement="right"
                                sx={{
                                    paddingY: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.background.container,
                                }}
                            >
                                <Stack
                                    flexDirection="row"
                                    justifyContent="flex-end"
                                >
                                    <WithLoading
                                        loading={loading}
                                        text={"Creating..."}
                                    >
                                        <Button
                                            type="submit"
                                            disabled={
                                                billingType === "AccessKey" &&
                                                (!isValid ||
                                                    !dirty ||
                                                    isSubmitting)
                                            }
                                            onClick={() => handleSubmit()}
                                        >
                                            Confirm Order
                                        </Button>
                                    </WithLoading>
                                </Stack>
                            </StickyFabPlacementHelper>
                        </Stack>
                    </CenteredNestedLayoutWithHeader>
                );
            }}
        </Formik>
    );
};

export default MarketplaceUpgradeForm;
