import { FlexScrollArea, FlexScrollAreaContainer } from "@biggeo/bg-ui/lab";
import kebabCase from "lodash/kebabCase";
import { useReducer, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { Consumers } from "../../common/redux/model";
import { MapHeader } from "../../components/MapHeader";
import { Routes } from "../../navigation/redux/model";
import {
    getSavedAreaIdFromParams,
    getSavedViewIdFromParams,
} from "../../utils/utils";
import MapConfiguration from "../containers/MapConfiguration";
import MapSavedViews from "../containers/MapSavedViews";
import { useRenderControls } from "../hooks/render-data-hooks";
import MapMainContainer from "../mapbox/containers/MapMainContainer";
import { MapContext, MapContextDataset, MapReducer } from "../mapbox/context";

export enum MapTabs {
    map = "map",
    configuration = "configuration",
    savedViews = "savedViews",
}

interface IMapViewWrapper {
    readonly currentTab?: MapTabs;
    readonly activeConsumption: Consumers;
    readonly setConsumption: (consumption: Consumers) => void;
    readonly mapTemplateId: number;
    readonly isSavedAreaForm?: boolean;
    readonly isFromMarketplace?: boolean;
    readonly datasets: MapContextDataset[];
}

const MapViewWrapper = ({
    currentTab,
    activeConsumption,
    setConsumption,
    mapTemplateId,
    isSavedAreaForm,
    isFromMarketplace,
    datasets,
}: IMapViewWrapper) => {
    const toPage = useNavigate();
    const tab = currentTab ?? MapTabs.map;

    const controlProps = useRenderControls();
    const [openSaveViewPopper, setOpenSaveViewPopper] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const savedAreaId = Number.parseInt(getSavedAreaIdFromParams(searchParams));
    const savedViewId = Number.parseInt(getSavedViewIdFromParams(searchParams));

    const map = useRef<mapboxgl.Map | null>(null);
    const draw = useRef<MapboxDraw | null>(null);
    const [state, dispatch] = useReducer(MapReducer, {
        map,
        draw,
        isLoaded: false,
        mapStates: [],
        selectedShapes: {
            type: "FeatureCollection",
            features: [],
        },
        filters: [],
        mapStyle: undefined,
        datasets: savedViewId ? [] : datasets,
    });

    const handleMapTabs = (t: MapTabs) => {
        toPage(`${Routes.mapView}/${mapTemplateId}/${kebabCase(t)}`);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <MapContext.Provider value={{ ...state, dispatch }}>
                <FlexScrollAreaContainer width="100%">
                    {!isFromMarketplace && (
                        <MapHeader
                            currentTab={tab}
                            handleMapTabs={handleMapTabs}
                            saveView={() => setOpenSaveViewPopper(true)}
                        />
                    )}
                    <FlexScrollArea flexDirection="column">
                        {match(tab)
                            .with(MapTabs.configuration, () => (
                                <MapConfiguration
                                    mapTemplateId={mapTemplateId}
                                    toPage={toPage}
                                    isSavedAreaForm={isSavedAreaForm}
                                />
                            ))
                            .with(MapTabs.savedViews, () => (
                                <MapSavedViews
                                    mapTemplateId={mapTemplateId}
                                    toPage={toPage}
                                />
                            ))
                            .with(MapTabs.map, () => (
                                <MapMainContainer
                                    {...controlProps}
                                    tab={tab}
                                    mapTemplateId={mapTemplateId}
                                    activeConsumption={activeConsumption}
                                    setConsumption={setConsumption}
                                    setOpenSaveViewPopper={
                                        setOpenSaveViewPopper
                                    }
                                    openSaveViewPopper={openSaveViewPopper}
                                    isFromMarketplace={isFromMarketplace}
                                    savedAreaId={savedAreaId}
                                    savedViewId={savedViewId}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                />
                            ))
                            .exhaustive()}
                    </FlexScrollArea>
                </FlexScrollAreaContainer>
            </MapContext.Provider>
        </DndProvider>
    );
};

export default MapViewWrapper;
