import { Chip, IconAvatar } from "@biggeo/bg-ui/lab";
import { VelocityIcon } from "@biggeo/bg-ui/lab/icons";
import { useNavigate } from "react-router";
import { Routes } from "../../navigation/redux/model";
import { hasBgVelocity } from "../redux/hooks";

export type VelocityButtonProps = {
    hasVelocity?: boolean;
    onClick?: () => void;
};

export const VelocityButton = ({
    hasVelocity,
    onClick,
}: VelocityButtonProps) => {
    const velocity = hasVelocity !== undefined ? hasVelocity : hasBgVelocity();
    const navigate = useNavigate();

    const handleGetVelocity = () => navigate(Routes.upgrade);

    return (
        <Chip
            variant="filled"
            color={velocity ? "background" : "primary"}
            rounded
            density="dense"
            startNode={
                <IconAvatar size={5} sx={{ flexShrink: 0 }}>
                    <VelocityIcon size="xxs" />
                </IconAvatar>
            }
            readonly={velocity}
            slotProps={{
                label: {
                    sx: {
                        color: velocity
                            ? (theme) => theme.palette.disabled.onContainer
                            : "inherit",
                    },
                },
            }}
            onClick={onClick ?? handleGetVelocity}
        >
            {velocity ? "Enabled" : "Get Velocity"}
        </Chip>
    );
};
