import { DataSource } from "@biggeo/bg-server-lib/datascape-ai";
import {
    EmptyState,
    MarketplaceBanner,
    MarketplaceDatasetCard,
} from "@biggeo/bg-ui";
import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
} from "@biggeo/bg-ui/lab";
import { DatabaseOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { formatNumberWithCommas } from "../../utils/utils";

export type AvailableDataSource = DataSource & {
    readonly isPurchased?: boolean;
    readonly fkProviderId?: string;
};
export type MarketplaceDatasetsProps = {
    readonly datasources: readonly AvailableDataSource[];
};

export const MarketplaceDatasets = ({
    datasources,
}: MarketplaceDatasetsProps) => {
    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea gap={4} flexDirection="column" sx={{ padding: 4 }}>
                <MarketplaceBanner
                    title="Datasets"
                    subtitle="Find indexed and ready to go datasets from your favorite data providers"
                />
                {pipe(
                    datasources,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.fold(
                        () => (
                            <EmptyState
                                title="No Datasets Added Yet"
                                subtitle="When data providers publish dataset listings, they will appear here."
                                icon={<DatabaseOutline size="md" />}
                            />
                        ),
                        (datasource) => (
                            <Grid container spacing={4} alignItems="stretch">
                                {pipe(
                                    datasource,
                                    toNonReadonlyArray,
                                    A.map((dataset) => (
                                        <Grid
                                            item
                                            key={dataset.id}
                                            sx={{ display: "flex" }}
                                            xl={4}
                                            md={6}
                                            xs={12}
                                        >
                                            <MarketplaceDatasetCard
                                                productId={dataset.id}
                                                bgIndexed={dataset.isConnected}
                                                description={
                                                    dataset.description || ""
                                                }
                                                size={`${formatNumberWithCommas(dataset.size)} rows`}
                                                source={{
                                                    image: "",
                                                    name: "",
                                                }}
                                                title={dataset.collectionName}
                                                isAdmin={false}
                                                onConnect={() => {
                                                    console.log("onConnect");
                                                }}
                                                onRequest={({
                                                    type,
                                                    productId,
                                                }) => {
                                                    console.log(
                                                        "onRequest",
                                                        type,
                                                        productId
                                                    );
                                                }}
                                                onContactSales={() => {
                                                    // TODO: Implement business logic for contacting sales
                                                }}
                                                isPurchased={
                                                    dataset.isPurchased
                                                }
                                            />
                                        </Grid>
                                    ))
                                )}
                            </Grid>
                        )
                    )
                )}
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
