import type {
    InputPolygon,
    SavedArea,
} from "@biggeo/bg-server-lib/datascape-ai";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import type { GeoJSONSource } from "mapbox-gl";
import { useEffect } from "react";
import { snapToView } from "../../../utils/utils";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import {
    SavedPolygonSource,
    getInputPolygon,
    getMapFeatures,
    getOutsideArea,
    getPolygonsThatOverlapOtherPolygons,
} from "../../utils/utils";
import { DEFAULT_SHAPE_COLOR, DEFAULT_SHAPE_OPACITY } from "./style-hooks";

export const OUTSIDE_AREA_LAYER_ID = "background-mask";
export const SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID =
    "saved-polygons-additional-outline";

export type SavedPolygonType = {
    source: SavedPolygonSource;
    polygons: readonly SavedArea[];
    isConflict?: boolean;
};

export type SavedPolygonsHookProps = {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    savedPolygons: SavedPolygonType;
    handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    draw: React.MutableRefObject<MapboxDraw | null>;
    isLoaded: boolean;
};

export const useSavedPolygon = ({
    map,
    savedPolygons,
    handleSavedPolygons,
    draw,
    isLoaded,
}: SavedPolygonsHookProps) => {
    const drawnPolygons = pipe(
        getMapFeatures(draw, isLoaded),
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        A.map((feature: any) => getInputPolygon(feature))
    );

    const onSavedAreasChange = (savedPolygons: SavedPolygonType) => {
        const current = map.current;

        if (current && isLoaded) {
            const saved = savedPolygons.polygons;

            const source = map.current?.getSource(
                "saved-polygons"
            ) as GeoJSONSource;

            const maskSource = map.current?.getSource(
                "saved-polygons-background"
            ) as GeoJSONSource;

            if (!isEmpty(saved)) {
                const { outsideArea, savedAreaPolygons } =
                    getOutsideArea(saved);

                const savedPolygonsCollection = {
                    type: MapboxDraw.constants.geojsonTypes.FEATURE_COLLECTION,
                    features: savedAreaPolygons,
                };

                if (source) {
                    source.setData(savedPolygonsCollection);
                }

                if (maskSource) {
                    maskSource.setData(outsideArea);
                }

                const selectedPolygons = pipe(
                    saved,
                    toNonReadonlyArray,
                    A.map(({ id, geometries }) => ({
                        id,
                        geometries,
                    })),
                    A.flatMap(({ id, geometries }) =>
                        pipe(
                            geometries,
                            A.map((g) => ({
                                id,
                                polygon: {
                                    inners: [],
                                    outer: {
                                        points: pipe(
                                            g.coordinates,
                                            // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
                                            A.flatMap((c) => c),
                                            A.map((c) => ({
                                                latitude: c[1],
                                                longitude: c[0],
                                            }))
                                        ),
                                    },
                                    properties: g.properties || undefined,
                                },
                            }))
                        )
                    )
                );

                const polygons = pipe(
                    selectedPolygons,
                    A.map(
                        ({ polygon }): InputPolygon => ({
                            inners: polygon.inners,
                            outer: polygon.outer,
                            properties: polygon.properties || undefined,
                        })
                    )
                );

                const multiPolygons = selectedPolygons.map((polygon, i) => ({
                    id: `${polygon.id}-${i}`,
                    inners: polygon.polygon.inners,
                    outer: polygon.polygon.outer,
                    properties: polygon.polygon.properties,
                }));

                // Only show datasets for the saved areas that don't have an
                // inner drawn polygon.
                const polygonsWithInners = getPolygonsThatOverlapOtherPolygons(
                    drawnPolygons,
                    multiPolygons
                );

                if (!isEmpty(polygonsWithInners)) {
                    const polygonsWithoutInnerPolygons = pipe(
                        multiPolygons,
                        A.filter((p) => !polygonsWithInners.includes(p))
                    );

                    handleSavedPolygons(
                        pipe(
                            polygonsWithoutInnerPolygons,
                            A.concat(drawnPolygons)
                        )
                    );
                } else {
                    handleSavedPolygons(multiPolygons);
                }

                snapToView({
                    polygons: [
                        ...polygons,
                        ...pipe(
                            drawnPolygons,
                            A.map((d) => ({
                                inners: d.inners,
                                outer: d.outer,
                                properties: d.properties || undefined,
                            }))
                        ),
                    ],
                    map,
                });
            } else {
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const defaultData: any = {
                    type: "FeatureCollection",
                    features: [],
                } as const;

                if (source) {
                    source.setData(defaultData);
                }

                if (maskSource) {
                    maskSource.setData(defaultData);
                }
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isEqual(savedPolygons.source, SavedPolygonSource.savedArea)) {
            onSavedAreasChange(savedPolygons);
        }
    }, [savedPolygons, drawnPolygons.length, isLoaded]);

    const onStyleLoad = (map: mapboxgl.Map) => {
        map.addSource("saved-polygons", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addSource("saved-polygons-background", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });

        map.addLayer({
            id: OUTSIDE_AREA_LAYER_ID,
            type: "fill",
            source: "saved-polygons-background",
            interactive: false,
            paint: {
                "fill-color": "#D0D5DD",
                "fill-opacity": 0.5,
            },
        });

        map.addLayer({
            id: "saved-polygons-outline",
            type: "line",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "line-color": [
                    "coalesce",
                    ["get", "stroke-color"],
                    DEFAULT_SHAPE_COLOR,
                ],
                "line-width": ["coalesce", ["get", "stroke-width"], 2],
                "line-opacity": ["coalesce", ["get", "stroke-opacity"], 1],
            },
        });

        // An additional invisible outline to stop the `not-allowed`
        // cursor from showing once it gets close to a saved area.
        map.addLayer({
            id: SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID,
            type: "line",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "line-color": DEFAULT_SHAPE_COLOR,
                "line-width": 10,
                "line-opacity": 0,
            },
        });

        map.addLayer({
            id: "saved-polygons-fill",
            type: "fill",
            source: "saved-polygons",
            interactive: false,
            paint: {
                "fill-color": [
                    "coalesce",
                    ["get", "fill-color"],
                    DEFAULT_SHAPE_COLOR,
                ],
                "fill-opacity": [
                    "coalesce",
                    ["get", "fill-opacity"],
                    DEFAULT_SHAPE_OPACITY,
                ],
            },
        });
    };

    return { onStyleLoad };
};
