import {
    DataSource,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    EmptyScreen,
    IFilterSearchPaginate,
    Typography,
} from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import some from "lodash/some";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { Routes } from "../../../navigation/redux/model";
import MapDatasetsConfigGrid from "./MapDatasetsConfigGrid";

interface IMapDatasetsConfigView {
    readonly mapTemplateDatasets: MapTemplateDatasetExtended[];
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly route?: "data" | "configuration";
    readonly setMapTemplateDataset: (i: {
        mapTemplateDatasetId: number;
        mapTemplateDataset?: MapTemplateDataset;
        dataSource?: DataSource;
    }) => void;
}

const MapDatasetsConfigView = ({
    mapTemplateDatasets,
    filterSearchPaginateProps,
    route,
    setMapTemplateDataset,
}: IMapDatasetsConfigView) => {
    const isRunningOnSF = isAppRunningOnSF();
    const toPage = useNavigate();
    const { mapTemplateId } = useParams();
    const UNINDEXED_DATASET_INFO =
        "Your datasets that need to be indexed by BigGeo first will appear here.";
    const No_DATASET_INFO =
        "You don't have any datasets from your data management area enabled for this map template.";

    const isDataRoute = isEqual(route, "data");

    return pipe(
        mapTemplateDatasets,
        O.fromPredicate((d: MapTemplateDatasetExtended[]) =>
            some(
                d,
                (item: MapTemplateDatasetExtended) => !isEmpty(item.dataSource)
            )
        ),
        O.fold(
            () => {
                const leanMoreRoute =
                    "https://datascape.featurebase.app/help/articles/6375162-use-map-datasets";

                return (
                    <EmptyScreen
                        title={
                            isDataRoute
                                ? `No Datasets ${mapTemplateId ? "Enabled" : "Available"}`
                                : "No Datasets Enabled"
                        }
                        subtitle={
                            (isDataRoute &&
                                (mapTemplateId
                                    ? No_DATASET_INFO
                                    : UNINDEXED_DATASET_INFO)) ||
                            No_DATASET_INFO
                        }
                        image={
                            isDataRoute
                                ? "https://biggeo.blob.core.windows.net/media/DataManagmentEmpty.png"
                                : "https://biggeo.blob.core.windows.net/test/f0896fd5-e036-2957-9c1c-61dfa1ff8cf9.png.png"
                        }
                        buttons={[
                            {
                                startNode: <AddOutline />,
                                onClick: () => {
                                    toPage(
                                        `${Routes.mapTemplatesManage}/${mapTemplateId}`
                                    );
                                },
                                children: "Enable Datasets",
                                variant: "filled",
                                color: "primary",
                            },
                            {
                                onClick: () => {
                                    if (isDataRoute)
                                        toPage(`${Routes.data}/available`);
                                },
                                children: isDataRoute ? (
                                    "Index Data"
                                ) : (
                                    <Link to={leanMoreRoute}>
                                        <Typography
                                            variant="body3"
                                            fontWeight="semibold"
                                        >
                                            Learn More
                                        </Typography>
                                    </Link>
                                ),
                            },
                        ]}
                    />
                );
            },
            (data) => (
                <MapDatasetsConfigGrid
                    route={route}
                    mapTemplateDatasets={data}
                    filterSearchPaginateProps={filterSearchPaginateProps}
                    setMapTemplateDataset={setMapTemplateDataset}
                    isRunningOnSF={isRunningOnSF}
                />
            )
        )
    );
};

export default MapDatasetsConfigView;
