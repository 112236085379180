import { MapTemplateExtended } from "@biggeo/bg-server-lib/datascape-ai";
import { WithLoading } from "@biggeo/bg-ui";
import {
    BasicDataWithSelect,
    Button,
    Checkbox,
    Grid,
    IconAvatar,
    OverflowingTypography,
    Stack,
    ThumbnailAvatar,
} from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { DatasetAccessKeyCellItem } from "../../data-access-keys/components/DatasetAccessKeyCellItem";
import { Routes } from "../../navigation/redux/model";
import { CallBacksType } from "../../utils/types";

const AddDatasetWithSubMenu = ({
    filteredExistingDataset,
    localDataList,
    loading,
    save,
    handleOnCheck,
    toPage,
}: {
    filteredExistingDataset: BasicDataWithSelect[];
    localDataList: string[];
    loading: boolean;
    save: (
        input: string[],
        callbacks?: CallBacksType<MapTemplateExtended>
    ) => void;
    handleOnCheck: (input: BasicDataWithSelect) => void;
    toPage: (url: string) => void;
}) => {
    return (
        <Stack>
            <Stack sx={{ padding: 2 }}>
                <DatasetAccessKeyCellItem
                    onClick={() => toPage(Routes.accessKeys)}
                />
            </Stack>
            <Stack
                gap={1}
                width={"100%"}
                sx={{
                    borderTop: filteredExistingDataset.length !== 0 ? 1 : 0,
                    borderColor: (theme) => theme.palette.stroke[100],
                    overflow: "auto",
                    breakpoints: {
                        cmd: {
                            maxHeight: 60,
                        },
                        xs: {
                            maxHeight: 20,
                        },
                    },
                }}
            >
                {pipe(
                    filteredExistingDataset,
                    toNonReadonlyArray,
                    A.map((data) => {
                        return (
                            <Grid
                                key={data.id}
                                container
                                sx={{
                                    alignItems: "center",
                                    gap: 1,
                                    paddingY: 1,
                                    paddingX: 2,
                                }}
                            >
                                <Checkbox
                                    checked={data.select}
                                    onChange={(_, select) => {
                                        handleOnCheck({
                                            ...data,
                                            select,
                                        });
                                    }}
                                />

                                <IconAvatar
                                    size="sm"
                                    sx={{
                                        borderRadius: (theme) =>
                                            theme.radius.default,
                                        backgroundColor: (theme) =>
                                            theme.palette.surface.container,
                                    }}
                                >
                                    <ThumbnailAvatar
                                        size="xs"
                                        src={data.image}
                                        alt={data.title}
                                    />
                                </IconAvatar>

                                <Grid item xs minWidth={0}>
                                    <OverflowingTypography
                                        fontWeight="semibold"
                                        variant="body3"
                                        truncate
                                    >
                                        {data.title}
                                    </OverflowingTypography>
                                </Grid>
                            </Grid>
                        );
                    })
                )}
            </Stack>
            {filteredExistingDataset.length !== 0 && (
                <Stack sx={{ padding: 2 }}>
                    <WithLoading loading={loading} text={"Saving Changes..."}>
                        <Button
                            disabled={localDataList.length === 0}
                            type="submit"
                            onClick={() => save(localDataList)}
                        >
                            Save Changes
                        </Button>
                    </WithLoading>
                </Stack>
            )}
        </Stack>
    );
};

export default AddDatasetWithSubMenu;
