import { WithPartialValues } from "@biggeo/bg-utils";
import { MapContextDataset, useMap } from "./map";

export type SetDatasetContextType = {
    addDatasets: (values: MapContextDataset | MapContextDataset[]) => void;
    updateDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => void;
    removeDataset: (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => void;
    overrideDatasets: (values: MapContextDataset[]) => void;
};

export const setDatasetContext = (): SetDatasetContextType => {
    const { dispatch } = useMap();

    const addDatasets = (values: MapContextDataset | MapContextDataset[]) => {
        dispatch?.({ type: "ADD_DATASETS", values });
    };

    const updateDataset = (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => {
        dispatch?.({ type: "UPDATE_DATASET", values });
    };

    const removeDataset = (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => {
        dispatch?.({ type: "REMOVE_DATASET", values });
    };

    const overrideDatasets = (values: MapContextDataset[]) => {
        dispatch?.({ type: "OVERRIDE_DATASETS", values });
    };

    return { addDatasets, updateDataset, removeDataset, overrideDatasets };
};
