import { ExitIntentModal } from "@biggeo/bg-ui";

import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";
import { compose } from "redux";
import { useNavigation } from "../../map/mapbox/context";
import { modalActions } from "../../modal/redux/model";

export type OnPageLeaveProps = React.PropsWithChildren<{
    readonly trigger?: boolean;
    readonly ids?: string[];
    readonly discard?: () => void;
    readonly save: (i: { navigate: () => void }) => void;
    readonly modal?: JSX.Element;
}>;

export const OnPageLeave = ({
    children,
    modal,
    trigger = true,
    ids,
    discard,
    save,
}: OnPageLeaveProps) => {
    const { location } = useNavigation();
    const [proceed, setProceed] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const openModal = compose(dispatch, modalActions.openModal);
    const closeModal = compose(dispatch, modalActions.closeModal);

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            isEqual(trigger, true) &&
            !isEqual(currentLocation.pathname, nextLocation.pathname)
        );
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const open = useCallback(() => {
        const nextPath = blocker.location?.pathname;

        const toNextPath = () => {
            if (nextPath) {
                navigate(nextPath);
                closeModal();
            }
        };

        return openModal({
            modalType: "new-dialog",
            component: modal ?? (
                <ExitIntentModal
                    discard={() => {
                        discard?.();
                        setProceed(true);
                        toNextPath();
                    }}
                    save={() => {
                        save({
                            navigate: () => {
                                setProceed(true);
                                toNextPath();
                            },
                        });
                    }}
                />
            ),
            dialogProps: {
                variant: "centered-small",
            },
        });
    }, [blocker, modal, proceed]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const onRouteChange = useCallback(() => {
        if (isEqual(trigger, true) && isEqual(proceed, false)) {
            open();
            blocker.reset?.();
            return;
        }
    }, [location, blocker, trigger, proceed]);

    /*
        Gets triggered when the user navigates in-app.
    */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            isEqual(trigger, true) &&
            location &&
            blocker.state === "blocked" &&
            isEqual(proceed, false)
        ) {
            onRouteChange();
        }

        if (isEqual(trigger, false) || isEqual(proceed, true)) {
            blocker.proceed?.();
        }
    }, [location, blocker, trigger, proceed]);

    /*
        Gets triggered when the user closes the tab or browser.
    */
    useEffect(() => {
        const handleBeforeUnload = () => {
            return trigger;
        };

        if (typeof window !== "undefined") {
            window.onbeforeunload = handleBeforeUnload;
        }

        return () => {
            if (typeof window !== "undefined") {
                window.onbeforeunload = null;
            }
        };
    }, [trigger]);

    /*
        Gets triggered when the user clicks on specific button ids.
    */
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        pipe(
            ids,
            O.fromNullable,
            O.fold(
                () => null,
                (buttonIds) =>
                    pipe(
                        trigger,
                        O.fromPredicate((x) => x),
                        O.fold(
                            () => null,
                            () =>
                                pipe(
                                    buttonIds,
                                    A.map((id) => {
                                        const button =
                                            document.getElementById(id);

                                        if (button) {
                                            button.addEventListener(
                                                "click",
                                                () => {
                                                    open();
                                                }
                                            );

                                            return () => {
                                                button.removeEventListener(
                                                    "click",
                                                    () => {
                                                        open();
                                                    }
                                                );
                                            };
                                        }
                                    })
                                )
                        )
                    )
            )
        );
    }, [ids, trigger]);

    return children;
};
