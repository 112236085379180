import {
    BlogCard,
    Box,
    Button,
    CellItem,
    ChangeLogListItem,
    Grid,
    HorizontalScroller,
    MarketingCard,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    AddOutline,
    ArrowForwardOutline,
    DatabaseOutline,
    DraftOutline,
    KeyOutline,
    MapOutline,
    OpenInNewOutline,
} from "@biggeo/bg-ui/lab/icons";
import { DoubleColumnLayout } from "@biggeo/bg-ui/lab/layouts";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../navigation/redux/model.ts";
import { temporaryResources } from "../dummy-data.tsx";
import { BannerUser, Blog, ChangeLog, Resource, UseCase } from "../types.ts";
import { DatascapeDemoBanner } from "./DatascapeDemoBanner.tsx";
import { MinimalHeader } from "./MinimalHeader.tsx";
import { ResourceCard } from "./ResourceCard.tsx";
import TemporaryHomePage from "./TemporaryHomePage.tsx";
import { UseCaseCard } from "./UseCaseCard.tsx";

type HomePageProps = {
    readonly isTemporary?: boolean;
    readonly title: string;
    readonly useCases: readonly UseCase[];
    readonly changeLogs: readonly ChangeLog[];
    readonly blogs: readonly Blog[];
    readonly resources: readonly Resource[];
    readonly bannerUsers: readonly BannerUser[];
};

const HomeContainer = ({
    title,
    changeLogs,
    useCases,
    blogs,
    resources,
    bannerUsers,
    isTemporary,
}: HomePageProps) => {
    const toPage = useNavigate();

    return (
        <DoubleColumnLayout
            rightWidth={75}
            rightHeader={"Details"}
            right={
                <Stack
                    width="100%"
                    gap={4}
                    sx={{
                        padding: 4,
                        borderLeft: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                    }}
                >
                    {/* TODO: Replace with real marketing content */}
                    <MarketingCard
                        img={
                            "https://biggeo.blob.core.windows.net/media/home-marketing-image.png"
                        }
                        title="What is Datascape"
                        description="BigGeo Datascape provides cutting-edge visualization and analysis tools for geospatial data."
                        cta="Learn More"
                    />
                    <Typography variant="body3" fontWeight="bold">
                        Get Started
                    </Typography>
                    <CellItem
                        title="Add Dataset"
                        variant="outlined"
                        density="dense"
                        startNode={<DatabaseOutline size="xs" />}
                        endNode={
                            <AddOutline
                                color="disabled"
                                colorSet="container"
                                invertColors
                                size="xs"
                            />
                        }
                        onClick={() => toPage(`${Routes.data}/un-indexed`)}
                    />
                    <CellItem
                        title="Create map template"
                        variant="outlined"
                        density="dense"
                        startNode={<MapOutline size="xs" />}
                        endNode={
                            <AddOutline
                                color="disabled"
                                colorSet="container"
                                invertColors
                                size="xs"
                            />
                        }
                        onClick={() => toPage(Routes.mapTemplatesCreate)}
                    />
                    {isTemporary && (
                        <Link to={Routes.installationGuide} target={"_blank"}>
                            <CellItem
                                variant="outlined"
                                title="Snowflake Installation Guides"
                                density="dense"
                                startNode={<DraftOutline size="xs" />}
                                endNode={
                                    <AddOutline
                                        color="disabled"
                                        colorSet="container"
                                        invertColors
                                        size="xs"
                                    />
                                }
                            />
                        </Link>
                    )}
                    <Link to={Routes.accessKeys}>
                        <CellItem
                            title="Dataset Access Key"
                            variant="outlined"
                            density="dense"
                            startNode={<KeyOutline size="xs" />}
                            endNode={
                                <AddOutline
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                    size="xs"
                                />
                            }
                        />
                    </Link>
                    {/* TODO Redirect/onClick */}
                    {/* TODO: Add Empty UI or hide this section if there are no change logs to justify the min height on the scroll area */}
                    {!isTemporary && (
                        <>
                            <Typography variant="body3" fontWeight="bold">
                                Change Log
                            </Typography>
                            <Box
                                sx={{
                                    paddingY: 2,
                                    border: 1,
                                    borderColor: (theme) =>
                                        theme.palette.stroke[100],
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                }}
                            >
                                <Stack
                                    sx={{
                                        paddingX: 2,
                                        overflow: "auto",
                                        maxHeight: 120,
                                    }}
                                >
                                    {changeLogs.map((changeLog) => (
                                        <ChangeLogListItem
                                            key={changeLog.id}
                                            date={changeLog.date}
                                            title={changeLog.title}
                                            description={changeLog.description}
                                            //TODO Redirect/onClick
                                            onClickLearnMore={() =>
                                                changeLog.onClick(changeLog.id)
                                            }
                                        />
                                    ))}
                                </Stack>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    sx={{ padding: 2 }}
                                >
                                    <Button
                                        variant="minimal"
                                        endNode={<ArrowForwardOutline />}
                                        //TODO Redirect/onClick
                                        onClick={() => null}
                                    >
                                        View All
                                    </Button>
                                </Grid>
                            </Box>
                        </>
                    )}
                </Stack>
            }
        >
            <Stack
                gap={4}
                sx={{
                    padding: 4,
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                }}
            >
                <Typography variant={"h6"} fontWeight={"bold"}>
                    {title}
                </Typography>
                <DatascapeDemoBanner
                    status={"Active"}
                    users={bannerUsers}
                    isTemporary={isTemporary}
                />
                {isTemporary ? (
                    <TemporaryHomePage resources={temporaryResources} />
                ) : (
                    <>
                        <MinimalHeader
                            title={"Use cases"}
                            endNode={
                                <Button
                                    variant={"minimal"}
                                    color={"primary"}
                                    endNode={<OpenInNewOutline />}
                                >
                                    View All
                                </Button>
                            }
                        />

                        <HorizontalScroller>
                            <Grid container flexWrap="nowrap" gap={4}>
                                {useCases.map((useCase) => (
                                    <Grid key={useCase.id} item width={77.5}>
                                        <UseCaseCard
                                            imgUrl={useCase.imgUrl}
                                            title={useCase.title}
                                            onClick={() =>
                                                useCase.onClick(useCase.id)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </HorizontalScroller>
                        <MinimalHeader
                            title={"What's new"}
                            endNode={
                                <Button
                                    variant={"minimal"}
                                    color={"primary"}
                                    endNode={<OpenInNewOutline />}
                                >
                                    Visit Blog
                                </Button>
                            }
                        />
                        <HorizontalScroller>
                            <Grid container flexWrap="nowrap" gap={4}>
                                {blogs.map((blog) => (
                                    <Grid key={blog.id} item width={77.5}>
                                        <BlogCard
                                            imgUrl={blog.imgUrl}
                                            title={blog.title}
                                            content={
                                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do..."
                                            }
                                            onClick={() =>
                                                blog.onClick(blog.id)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </HorizontalScroller>
                        <MinimalHeader
                            title={"Resources"}
                            endNode={
                                <Button
                                    variant={"minimal"}
                                    color={"primary"}
                                    endNode={<OpenInNewOutline />}
                                >
                                    Help Center
                                </Button>
                            }
                        />

                        <HorizontalScroller>
                            <Grid container flexWrap="nowrap" gap={4}>
                                {resources.map((resource) => (
                                    <Grid key={resource.id} item width={77.5}>
                                        <ResourceCard
                                            logo={resource.logo}
                                            onClick={() =>
                                                resource.onClick(resource.id)
                                            }
                                            title={resource.title}
                                            description={resource.description}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </HorizontalScroller>
                    </>
                )}
            </Stack>
        </DoubleColumnLayout>
    );
};

export default HomeContainer;
