import { SelectChipProps, themeSpacingToNumber } from "@biggeo/bg-ui";
import { HorizontalFilledBarChart } from "@biggeo/bg-ui/charts";
import {
    Button,
    CompanyAvatar,
    Divider,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    Hidden,
    IconAvatar,
    IconButton,
    Image,
    MenuItem,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    ActionKeyOutline,
    ChevronRightOutline,
    ExpandMoreOutline,
    MapOutline,
} from "@biggeo/bg-ui/lab/icons";
import { theme } from "@biggeo/bg-ui/theme";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../../navigation/redux/model";
import { horizontalFilledBarChartData } from "../dummyData";

export const RightMapMainContainerSlot = ({
    id,
    name,
    image,
    preview,
    size,
    price,
    openRightSlot,
    setOpenRightSlot,
    isPreview,
    isPreviewBtnDisabled,
}: {
    readonly id: string;
    readonly name: string;
    readonly preview: (datasetId: string) => void;
    readonly image: string;
    readonly size: number;
    readonly price: number;
    readonly openRightSlot: boolean;
    readonly isPreview: boolean;
    readonly setOpenRightSlot: (open: boolean) => void;
    readonly isPreviewBtnDisabled: boolean;
}) => {
    const [label, setLabel] = useState("Column");

    const selectChipProps: SelectChipProps = {
        label: label,
        color: "surface",
        options: [
            <MenuItem key={1} label="Name" onClick={() => setLabel("Name")} />,
            <MenuItem
                key={2}
                label="last 14 days"
                onClick={() => setLabel("Last 14 days")}
            />,
        ],
    };
    return (
        <Stack height={"100%"}>
            <Hidden breakpoint="cmd" variant="up">
                <Grid
                    container
                    gap={2}
                    sx={{ padding: 2 }}
                    justifyContent="space-between"
                >
                    <Typography
                        variant="title3"
                        fontWeight="bold"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                        sx={{ alignSelf: "center" }}
                    >
                        Dataset information
                    </Typography>
                    <IconButton
                        variant="ghost"
                        onClick={() => setOpenRightSlot(false)}
                    >
                        <ExpandMoreOutline size="xs" />
                    </IconButton>
                </Grid>
            </Hidden>
            <Hidden breakpoint="cmd" variant="down">
                <Grid container gap={2} sx={{ padding: 2 }}>
                    <IconButton
                        variant="ghost"
                        onClick={() => setOpenRightSlot(false)}
                    >
                        <ChevronRightOutline size="xs" />
                    </IconButton>
                    <Typography
                        variant="title3"
                        fontWeight="bold"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                        sx={{ alignSelf: "center" }}
                    >
                        Dataset information
                    </Typography>
                </Grid>
            </Hidden>

            <Divider orientation="horizontal" color={100} />
            <FlexScrollAreaContainer>
                <FlexScrollArea
                    gap={4}
                    flexDirection="column"
                    sx={{ padding: 4 }}
                >
                    <Image
                        src={
                            "https://biggeo.blob.core.windows.net/media/Basemap-image.png"
                        }
                        sx={{
                            width: "100%",
                            objectFit: "contain",
                            objectPosition: "top left",
                        }}
                    />
                    {openRightSlot && (
                        <Button
                            variant="filled"
                            color="background"
                            startNode={<MapOutline size="xs" />}
                            sx={{
                                position: "absolute",
                                right: "26px",
                                top: "72px",
                                borderColor: (theme) => theme.palette.outline,
                                border: "1px solid",
                                borderRadius: (theme) => theme.radius.default,
                            }}
                            disabled={isPreview || isPreviewBtnDisabled}
                            onClick={() => preview?.(id)}
                        >
                            Preview
                        </Button>
                    )}
                    <Grid container gap={2}>
                        <IconAvatar size="sm" color="surface" square>
                            <CompanyAvatar
                                square
                                size="xs"
                                src={image}
                                alt={id.toString()}
                            />
                        </IconAvatar>

                        <Grid item xs minWidth={0}>
                            <Stack spacing={0.5}>
                                <Typography
                                    variant="body3"
                                    fontWeight="bold"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.background.onMain,
                                    }}
                                    align="left"
                                    truncate
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                    align="left"
                                    truncate
                                >
                                    By Dataplor
                                </Typography>
                            </Stack>
                        </Grid>
                        <Divider
                            sx={{
                                color: (theme) => theme.palette.stroke[100],
                            }}
                        />
                    </Grid>

                    <Grid container>
                        <HorizontalFilledBarChart
                            title="Area data quality"
                            selectChipProps={selectChipProps}
                            data={horizontalFilledBarChartData}
                            barChartProps={{
                                responsiveContainerProps: {
                                    height: themeSpacingToNumber(
                                        theme.spacing(12)
                                    ),
                                },
                            }}
                            color="chartNegative"
                            endColor="background"
                            disablePadding
                            slotProps={{
                                mainContent: {
                                    sx: {
                                        gap: 1.5,
                                    },
                                },
                                topDivider: {
                                    sx: {
                                        display: "none",
                                    },
                                },
                                root: {
                                    sx: {
                                        border: "none",
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Divider
                        sx={{
                            color: (theme) => theme.palette.stroke[100],
                        }}
                    />
                    <Stack gap={4}>
                        <Grid container gap={1}>
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                color="background"
                                colorSet="main"
                                invertColors
                            >
                                ${`${price}`}
                            </Typography>
                            <Typography
                                variant="body3"
                                fontWeight="regular"
                                color="disabled"
                                colorSet="container"
                                invertColors
                                sx={{ alignSelf: "center" }}
                            >
                                /Billed monthly
                            </Typography>
                        </Grid>
                        <Stack>
                            <Grid container gap={2}>
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                    sx={{ alignSelf: "center" }}
                                >
                                    Size:
                                </Typography>
                                <Grid item minWidth={0} xs>
                                    <Typography
                                        color="background"
                                        colorSet="main"
                                        invertColors
                                        variant="body3"
                                        fontWeight="bold"
                                    >{`${size} rows`}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container gap={2}>
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                    sx={{ alignSelf: "center" }}
                                >
                                    Refresh:
                                </Typography>
                                <Grid item minWidth={0} xs>
                                    <Typography
                                        color="background"
                                        colorSet="main"
                                        invertColors
                                        variant="body3"
                                        fontWeight="bold"
                                    >
                                        Monthly
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Typography variant="body3" fontWeight="regular">
                            By using this dataset you agree to it's{" "}
                            <Link to={"/"}>
                                <u>
                                    <b>terms of use</b>
                                </u>
                            </Link>
                        </Typography>
                    </Stack>
                    <Divider
                        sx={{
                            color: (theme) => theme.palette.stroke[100],
                        }}
                    />
                    <Stack gap={2}>
                        <Link to={"https://biggeo.com/deploy/contact-sales"}>
                            <Button variant="filled" color="primary" fullWidth>
                                Contact Sales
                            </Button>
                        </Link>
                        <Link to={Routes.support}>
                            <Button
                                variant="outlined"
                                color="surface"
                                fullWidth
                            >
                                Support
                            </Button>
                        </Link>
                    </Stack>
                    <Divider
                        sx={{
                            color: (theme) => theme.palette.stroke[100],
                        }}
                    />
                    <Grid
                        container
                        sx={{
                            background: (theme) =>
                                theme.palette.background.main,
                            width: "100%",
                            padding: (theme) => theme.spacing(3),
                            border: (theme) =>
                                `1px solid ${theme.palette.stroke[100]}`,
                            borderRadius: (theme) => theme.radius.xs3,
                            cursor: "pointer",
                            alignItems: "center",
                        }}
                        gap={2}
                    >
                        <IconAvatar size="sm" square>
                            <ActionKeyOutline size="xs" />
                        </IconAvatar>
                        <Grid item minWidth={0} xs>
                            <Stack gap={0.5}>
                                <Typography
                                    variant="body3"
                                    fontWeight="semibold"
                                    color="background"
                                    colorSet="main"
                                    invertColors
                                    truncate
                                >
                                    Have a dataset access key?
                                </Typography>
                                <Typography
                                    variant="body4"
                                    fontWeight="regular"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                    truncate
                                >
                                    Unlock dataset with access key
                                </Typography>
                            </Stack>
                        </Grid>
                        <IconButton variant="ghost">
                            <ChevronRightOutline size="xs" />
                        </IconButton>
                    </Grid>
                </FlexScrollArea>
            </FlexScrollAreaContainer>
        </Stack>
    );
};
