import {
    DatabaseType,
    DatasetDataAggregationHeatmap,
    DatasetStyleType,
    DatasetStyles,
    LevelSet,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import mapValues from "lodash/mapValues";
import size from "lodash/size";
import some from "lodash/some";

import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { match } from "ts-pattern";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { MapColorSetterType } from "../../../common/types/color-picker";
import { getPreviousAndNextElements } from "../../../common/utils/helpers";
import {
    createSquareIcon,
    generateHeatMapInitialValues,
    getLayerHeatmapStyle,
} from "../../utils/style-utils";
import { MapAction, MapContextDataset } from "../context";
import { DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";
import { HeatMapColorSwatchOption } from "./heatmap";

// ████████╗██╗   ██╗██████╗ ███████╗███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║
//    ██║      ██║   ██║     ███████╗███████║
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝

export enum DatasetFillLayer {
    aggregate = "aggregate",
    polygons = "polygons",
    lines = "lines",
    levelSets = "levelSets",
}

type DatasetFillLayerType<T> = T extends DatasetFillLayer.levelSets
    ? mapboxgl.AnyLayer[]
    : mapboxgl.AnyLayer;

type DatasetStyleCategory = Exclude<
    DatasetStyleType,
    DatasetStyleType.stroke | DatasetStyleType.dataAggregation
>;

export type DatasetSources = {
    aggregate: string;
    points: string;
    polygons: string;
    levelSets: string[];
};

export type DatasetSourcesData = {
    aggregate: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    points: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    polygons: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    levelSets: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[][];
};

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗ ███████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗██╔════╝
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝███████╗
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗╚════██║
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║███████║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝╚══════╝

export const getDatasetSources = (input: {
    prefix: string;
    levelSets: LevelSet[];
    map: mapboxgl.Map;
    isLoaded: boolean;
}): DatasetSources => {
    const { prefix, levelSets, map, isLoaded } = input;

    const style = map && isLoaded ? map.getStyle() : undefined;

    const levelSetsSize =
        isEmpty(levelSets) && style
            ? pipe(
                  style.layers,
                  A.filter((l) => l.id.includes(`${prefix}-levelset`)),
                  size
              )
            : levelSets.length;

    return {
        aggregate: `${prefix}-aggregate`,
        points: `${prefix}-points`,
        polygons: `${prefix}-polygons`,
        levelSets: Array.from(
            { length: levelSetsSize },
            (_, index) => `${prefix}-levelset-${index}`
        ),
    };
};

export const isShapeStyleLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is Record<ShapeStyle, mapboxgl.AnyLayer> =>
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(ShapeStyle.square) ||
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty(ShapeStyle.oval);

export const isShapeFillLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is { [K in DatasetFillLayer]: DatasetFillLayerType<K> } =>
    some(Object.keys(value), (v: DatasetFillLayer) =>
        Object.values(DatasetFillLayer).includes(v)
    );

export const isShapeAnyLayer = (
    value:
        | mapboxgl.AnyLayer
        | Record<ShapeStyle, mapboxgl.AnyLayer>
        | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
): value is mapboxgl.AnyLayer =>
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty("id") ||
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    value.hasOwnProperty("source");

export const getHeatmapFormattedValue = (
    heatmap?: DatasetDataAggregationHeatmap
): ColorSwatchOption | undefined => {
    const heatMapId = heatmap?.id || undefined;

    return heatmap && heatMapId
        ? {
              id: isNumber(Number(heatMapId))
                  ? Number(heatMapId)
                  : (heatMapId as "custom"),
              swatch: pipe(
                  heatmap.swatch,
                  A.map((s) => ({
                      range: s.range,
                      color: s.color,
                  }))
              ),
          }
        : undefined;
};

export const getDatasetStyles = (dataset: MapContextDataset): DatasetStyles => {
    return {
        shape: dataset.mapTemplateDataset?.shape || ShapeStyle.oval,
        fill: {
            color:
                dataset.mapTemplateDataset?.color ?? dataset.dataSource.color,
            opacity: dataset.dataSource.opacity,
        },
        stroke: dataset.mapTemplateDataset?.stroke
            ? {
                  color: dataset.mapTemplateDataset.stroke.color,
                  opacity: dataset.mapTemplateDataset.stroke.opacity,
              }
            : undefined,
        customMarker: dataset.dataSource.icon,
        dataAggregation: {
            heatmap: dataset.mapTemplateDataset?.heatmapColor
                ? JSON.parse(dataset.mapTemplateDataset.heatmapColor)
                : dataset.dataSource.heatmapColor
                  ? JSON.parse(dataset.dataSource.heatmapColor)
                  : undefined,
        },
    };
};

// ███╗   ███╗ █████╗ ██╗███╗   ██╗
// ████╗ ████║██╔══██╗██║████╗  ██║
// ██╔████╔██║███████║██║██╔██╗ ██║
// ██║╚██╔╝██║██╔══██║██║██║╚██╗██║
// ██║ ╚═╝ ██║██║  ██║██║██║ ╚████║
// ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝

export const getDatasetLayers = (input: {
    prefix: string;
    sources: DatasetSources;
    styles?: DatasetStyles;
    data?: Partial<{
        isPreview: boolean;
        isMipmapped: boolean;
        type: DatabaseType;
    }>;
}): Record<
    DatasetStyleCategory,
    | mapboxgl.AnyLayer
    | Record<ShapeStyle, mapboxgl.AnyLayer>
    | { [K in DatasetFillLayer]: DatasetFillLayerType<K> }
> => {
    const { prefix, sources, styles, data } = input;

    const color = styles?.fill.color || DEFAULT_SHAPE_COLOR;
    const opacity = styles?.fill.opacity || 0.9;
    const customMarker = styles?.customMarker || "airport";

    const heatmap = getHeatmapFormattedValue(
        styles?.dataAggregation?.heatmap || undefined
    );

    const { heatMapColorArray, colorMap } = getLayerHeatmapStyle(
        heatmap,
        color
    );

    return {
        [DatasetStyleType.shape]: {
            [ShapeStyle.square]: {
                id: `${prefix}-points-icon-square`,
                type: "symbol",
                source: sources.points,
                minzoom: 15,
                layout: {
                    "icon-image": `${prefix}-square-icon`,
                    "icon-size": 0.7,
                },
                paint: {
                    "icon-opacity": {
                        default: opacity,
                        stops: [
                            [14, 0],
                            [15, opacity],
                        ],
                    },
                },
            },
            [ShapeStyle.oval]: {
                id: `${prefix}-points-icon-oval`,
                type: "circle",
                source: sources.points,
                minzoom: 15,
                paint: {
                    "circle-radius": 4,
                    "circle-color": color,
                    "circle-stroke-color": `${styles?.stroke?.color || color}`,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: styles?.stroke?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, styles?.stroke?.opacity || opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: opacity,
                        stops: [
                            [14, 0],
                            [15, opacity],
                        ],
                    },
                },
            },
        },
        [DatasetStyleType.fill]: {
            [DatasetFillLayer.aggregate]: {
                id: `${prefix}-aggregate`,
                type: "heatmap",
                source: sources.aggregate,
                paint: {
                    "heatmap-weight": [
                        "interpolate",
                        ["linear"],
                        ["get", "count"],
                        0,
                        0,
                        3000,
                        20,
                    ],
                    "heatmap-intensity": {
                        stops: [
                            [8, 1],
                            [11, 3],
                        ],
                    },
                    "heatmap-radius": 15,
                    "heatmap-color": [
                        "interpolate",
                        ["linear"],
                        ["heatmap-density"],
                        ...heatMapColorArray,
                    ],
                    "heatmap-opacity": {
                        default: opacity,
                        stops: [
                            [14, opacity],
                            [15, 0],
                        ],
                    },
                },
            },
            [DatasetFillLayer.polygons]: {
                id: `${prefix}-polygons`,
                type: "fill",
                source: sources.polygons,
                paint: {
                    "fill-color": [
                        "match",
                        ["get", "idCadence"],
                        ...colorMap,
                        color,
                    ],
                    "fill-opacity": [
                        "match",
                        ["get", "line"],
                        "true",
                        0,
                        opacity,
                    ],
                    "fill-antialias": false,
                },
            },
            [DatasetFillLayer.lines]: {
                id: `${prefix}-lines`,
                type: "line",
                source: sources.polygons,
                paint: {
                    "line-color": [
                        "match",
                        ["get", "line"],
                        "true",
                        color,
                        "black",
                    ],
                    "line-width": 3,
                    "line-opacity": opacity,
                },
            },
            [DatasetFillLayer.levelSets]: pipe(
                sources.levelSets,
                A.mapWithIndex((index, source) => ({
                    id: `${prefix}-levelset-${index}`,
                    type: "fill",
                    source,
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "idCadence"],
                            ...colorMap,
                            color,
                        ],
                        "fill-opacity": [
                            "match",
                            ["get", "line"],
                            "true",
                            0,
                            data?.isMipmapped
                                ? 0.2
                                : data?.type === DatabaseType.polygon
                                  ? 0.3
                                  : 0.15,
                        ],
                        "fill-antialias": false,
                    },
                }))
            ),
        },
        [DatasetStyleType.customMarker]: {
            id: `${prefix}-custom-marker`,
            type: "symbol",
            source: sources.points,
            layout: {
                "icon-image": customMarker,
                "icon-size": 1,
                visibility: "none",
            },
            paint: {
                "icon-opacity": {
                    default: opacity,
                    stops: [
                        [14, 0],
                        [15, opacity],
                    ],
                },
            },
        },
    };
};

export const getDatasetLayerIds = (input: {
    prefix: string;
    map: mapboxgl.Map;
    isLoaded: boolean;
}): string[] => {
    const { prefix, map, isLoaded } = input;

    const layers = getDatasetLayers({
        prefix,
        sources: getDatasetSources({
            prefix,
            map,
            isLoaded,
            levelSets: [],
        }),
    });

    return pipe(
        mapValues(layers, (value) => {
            if (isShapeStyleLayer(value)) {
                return [value.oval.id, value.square.id];
            }

            if (isShapeFillLayer(value)) {
                return flatten([
                    value.aggregate.id,
                    value.polygons.id,
                    value.lines.id,
                    value.levelSets.map((l) => l.id),
                ]);
            }

            return [value.id];
        }),
        Object.values,
        flatten
    );
};

export const setDatasetVisibility = (input: {
    map: mapboxgl.Map;
    prefix: string;
    levelSets: LevelSet[];
    visibility: "visible" | "none";
    isLoaded: boolean;
    points: { shape?: ShapeStyle; customMarker?: string };
}) => {
    const { map, prefix, levelSets, visibility, isLoaded, points } = input;

    if (isLoaded) {
        const layers = getDatasetLayers({
            prefix,
            sources: getDatasetSources({
                prefix,
                map,
                isLoaded,
                levelSets,
            }),
        });

        mapValues(layers, (value) => {
            if (isShapeStyleLayer(value)) {
                if (isEqual(visibility, "visible")) {
                    if (points.shape && !points.customMarker) {
                        map.setLayoutProperty(
                            value[points.shape].id,
                            "visibility",
                            visibility
                        );
                    }
                } else {
                    map.setLayoutProperty(
                        value.square.id,
                        "visibility",
                        visibility
                    );
                    map.setLayoutProperty(
                        value.oval.id,
                        "visibility",
                        visibility
                    );
                }
            } else if (isShapeFillLayer(value)) {
                map.setLayoutProperty(
                    value.aggregate.id,
                    "visibility",
                    visibility
                );
                map.setLayoutProperty(
                    value.polygons.id,
                    "visibility",
                    visibility
                );
                map.setLayoutProperty(value.lines.id, "visibility", visibility);

                mapValues(value.levelSets, (v) =>
                    map.setLayoutProperty(v.id, "visibility", visibility)
                );
            } else {
                if (isEqual(visibility, "visible")) {
                    if (
                        points.customMarker &&
                        value.id.includes("custom-marker")
                    ) {
                        map.setLayoutProperty(
                            value.id,
                            "visibility",
                            visibility
                        );
                    }
                } else {
                    map.setLayoutProperty(value.id, "visibility", visibility);
                }
            }
        });
    }
};

export const handleDatasetLayers = (input: {
    action: "add" | "remove";
    map: mapboxgl.Map;
    isLoaded: boolean;
    prefix: string;
    hierarchy?: string[];
    levelSets?: LevelSet[];
    sources?: DatasetSources;
    sourcesData?: DatasetSourcesData;
    styles?: DatasetStyles;
    data?: Partial<{
        isPreview: boolean;
        isMipmapped: boolean;
        type: DatabaseType;
    }>;
}) => {
    const {
        action,
        map,
        isLoaded,
        prefix,
        levelSets,
        sources: _sources,
        sourcesData,
        styles,
        data,
        hierarchy,
    } = input;

    if (isLoaded) {
        const sources =
            _sources ??
            getDatasetSources({
                prefix,
                map,
                isLoaded,
                levelSets: levelSets || [],
            });

        const layers = getDatasetLayers({
            prefix,
            sources,
            styles,
            data,
        });

        if (isEqual(action, "add")) {
            const { previousElement } = getPreviousAndNextElements(
                prefix,
                hierarchy
            );

            if (sourcesData) {
                mapValues(
                    sources,
                    (
                        source: string | string[],
                        key: "aggregate" | "polygons" | "points" | "levelSets"
                    ) => {
                        if (isArray(source)) {
                            pipe(
                                source,
                                A.mapWithIndex((index, s) => {
                                    const geojsonSource = map.getSource(
                                        s
                                    ) as GeoJSONSource;

                                    if (geojsonSource) {
                                        geojsonSource.setData({
                                            type: "FeatureCollection",
                                            features:
                                                sourcesData.levelSets[index],
                                        });
                                    } else {
                                        map.addSource(s, {
                                            type: "geojson",
                                            data: {
                                                type: "FeatureCollection",
                                                features:
                                                    sourcesData.levelSets[
                                                        index
                                                    ],
                                            },
                                        });
                                    }
                                })
                            );
                        } else {
                            const geojsonSource = map.getSource(
                                source
                            ) as GeoJSONSource;

                            const features =
                                key !== "levelSets"
                                    ? match<
                                          "aggregate" | "polygons" | "points",
                                          GeoJSON.Feature<
                                              GeoJSON.Geometry,
                                              GeoJSON.GeoJsonProperties
                                          >[]
                                      >(key)
                                          .with(
                                              "aggregate",
                                              () => sourcesData.aggregate
                                          )
                                          .with(
                                              "polygons",
                                              () => sourcesData.polygons
                                          )
                                          .with(
                                              "points",
                                              () => sourcesData.points
                                          )
                                          .exhaustive()
                                    : [];

                            if (geojsonSource) {
                                geojsonSource.setData({
                                    type: "FeatureCollection",
                                    features,
                                });
                            } else {
                                map.addSource(source, {
                                    type: "geojson",
                                    data: {
                                        type: "FeatureCollection",
                                        features,
                                    },
                                });
                            }
                        }
                    }
                );
            }

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    map.addLayer(value.square);
                    map.addLayer(value.oval);

                    if (previousElement) {
                        map.moveLayer(
                            value.square.id.replace(prefix, previousElement)
                        );
                        map.moveLayer(
                            value.oval.id.replace(prefix, previousElement)
                        );
                    }
                } else if (isShapeFillLayer(value)) {
                    map.addLayer(value.aggregate);
                    map.addLayer(value.polygons);
                    map.addLayer(value.lines);

                    if (previousElement) {
                        map.moveLayer(
                            value.polygons.id.replace(prefix, previousElement)
                        );
                        map.moveLayer(
                            value.aggregate.id.replace(prefix, previousElement)
                        );
                        map.moveLayer(
                            value.lines.id.replace(prefix, previousElement)
                        );
                    }

                    mapValues(value.levelSets, (v) => {
                        map.addLayer(v);

                        if (previousElement) {
                            map.moveLayer(
                                v.id.replace(prefix, previousElement)
                            );
                        }
                    });
                } else {
                    map.addLayer(value);

                    if (previousElement) {
                        map.moveLayer(
                            value.id.replace(prefix, previousElement)
                        );
                    }
                }
            });
        }

        if (isEqual(action, "remove")) {
            mapValues(sources, (source) => {
                if (isArray(source)) {
                    pipe(
                        source,
                        A.map((s) => {
                            const geojsonSource = map.getSource(
                                s
                            ) as GeoJSONSource;

                            if (geojsonSource) {
                                geojsonSource.setData({
                                    type: "FeatureCollection",
                                    features: [],
                                });
                            }
                        })
                    );
                } else {
                    const geojsonSource = map.getSource(
                        source
                    ) as GeoJSONSource;

                    if (geojsonSource) {
                        geojsonSource.setData({
                            type: "FeatureCollection",
                            features: [],
                        });
                    }
                }
            });

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    const squareLayer = map.getLayer(value.square.id);
                    const ovalLayer = map.getLayer(value.oval.id);

                    if (squareLayer) {
                        map.removeLayer(value.square.id);
                    }

                    if (ovalLayer) {
                        map.removeLayer(value.oval.id);
                    }
                } else if (isShapeFillLayer(value)) {
                    const aggregateLayer = map.getLayer(value.aggregate.id);
                    const polygonsLayer = map.getLayer(value.polygons.id);
                    const linesLayer = map.getLayer(value.lines.id);

                    if (aggregateLayer) {
                        map.removeLayer(value.aggregate.id);
                    }

                    if (polygonsLayer) {
                        map.removeLayer(value.polygons.id);
                    }

                    if (linesLayer) {
                        map.removeLayer(value.lines.id);
                    }

                    mapValues(value.levelSets, (v) => {
                        const levelSetLayer = map.getLayer(v.id);

                        if (levelSetLayer) {
                            map.removeLayer(v.id);
                        }
                    });
                } else {
                    const otherLayer = map.getLayer(value.id);

                    if (otherLayer) {
                        map.removeLayer(value.id);
                    }
                }
            });
        }
    }
};

export const setDatasetLayersByCategory = (input: {
    category: DatasetStyleType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    prefix: string;
    styles: DatasetStyles;
    levelSets?: LevelSet[];
}) => {
    const { category, map, isLoaded, prefix, styles, levelSets } = input;
    const shape = styles.shape;

    if (map.current && isLoaded) {
        const layers = getDatasetLayers({
            prefix,
            sources: getDatasetSources({
                prefix,
                map: map.current,
                isLoaded,
                levelSets: levelSets || [],
            }),
            styles,
        });

        const shapeLayers = layers[DatasetStyleType.shape];
        const customMarkerLayer = layers[DatasetStyleType.customMarker];
        const aggregateLayers = layers[DatasetStyleType.fill];

        const iconStyle = {
            color: {
                color: styles.fill.color || undefined,
                opacity: styles.fill.opacity || undefined,
            },
            stroke: {
                color: styles.stroke?.color || styles.fill.color || undefined,
                opacity:
                    styles.stroke?.opacity || styles.fill.opacity || undefined,
            },
        };

        match(category)
            .with(DatasetStyleType.shape, () => {
                if (isEqual(shape, ShapeStyle.square)) {
                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setLayoutProperty(
                            shapeLayers.square.id,
                            "visibility",
                            "visible"
                        );
                        map.current?.setLayoutProperty(
                            shapeLayers.oval.id,
                            "visibility",
                            "none"
                        );
                    }

                    if (isShapeAnyLayer(customMarkerLayer)) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "none"
                        );
                    }

                    const image = map.current?.hasImage(
                        `${prefix}-square-icon`
                    );

                    if (image) {
                        map.current?.updateImage(
                            `${prefix}-square-icon`,
                            createSquareIcon({
                                ...iconStyle,
                            })
                        );
                    } else {
                        map.current?.addImage(
                            `${prefix}-square-icon`,
                            createSquareIcon({
                                ...iconStyle,
                            }),
                            { pixelRatio: 4 }
                        );
                    }
                }

                if (isEqual(shape, ShapeStyle.oval)) {
                    const stroke = styles.stroke;

                    if (isShapeAnyLayer(customMarkerLayer)) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "none"
                        );
                    }

                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setLayoutProperty(
                            shapeLayers.oval.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setLayoutProperty(
                            shapeLayers.square.id,
                            "visibility",
                            "none"
                        );

                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-color",
                            `${styles.fill.color}`
                        );

                        if (stroke) {
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-stroke-color",
                                `${stroke.color}`
                            );
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-stroke-opacity",
                                {
                                    default: stroke.opacity || 0.9,
                                    stops: [
                                        [14, 0],
                                        [15, stroke.opacity || 0.9],
                                    ],
                                }
                            );
                            map.current?.setPaintProperty(
                                shapeLayers.oval.id,
                                "circle-opacity",
                                {
                                    default: stroke.opacity || 0.9,
                                    stops: [
                                        [14, 0],
                                        [15, stroke.opacity || 0.9],
                                    ],
                                }
                            );
                        }
                    }
                }
            })
            .with(DatasetStyleType.fill, () => {
                if (styles.fill) {
                    map.current?.updateImage(
                        `${prefix}-square-icon`,
                        createSquareIcon({
                            ...iconStyle,
                        })
                    );

                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-color",
                            styles.fill.color
                        );

                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-opacity",
                            styles.fill.opacity
                        );
                    }

                    if (isShapeAnyLayer(customMarkerLayer)) {
                        map.current?.setPaintProperty(
                            customMarkerLayer.id,
                            "icon-color",
                            styles.fill.color
                        );
                    }

                    if (
                        isShapeFillLayer(aggregateLayers) &&
                        styles.fill.color
                    ) {
                        const { heatMapColorArray, colorMap } =
                            getLayerHeatmapStyle(undefined, styles.fill.color);

                        map.current?.setLayoutProperty(
                            aggregateLayers.aggregate.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-color",
                            [
                                "interpolate",
                                ["linear"],
                                ["heatmap-density"],
                                ...heatMapColorArray,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-opacity",
                            {
                                default: styles.fill.opacity,
                                stops: [
                                    [14, styles.fill.opacity],
                                    [15, 0],
                                ],
                            }
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-color",
                            [
                                "match",
                                ["get", "idCadence"],
                                ...colorMap,
                                styles.fill.color,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-opacity",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                0,
                                styles.fill.opacity,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-opacity",
                            styles.fill.opacity
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-color",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                styles.fill.color,
                                "black",
                            ]
                        );

                        mapValues(aggregateLayers.levelSets, (levelSetLayer) =>
                            map.current?.setPaintProperty(
                                levelSetLayer.id,
                                "fill-color",
                                [
                                    "match",
                                    ["get", "idCadence"],
                                    ...colorMap,
                                    styles.fill.color,
                                ]
                            )
                        );
                    }
                }
            })
            .with(DatasetStyleType.stroke, () => {
                if (styles.stroke) {
                    map.current?.updateImage(
                        `${prefix}-square-icon`,
                        createSquareIcon({
                            ...iconStyle,
                        })
                    );

                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setPaintProperty(
                            shapeLayers.oval.id,
                            "circle-stroke-color",
                            styles.stroke.color
                        );
                    }
                }
            })
            .with(DatasetStyleType.customMarker, () => {
                if (styles.customMarker) {
                    if (isShapeAnyLayer(customMarkerLayer)) {
                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setLayoutProperty(
                            customMarkerLayer.id,
                            "icon-image",
                            styles.customMarker
                        );
                    }

                    if (isShapeStyleLayer(shapeLayers)) {
                        map.current?.setLayoutProperty(
                            shapeLayers.oval.id,
                            "visibility",
                            "none"
                        );
                        map.current?.setLayoutProperty(
                            shapeLayers.square.id,
                            "visibility",
                            "none"
                        );
                    }
                }
            })
            .with(DatasetStyleType.dataAggregation, () => {
                if (styles.dataAggregation) {
                    const heatmap = getHeatmapFormattedValue(
                        styles?.dataAggregation?.heatmap || undefined
                    );

                    const { heatMapColorArray, colorMap } =
                        getLayerHeatmapStyle(heatmap);

                    if (isShapeFillLayer(aggregateLayers)) {
                        map.current?.setLayoutProperty(
                            aggregateLayers.aggregate.id,
                            "visibility",
                            "visible"
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.aggregate.id,
                            "heatmap-color",
                            [
                                "interpolate",
                                ["linear"],
                                ["heatmap-density"],
                                ...heatMapColorArray,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.polygons.id,
                            "fill-color",
                            [
                                "match",
                                ["get", "idCadence"],
                                ...colorMap,
                                styles.fill.color,
                            ]
                        );

                        map.current?.setPaintProperty(
                            aggregateLayers.lines.id,
                            "line-color",
                            [
                                "match",
                                ["get", "line"],
                                "true",
                                styles.fill.color,
                                "black",
                            ]
                        );

                        mapValues(aggregateLayers.levelSets, (levelSetLayer) =>
                            map.current?.setPaintProperty(
                                levelSetLayer.id,
                                "fill-color",
                                [
                                    "match",
                                    ["get", "idCadence"],
                                    ...colorMap,
                                    styles.fill.color,
                                ]
                            )
                        );
                    }
                }
            });
    }
};

export const setDatasetLayers = (input: {
    currentDataset?: MapContextDataset;
    datasets: MapContextDataset[];
    dispatch: React.Dispatch<MapAction> | undefined;
    isSavedViewPage: boolean;
}) => {
    const { datasets, currentDataset, dispatch, isSavedViewPage } = input;

    const initialHeatMapValues = useMemo(
        () =>
            datasets.map((data) => {
                const swatch = data.mapTemplateDataset?.heatmapColor
                    ? JSON.parse(data.mapTemplateDataset.heatmapColor)
                    : data.dataSource.heatmapColor
                      ? JSON.parse(data.dataSource.heatmapColor)
                      : undefined;

                if (swatch) {
                    return {
                        datasetId: data.dataSource.id,
                        swatch,
                    };
                }

                return generateHeatMapInitialValues(
                    data.dataSource.id,
                    data.mapTemplateDataset?.color ||
                        data.dataSource.color ||
                        "#ffffff"
                );
            }),
        [datasets]
    );

    const [heatMapValue, setHeatMapValue] =
        useState<HeatMapColorSwatchOption[]>(initialHeatMapValues);

    const [extraHeatmapOptions, setExtraHeatmapOptions] = useState<
        ColorSwatchOption[]
    >(
        pipe(
            [
                generateHeatMapInitialValues(
                    currentDataset ? currentDataset.dataSource.id : "custom",
                    currentDataset?.mapTemplateDataset?.color ||
                        currentDataset?.dataSource.color ||
                        "#ffffff"
                ).swatch,
            ],
            compact
        )
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isSavedViewPage) {
            setHeatMapValue(initialHeatMapValues);
        }
    }, [initialHeatMapValues, heatMapValue, datasets, isSavedViewPage]);

    const updateShape = (input: {
        shape: ShapeStyle;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const { shape, map, isLoaded, dataset } = input;
        const dataSourceId = dataset.dataSource.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        shape,
                    },
                    dataSource: {
                        icon: undefined,
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: DatasetStyleType.shape,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: { ...getDatasetStyles(currentDataset ?? dataset), shape },
        });
    };

    const updateColor = ({
        color,
        opacity,
        type,
        map,
        isLoaded,
        dataset,
        updateMapTemplateDatasetHeatMapColor,
    }: MapColorSetterType & {
        type: "fill" | "stroke";
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
        updateMapTemplateDatasetHeatMapColor: (input: {
            value: ColorSwatchOption;
            color?: string;
            mapTemplateDatasetId?: number;
        }) => void;
    }) => {
        const dataSourceId = dataset.dataSource.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        ...(type === "stroke" && {
                            stroke: {
                                color,
                                opacity,
                            },
                        }),
                        ...(type === "fill" && {
                            color,
                        }),
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category:
                type === "fill"
                    ? DatasetStyleType.fill
                    : DatasetStyleType.stroke,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(currentDataset ?? dataset),
                ...(type === "stroke" && {
                    stroke: {
                        color,
                        opacity,
                    },
                }),
                ...(type === "fill" && {
                    fill: { color, opacity },
                }),
            },
        });

        if (type === "fill" && color) {
            const swatch = generateHeatMapInitialValues(
                dataSourceId,
                color
            ).swatch;

            setHeatMapValue((prev) =>
                prev.map((p) =>
                    p.datasetId === dataSourceId
                        ? {
                              datasetId: dataSourceId,
                              swatch,
                          }
                        : p
                )
            );

            setExtraHeatmapOptions([swatch]);

            updateMapTemplateDatasetHeatMapColor({
                value: swatch,
                color,
                mapTemplateDatasetId: dataset.mapTemplateDataset?.id,
            });

            dispatch?.({
                type: "UPDATE_DATASET",
                values: {
                    dataSourceId,
                    dataset: {
                        mapTemplateDataset: {
                            heatmapColor: JSON.stringify(swatch),
                        },
                    },
                },
            });
        }
    };

    const updateCustomMarker = (input: {
        marker: string;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const { map, isLoaded, marker, dataset } = input;
        const dataSourceId = dataset.dataSource.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        shape: undefined,
                    },
                    dataSource: {
                        icon: marker,
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: DatasetStyleType.customMarker,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(currentDataset ?? dataset),
                customMarker: marker,
            },
        });
    };

    const updateHeatMapColor = (input: {
        value: ColorSwatchOption;
        updateMapTemplateDatasetHeatMapColor: (input: {
            value: ColorSwatchOption;
            color?: string;
            mapTemplateDatasetId?: number;
        }) => void;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
        dataset: MapContextDataset;
    }) => {
        const {
            value,
            dataset,
            updateMapTemplateDatasetHeatMapColor,
            map,
            isLoaded,
        } = input;

        const dataSourceId = dataset.dataSource.id;

        dispatch?.({
            type: "UPDATE_DATASET",
            values: {
                dataSourceId,
                dataset: {
                    mapTemplateDataset: {
                        heatmapColor: JSON.stringify(value),
                    },
                },
            },
        });

        setDatasetLayersByCategory({
            category: DatasetStyleType.dataAggregation,
            prefix: dataSourceId,
            map,
            isLoaded,
            styles: {
                ...getDatasetStyles(dataset),
                dataAggregation: {
                    heatmap: {
                        id: value.id.toString(),
                        swatch: value.swatch,
                    },
                },
            },
        });

        setHeatMapValue((prev) =>
            prev.map((val) =>
                val.datasetId === dataSourceId
                    ? {
                          datasetId: dataSourceId,
                          swatch: value,
                      }
                    : val
            )
        );

        updateMapTemplateDatasetHeatMapColor({
            value,
            mapTemplateDatasetId: dataset.mapTemplateDataset?.id,
        });
    };

    return {
        heatMapValue,
        setHeatMapValue,
        extraHeatmapOptions,
        setExtraHeatmapOptions,
        updateShape,
        updateColor,
        updateCustomMarker,
        updateHeatMapColor,
    };
};

export const reorderDatasetLayers = (
    order: {
        readonly type?: DatabaseType;
        readonly dataSourceId: string;
    }[],
    map: React.MutableRefObject<mapboxgl.Map | null>,
    isLoaded: boolean
) => {
    order.map((layer) => {
        if (map.current && isLoaded) {
            const layers = getDatasetLayerIds({
                prefix: layer.dataSourceId,
                map: map.current,
                isLoaded,
            });

            layers.map((layerId) => {
                map.current?.moveLayer(layerId);
            });
        }
    });
};
