import { DescriptionTextField, WithLoading } from "@biggeo/bg-ui";
import {
    Button,
    CircularLoading,
    Stack,
    StickyFabPlacementHelper,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";

export type MappingModalProps = Readonly<{
    onChange: (field: string, val: string) => void;
    values: Readonly<{
        label: string;
        tableName: string;
        description: string;
    }>;
    disabled: boolean;
    loading?: boolean;
    handleSubmit: () => void;
}>;

export const MappingColumnForm = ({
    values,
    loading,
    disabled,
    handleSubmit,
    onChange,
}: MappingModalProps) => {
    return (
        <Stack sx={{ padding: 4, height: "100%" }}>
            <Stack gap={4} height="100%">
                <ThumbnailAvatar
                    sx={{
                        borderRadius: (theme) => theme.radius.default,
                        border: (theme) =>
                            `1px solid ${theme.palette.background.main}`,
                        backgroundColor: (theme) =>
                            theme.palette.primary.container,
                    }}
                    size="lg"
                    src="https://biggeo.blob.core.windows.net/media/sf.png"
                />
                <Stack>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        Original name
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{
                            color: (theme) => theme.palette.background.onMain,
                        }}
                    >
                        {values.tableName}
                    </Typography>
                </Stack>
                <TextField
                    fullWidth
                    label="Display name"
                    subLabel="(Optional)"
                    type="name"
                    placeholder="Enter name"
                    value={values.label}
                    onChange={(_e, v) => {
                        onChange("label", v ?? "");
                    }}
                />

                <DescriptionTextField
                    fullWidth
                    label="Description"
                    subLabel="(Optional)"
                    placeholder="Enter description"
                    value={values.description}
                    onChange={(_e, v) => {
                        onChange("description", v ?? "");
                    }}
                />
            </Stack>
            <StickyFabPlacementHelper
                placement="right"
                sx={{
                    paddingY: 4,
                    backgroundColor: (theme) =>
                        theme.palette.background.container,
                }}
            >
                <WithLoading loading={loading} text="Indexing...">
                    <Button
                        disabled={disabled}
                        onClick={handleSubmit}
                        startNode={
                            loading ? <CircularLoading size="xs" /> : undefined
                        }
                    >
                        Index Data
                    </Button>
                </WithLoading>
            </StickyFabPlacementHelper>
        </Stack>
    );
};
