import { IconButton, Stack } from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import { startsWith } from "lodash";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { compose } from "redux";
import { isSnp } from "../../common/redux/hooks";
import { Routes } from "../../navigation/redux/model";
import { UnlockDatasetViaAccessKeyForm } from "../forms/UnlockDatasetViaAccessKeyForm";
import { useDataAccessKeyFormPanelState } from "../redux/hooks";
import { dataAccessKeysActions } from "../redux/model";

export const DataAccessKeyFormPanel = () => {
    const path = useLocation().pathname;
    const dispatch = useDispatch();
    const open = useDataAccessKeyFormPanelState();

    const showOnRoutes = [Routes.home, Routes.marketplace];
    const show =
        path === Routes.home ||
        showOnRoutes.some(
            (route) => route !== Routes.home && startsWith(path, route)
        );

    const handleClose = () =>
        compose(dispatch, dataAccessKeysActions.closeDataAccessKeyFormPanel)();

    return (
        show && (
            <Stack
                gap={4}
                sx={{
                    display: open ? "flex" : "none",
                    width: (theme) => `calc(100% - ${theme.spacing(8)})`,
                    maxHeight: (theme) => `calc(100% - ${theme.spacing(8)})`,
                    background: (theme) => theme.palette.background.container,
                    color: (theme) => theme.palette.background.onContainer,
                    maxWidth: 97,
                    position: "fixed",
                    bottom: 4,
                    left: 4,
                    borderRadius: (theme) => theme.radius.xs3,
                    zIndex: (theme) => theme.zIndex.drawer - 5,
                    backgroundColor: (theme) => theme.palette.background.main,
                    boxShadow: (theme) => theme.shadows.allAround,
                    overflow: "auto",
                }}
            >
                <IconButton
                    variant="minimal"
                    onClick={handleClose}
                    sx={{ alignSelf: "end", marginTop: 4, marginRight: 4 }}
                >
                    <CloseOutline />
                </IconButton>
                <UnlockDatasetViaAccessKeyForm handleClose={handleClose} />
            </Stack>
        )
    );
};
