import { ActionType as ActionT, action } from "typesafe-actions";

type DataAccessKeysModel = {
    dataAccessKeyFormPanel: {
        open: boolean;
    };
};

const initialState: DataAccessKeysModel = {
    dataAccessKeyFormPanel: {
        open: true,
    },
};

export const dataAccessKeysActions = {
    openDataAccessKeyFormPanel: () => action("OPEN_DATA_ACCESS_KEY_FORM_PANEL"),
    closeDataAccessKeyFormPanel: () =>
        action("CLOSE_DATA_ACCESS_KEY_FORM_PANEL"),
};

export type CombinedActions = ActionT<typeof dataAccessKeysActions>;

export const dataAccessKeysReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: DataAccessKeysModel = initialState,
    action: CombinedActions
): DataAccessKeysModel => {
    if (!action) return state;
    switch (action.type) {
        case "OPEN_DATA_ACCESS_KEY_FORM_PANEL":
            return {
                ...state,
                dataAccessKeyFormPanel: {
                    ...state.dataAccessKeyFormPanel,
                    open: true,
                },
            };
        case "CLOSE_DATA_ACCESS_KEY_FORM_PANEL":
            return {
                ...state,
                dataAccessKeyFormPanel: {
                    ...state.dataAccessKeyFormPanel,
                    open: false,
                },
            };
        default:
            return state;
    }
};
