import {
    DatasetStyleType,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mapValues from "lodash/mapValues";
import { Expression, GeoJSONSource } from "mapbox-gl";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";
import {
    createSquareIcon,
    getLayerHeatmapStyle,
} from "../../utils/style-utils";
import { MapContextDataset } from "../context";
import { CustomShapeSource, DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";
import { isShapeStyleLayer } from "./data-layers-utils";

export const getFiltersLayers = ({
    dataset,
    sources,
    currentStyles,
    addedStyles,
    suffix = "preview",
}: {
    suffix?: string;
    dataset?: MapContextDataset;
    sources: {
        aggregate: string;
        points: string;
    };
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
}): Record<
    Exclude<
        DatasetStyleType,
        DatasetStyleType.stroke | DatasetStyleType.dataAggregation
    >,
    mapboxgl.AnyLayer | Record<ShapeStyle, mapboxgl.AnyLayer>
> => {
    const color = DEFAULT_SHAPE_COLOR;
    const opacity = dataset?.dataSource?.opacity || 0;
    const customMarker = dataset?.dataSource?.icon || "airport";
    const { heatMapColorArray } = getLayerHeatmapStyle(
        addedStyles?.dataAggregation?.heatmap,
        dataset?.dataSource?.color || undefined
    );

    const heatmap: Expression = !isEmpty(heatMapColorArray)
        ? ["interpolate", ["linear"], ["heatmap-density"], ...heatMapColorArray]
        : [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(255,255,255,0)",
              0.5,
              `${addedStyles?.fill?.color || color}`,
              1,
              `${addedStyles?.fill?.color || color}`,
          ];

    return {
        [DatasetStyleType.shape]: {
            [ShapeStyle.square]: {
                id: `filtered-points-icon-square-${suffix}`,
                type: "symbol",
                source: sources.points,
                minzoom: 15,
                layout: {
                    "icon-image": "filtered-square-icon",
                    "icon-size": 0.7,
                },
                paint: {
                    "icon-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
            [ShapeStyle.oval]: {
                id: `filtered-points-icon-oval-${suffix}`,
                type: "circle",
                source: sources.points,
                minzoom: 15,
                paint: {
                    "circle-radius": 4,
                    "circle-color": `${currentStyles?.fill?.color || color}`,
                    "circle-stroke-color": `${currentStyles?.stroke?.color || color}`,
                    "circle-stroke-width": 2,
                    "circle-stroke-opacity": {
                        default: currentStyles?.stroke?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.stroke?.opacity || opacity],
                        ],
                    },
                    "circle-opacity": {
                        default: currentStyles?.fill?.opacity || opacity,
                        stops: [
                            [14, 0],
                            [15, currentStyles?.fill?.opacity || opacity],
                        ],
                    },
                },
            },
        },
        [DatasetStyleType.fill]: {
            id: `filtered-heatmap-${suffix}`,
            type: "heatmap",
            source: sources.aggregate,
            paint: {
                "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "count"],
                    0,
                    0,
                    3000,
                    20,
                ],
                "heatmap-intensity": {
                    stops: [
                        [8, 1],
                        [11, 3],
                    ],
                },
                "heatmap-radius": 15,
                "heatmap-color": heatmap,
                "heatmap-opacity": {
                    default: addedStyles?.fill?.opacity || opacity,
                    stops: [
                        [14, addedStyles?.fill?.opacity || opacity],
                        [15, 0],
                    ],
                },
            },
        },
        [DatasetStyleType.customMarker]: {
            id: `filtered-custom-icons-${suffix}`,
            type: "symbol",
            source: sources.points,
            minzoom: 15,
            layout: {
                "icon-image":
                    addedStyles?.customMarker ||
                    currentStyles?.customMarker ||
                    customMarker,
                "icon-size": 1.2,
                visibility: "none",
            },
            paint: {
                "icon-opacity": {
                    default: currentStyles?.fill?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles?.fill?.opacity || opacity],
                    ],
                },
            },
        },
    };
};

export const setFiltersLayers = (input: {
    addedStyles: Partial<MapFilterCriteriaStyle>;
    currentStyles: Partial<MapFilterCriteriaStyle>;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix: string;
}) => {
    const { map, isLoaded, addedStyles, currentStyles, suffix } = input;

    const color = DEFAULT_SHAPE_COLOR;
    const opacity = 0.9;

    if (isLoaded && map.current) {
        if (isEqual(addedStyles.shape, ShapeStyle.square)) {
            map.current.setLayoutProperty(
                `filtered-points-icon-square-${suffix}`,
                "visibility",
                "visible"
            );

            const image = map.current.hasImage("filtered-square-icon");

            const squareColor = {
                color: currentStyles.fill?.color || color,
                opacity: currentStyles.fill?.opacity || opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };

            if (image) {
                map.current.updateImage(
                    "filtered-square-icon",
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    })
                );
            } else {
                map.current.addImage(
                    "filtered-square-icon",
                    createSquareIcon({
                        color: squareColor,
                        stroke: squareStroke,
                    }),
                    { pixelRatio: 4 }
                );
            }

            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-oval-${suffix}`,
                "visibility",
                "none"
            );
        }

        if (isEqual(addedStyles.shape, ShapeStyle.oval)) {
            map.current.setLayoutProperty(
                `filtered-points-icon-oval-${suffix}`,
                "visibility",
                "visible"
            );

            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-square-${suffix}`,
                "visibility",
                "none"
            );

            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-color",
                `${currentStyles.fill?.color || color}`
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                `${currentStyles.stroke?.color || color}`
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-opacity",
                {
                    default: currentStyles.stroke?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles.stroke?.opacity || opacity],
                    ],
                }
            );
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                {
                    default: currentStyles.fill?.opacity || opacity,
                    stops: [
                        [14, 0],
                        [15, currentStyles.fill?.opacity || opacity],
                    ],
                }
            );
        }

        if (addedStyles.fill && !isEmpty(addedStyles.fill)) {
            // Update square shape color
            const squareColor = {
                color: addedStyles.fill.color,
                opacity: addedStyles.fill.opacity,
            };

            const squareStroke = {
                color: currentStyles.stroke?.color || color,
                opacity: currentStyles.stroke?.opacity || opacity,
            };
            map.current.updateImage(
                "filtered-square-icon",
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );

            // Update oval shape color
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-color",
                addedStyles.fill.color
            );

            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-opacity",
                addedStyles.fill.opacity
            );

            // Update custom marker color
            map.current.setPaintProperty(
                `filtered-custom-icons-${suffix}`,
                "icon-color",
                addedStyles.fill.color
            );
        }

        if (addedStyles.stroke && !isEmpty(addedStyles.stroke)) {
            map.current.setPaintProperty(
                `filtered-points-icon-oval-${suffix}`,
                "circle-stroke-color",
                addedStyles.stroke.color
            );

            // Update square icon color
            const squareColor = {
                color: currentStyles.fill?.color,
                opacity: currentStyles.fill?.opacity,
            };

            const squareStroke = {
                color: addedStyles.stroke.color,
                opacity: addedStyles.stroke.opacity || opacity,
            };

            map.current.updateImage(
                "filtered-square-icon",
                createSquareIcon({
                    color: squareColor,
                    stroke: squareStroke,
                })
            );
        }

        if (addedStyles.customMarker) {
            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "visibility",
                "visible"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-square-${suffix}`,
                "visibility",
                "none"
            );
            map.current.setLayoutProperty(
                `filtered-points-icon-oval-${suffix}`,
                "visibility",
                "none"
            );

            map.current.setLayoutProperty(
                `filtered-custom-icons-${suffix}`,
                "icon-image",
                addedStyles.customMarker
            );
        }

        if (addedStyles.dataAggregation?.heatmap) {
            const { heatMapColorArray } = getLayerHeatmapStyle(
                addedStyles.dataAggregation.heatmap
            );

            map.current.setLayoutProperty(
                `filtered-heatmap-${suffix}`,
                "visibility",
                "visible"
            );

            map.current.setPaintProperty(
                `filtered-heatmap-${suffix}`,
                "heatmap-color",
                [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    ...heatMapColorArray,
                ]
            );
        }
    }
};

export const setFilterVisibility = (input: {
    visibility: "visible" | "none";
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    suffix?: string;
    dataset?: MapContextDataset;
    currentStyles?: Partial<MapFilterCriteriaStyle>;
    addedStyles?: Partial<MapFilterCriteriaStyle>;
}) => {
    const {
        visibility,
        map,
        isLoaded,
        suffix = "preview",
        dataset,
        currentStyles,
        addedStyles,
    } = input;

    if (map.current && isLoaded) {
        const layers = getFiltersLayers({
            sources: {
                aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                points: `${CustomShapeSource.filtering}-points-${suffix}`,
            },
            dataset,
            suffix,
            currentStyles,
            addedStyles,
        });

        if (isEqual(visibility, "none")) {
            const aggregateSource = map.current.getSource(
                `${CustomShapeSource.filtering}-aggregate-${suffix}`
            ) as GeoJSONSource;

            const pointsSource = map.current.getSource(
                `${CustomShapeSource.filtering}-points-${suffix}`
            ) as GeoJSONSource;

            if (aggregateSource) {
                aggregateSource.setData({
                    type: "FeatureCollection",
                    features: [],
                });
            }

            if (pointsSource) {
                pointsSource.setData({
                    type: "FeatureCollection",
                    features: [],
                });
            }

            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    map.current?.removeLayer(value.square.id);
                    map.current?.removeLayer(value.oval.id);
                } else {
                    map.current?.removeLayer(value.id);
                }
            });
        }

        if (isEqual(visibility, "visible")) {
            mapValues(layers, (value) => {
                if (isShapeStyleLayer(value)) {
                    map.current?.addLayer(value.square);
                    map.current?.addLayer(value.oval);
                } else {
                    map.current?.addLayer(value);
                }
            });
        }
    }
};
