import { CellItem, IconAvatar } from "@biggeo/bg-ui/lab";
import { ChevronRightOutline, KeyOutline } from "@biggeo/bg-ui/lab/icons";
import { ComponentProps } from "react";

export type DatasetAccessKeyCellItemProps = Pick<
    ComponentProps<typeof CellItem>,
    "variant" | "density" | "onClick"
>;

export const DatasetAccessKeyCellItem = (
    props: DatasetAccessKeyCellItemProps
) => {
    return (
        <CellItem
            startNode={
                <IconAvatar size="sm" square>
                    <KeyOutline size="md" />
                </IconAvatar>
            }
            endNode={<ChevronRightOutline />}
            title="Have a dataset access key?"
            subtitle="Unlock dataset with access key"
            slotProps={{
                subtitle: {
                    sx: {
                        color: (theme) => theme.palette.disabled.onContainer,
                    },
                },
            }}
            {...props}
        />
    );
};
