import {
    FilterObjectType,
    useFetchDataSourcesLazyQuery,
    useRemoveDataSetMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { ErrorPage } from "../../common/components/ErrorPage";
import { useMap } from "../../map/mapbox/context";
import { SetDatasetContextType } from "../../map/mapbox/context/context-utils";
import { DatasetInfo } from "../../map/mapbox/views/MarketplaceDataView";
import { ExploreDatasetsView } from "../views/ExploreDatasetsView";

export const ExploreDatasetsViewContainer = ({
    exploreDataset,
    previewInfo,
    refetchDatasourcePreview,
    setRefetchDatasourcePreview,
    addMultipleDatasets,
    addRemoveDataset,
    updateDataset,
    addDatasets,
    isSavedViewPage,
}: {
    readonly exploreDataset: () => void;
    readonly previewInfo: (data: DatasetInfo) => void;
    readonly setRefetchDatasourcePreview: (value: boolean) => void;
    readonly refetchDatasourcePreview: boolean;
    readonly addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
    readonly addRemoveDataset: (v: string) => void;
    readonly isSavedViewPage: boolean;
} & Pick<SetDatasetContextType, "updateDataset" | "addDatasets">) => {
    const { datasets, map, isLoaded } = useMap();
    const { executeQuery, remote } = useFetchDataSourcesLazyQuery();
    const { executeMutation: removeDataset } = useRemoveDataSetMutation();

    const onRemove = (collection: string) => {
        removeDataset({ variables: { collectionName: collection } });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (refetchDatasourcePreview) {
            executeQuery({
                variables: {
                    input: {
                        isPreview: true,
                    },
                },
                onCompleted: (d) => {
                    if (!isSavedViewPage) {
                        setRefetchDatasourcePreview(false);

                        addMultipleDatasets(
                            d.fetchDataSources.dataSources.map((c) => ({
                                collection: c.collectionName,
                                color: c.color,
                                databaseId: c.id,
                                databaseType: c.type,
                                isPreview: c.isPreview,
                            }))
                        );

                        datasets.map((dataset) => {
                            const found = d.fetchDataSources.dataSources.find(
                                (i) => i.id === dataset.dataSource.id
                            );

                            if (found) {
                                updateDataset({
                                    dataSourceId: found.id,
                                    dataset: {
                                        dataSource: found,
                                        isVisible: true,
                                        isSelected: true,
                                        isGettingStyled: false,
                                        isLegendOpen: false,
                                        isTableViewed: false,
                                    },
                                });
                            }
                        });

                        d.fetchDataSources.dataSources.map((dataSource) => {
                            addDatasets({
                                dataSource,
                                mapTemplateDataset: undefined,
                                isVisible: true,
                                isSelected: true,
                                isGettingStyled: false,
                                isLegendOpen: false,
                                isTableViewed: false,
                            });
                        });
                    }
                },
            });
        }
    }, [refetchDatasourcePreview]);

    return match(remote, {
        _: () => <LoadingBar />,
        Success: () => {
            return (
                // TODO: add onEdit, onView, and onRemove function
                <ExploreDatasetsView
                    datasets={pipe(
                        datasets,
                        A.filter((d) => isEqual(d.dataSource.isPreview, true))
                    )}
                    previewInfo={previewInfo}
                    exploreDataset={exploreDataset}
                    onRemove={onRemove}
                    addRemoveDataset={addRemoveDataset}
                    updateDataset={updateDataset}
                    addDatasets={addDatasets}
                    map={map}
                    isLoaded={isLoaded}
                />
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};
