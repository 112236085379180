import {
    DataSource,
    DatabaseType,
    DatasetStyleType,
    LevelSet,
    MapTemplateDataset,
    MapTemplateDatasetExtended,
    ShapeColor,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isString from "lodash/isString";
import mapValues from "lodash/mapValues";
import replace from "lodash/replace";
import some from "lodash/some";
import { Expression, GeoJSONSource } from "mapbox-gl";
import { useMemo, useState } from "react";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { MapColorSetterType } from "../../common/types/color-picker";
import { UpdateMapTemplateDatasetContext } from "../../database-meta-data/redux/model";
import { levelSetPercentiles } from "../../utils/variables";
import { MapFilterCriteriaStyle } from "../filter-criteria/utils/utils";
import { MapAction, MapContextDataset } from "../mapbox/context";
import {
    CustomShapeSource,
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../mapbox/hooks/style-hooks";
import {
    getDatasetStyles,
    isShapeStyleLayer,
    setDatasetLayers,
} from "../mapbox/utils/data-layers-utils";
import { HeatMapColorSwatchOption } from "../mapbox/utils/heatmap";
import { MapShapeColorType } from "../views/MapShapeLayerStyles";
import { generateColorScale, generateSteps } from "./utils";

export const vertexLayers = [
    "gl-draw-polygon-and-line-vertex-inactive.hot",
    "gl-draw-polygon-and-line-vertex-inactive.cold",
    "gl-draw-polygon-and-line-vertex-active.hot",
    "gl-draw-polygon-and-line-vertex-active.cold",
    "gl-draw-polygon-midpoint.hot",
    "gl-draw-polygon-midpoint.cold",
    "gl-draw-point-active.hot",
    "gl-draw-point-active.cold",
];

export const onCustomShapeLayerLoad = (
    map: mapboxgl.Map,
    features?: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >
): GeoJSONSource => {
    const customizationSource = map.getSource(
        CustomShapeSource.customization
    ) as GeoJSONSource;

    if (customizationSource && features) {
        customizationSource.setData(features);
    }

    map.addLayer({
        id: "shape-customization-fill",
        type: "fill",
        source: CustomShapeSource.customization,
        paint: {
            "fill-color": [
                "coalesce",
                ["get", "fill-color"],
                DEFAULT_SHAPE_COLOR,
            ],
            "fill-opacity": [
                "coalesce",
                ["get", "fill-opacity"],
                DEFAULT_SHAPE_OPACITY,
            ],
        },
        filter: ["==", ["geometry-type"], "Polygon"],
    });

    map.addLayer({
        id: "shape-customization-line",
        type: "line",
        source: CustomShapeSource.customization,
        paint: {
            "line-color": [
                "coalesce",
                ["get", "stroke-color"],
                DEFAULT_SHAPE_COLOR,
            ],
            "line-width": ["coalesce", ["get", "stroke-width"], 2],
            "line-opacity": ["coalesce", ["get", "stroke-opacity"], 1],
        },
        filter: ["==", ["geometry-type"], "Polygon"],
    });

    return customizationSource;
};

export const setLayersFilter = ({
    map,
    isSelectMode,
}: { map: mapboxgl.Map; isSelectMode: boolean }) => {
    if (isEqual(isSelectMode, true)) {
        // Hide the draw layers when select mode is true
        map.setFilter("gl-map-shapes-fill.hot", ["!=", "user_selected", true]);
        map.setFilter("gl-map-shapes-fill.cold", ["!=", "user_selected", true]);

        map.setFilter("gl-map-shapes-line.hot", ["!=", "user_selected", true]);
        map.setFilter("gl-map-shapes-line.cold", ["!=", "user_selected", true]);

        map.setFilter("gl-draw-polygon-stroke-active.hot", [
            "!=",
            "user_selected",
            true,
        ]);
        map.setFilter("gl-draw-polygon-stroke-active.cold", [
            "!=",
            "user_selected",
            true,
        ]);

        // Hide the shape customization layers when select mode is true
        map.setFilter("shape-customization-fill", ["!=", "selected", true]);
        map.setFilter("shape-customization-line", ["!=", "selected", true]);
    }

    if (isEqual(isSelectMode, false)) {
        // Show the draw layers when select mode is false
        map.setFilter("gl-map-shapes-fill.hot", [
            "all",
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-map-shapes-fill.cold", [
            "all",
            ["==", "$type", "Polygon"],
        ]);

        map.setFilter("gl-map-shapes-line.hot", [
            "all",
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-map-shapes-line.cold", [
            "all",
            ["==", "$type", "Polygon"],
        ]);

        map.setFilter("gl-draw-polygon-stroke-active.hot", [
            "all",
            ["==", "active", "true"],
            ["==", "$type", "Polygon"],
        ]);
        map.setFilter("gl-draw-polygon-stroke-active.cold", [
            "all",
            ["==", "active", "true"],
            ["==", "$type", "Polygon"],
        ]);

        // Show the shape customization layers when select mode is false
        map.setFilter("shape-customization-fill", undefined);
        map.setFilter("shape-customization-line", undefined);
    }
};

export const removeCustomLayers = (map: mapboxgl.Map) => {
    map.removeLayer("shape-customization-fill");
    map.removeLayer("shape-customization-line");

    map.removeLayer(`map-shapes-fill-${CustomShapeSource.select}`);
    map.removeLayer(`map-shapes-line-${CustomShapeSource.select}`);
};

export const isTouchEvent = (
    value: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | TouchEvent
): value is TouchEvent => {
    return value.type.includes("touch");
};

export const isMapTouchEvent = (
    value:
        | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
        | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
): value is mapboxgl.MapTouchEvent & mapboxgl.EventData => {
    return value.type.includes("touch");
};

export const resize = ({
    map,
    draw,
    isLoaded,
    onResize,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    onResize: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease?: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isResizing = false;
    let featureId: string | undefined = undefined;
    let isMidpoint = false;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onMouseDown = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        const selectedFeature = draw?.getSelected().features[0];

        const id =
            selectedFeature && isString(selectedFeature.id)
                ? selectedFeature.id
                : undefined;

        if (map && selectedFeature) {
            const features = map.queryRenderedFeatures(e.point, {
                layers: vertexLayers,
            });

            const isVertex =
                !isEmpty(features) &&
                some(
                    features,
                    (f) =>
                        f.properties?.meta === "vertex" ||
                        f.properties?.meta === "midpoint"
                );

            if (isVertex) {
                isResizing = true;
                featureId = id;
                isMidpoint = some(
                    features,
                    (f) => f.properties?.real_meta === "midpoint"
                );
            } else {
                isResizing = false;
                featureId = undefined;
            }
        }
    };

    const onMouseUp = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });

            isResizing = false;
            isMidpoint = false;
            featureId = undefined;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });
        }

        isResizing = false;
        isMidpoint = false;
        featureId = undefined;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        if (isEqual(isResizing, true)) {
            const selectedFeatureWithUpdatedCoordinates =
                featureId && draw ? draw.get(featureId) : undefined;

            const x = isMapTouchEvent(e)
                ? e.originalEvent.touches[0].clientX - left
                : e.originalEvent.clientX - left;
            const y = isMapTouchEvent(e)
                ? e.originalEvent.touches[0].clientY - top
                : e.originalEvent.clientY - top;

            featureId = selectedFeatureWithUpdatedCoordinates?.id?.toString();

            const cursor = { x, y };

            onResize({
                isResizing,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
                isMidpoint,
            });
        }
    };

    const onTouchStart = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onMouseDown(e);
    };

    const onTouchMove = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onMouseMove(e);
    };

    const onTouchEnd = () => {
        onMouseUp();
    };

    if (map && isLoaded) {
        map.on("mousedown", onMouseDown);
        map.on("mouseup", onMouseUp);
        map.on("mousemove", onMouseMove);
        map.on("draw.update", onDrawUpdate);

        map.on("touchstart", onTouchStart);
        map.on("touchmove", onTouchMove);
        map.on("touchend", onTouchEnd);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off("mousedown", onMouseDown);
                map.off("mouseup", onMouseUp);
                map.off("mousemove", onMouseMove);
                map.off("draw.update", onDrawUpdate);

                map.off("touchstart", onTouchStart);
                map.off("touchmove", onTouchMove);
                map.off("touchend", onTouchEnd);
            }
        },
    };
};

export const drag = ({
    map,
    draw,
    isLoaded,
    onDrag,
    mode,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    mode: boolean;
    onDrag: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isDragging = false;
    let selected:
        | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
        | undefined = undefined;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onClick = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        const shape = isMapTouchEvent(e)
            ? draw?.getSelected().features[0]
            : e.features[0];

        if (map && shape) {
            selected = {
                type: "Feature",
                id: shape.id,
                geometry: shape.geometry,
                properties: shape.properties,
            };
        }
    };

    const onMouseEnter = (
        e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData
    ) => {
        e.preventDefault();
        if (canvas) {
            canvas.style.cursor = "pointer";
        }
    };

    const onMouseUp = () => {
        onRelease({
            isDragging,
            feature: selected,
        });

        if (isEqual(isDragging, true)) {
            isDragging = false;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isDragging, true)) {
            onRelease({
                isDragging,
                feature: selected,
            });
        }

        isDragging = false;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (
        e: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | TouchEvent
    ) => {
        const selectedFeatureWithUpdatedCoordinates =
            selected && isString(selected.id) && draw
                ? draw.get(selected.id)
                : undefined;

        const hasMoved = !isEqual(
            selected?.geometry,
            selectedFeatureWithUpdatedCoordinates?.geometry
        );

        const x = isTouchEvent(e)
            ? e.touches[0].clientX - left
            : e.originalEvent.clientX - left;
        const y = isTouchEvent(e)
            ? e.touches[0].clientY - top
            : e.originalEvent.clientY - top;

        const cursor = { x, y };

        if (hasMoved && isEqual(mode, true)) {
            isDragging = true;

            selected = selectedFeatureWithUpdatedCoordinates;

            onDrag({
                isDragging,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
            });
        } else {
            isDragging = false;
        }
    };

    const onTouchStart = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onClick(e);
    };

    const onTouchMove = (e: TouchEvent) => {
        onMouseMove(e);
    };

    const onTouchEnd = () => {
        onMouseUp();
    };

    if (map && isLoaded && isEqual(mode, true)) {
        map.on("click", `map-shapes-fill-${CustomShapeSource.select}`, onClick);
        map.on(
            "mouseenter",
            `map-shapes-fill-${CustomShapeSource.select}`,
            onMouseEnter
        );
        map.on("mousemove", onMouseMove);
        map.on("mouseup", onMouseUp);
        map.on("draw.update", onDrawUpdate);

        map.on("touchstart", onTouchStart);
        map.on("touchend", onTouchEnd);
        canvas?.addEventListener("touchmove", onTouchMove);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off(
                    "click",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onClick
                );
                map.off(
                    "mouseenter",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onMouseEnter
                );
                map.off("mousemove", onMouseMove);
                map.off("mouseup", onMouseUp);
                map.off("draw.update", onDrawUpdate);

                map.off("touchstart", onTouchStart);
                map.off("touchend", onTouchEnd);
                canvas?.removeEventListener("touchmove", onTouchMove);
            }
        },
    };
};

export const hexToRgba = ({
    hex,
    opacity = 0.9,
}: { hex?: string; opacity?: number }) =>
    pipe(
        hex,
        O.fromNullable,
        O.fold(
            () => [0, 0, 0, opacity],
            (hex) =>
                pipe(
                    replace(hex, "#", ""),
                    (color) => color.match(/.{1,2}/g),
                    O.fromNullable,
                    O.foldW(
                        () => [0, 0, 0, opacity],
                        (color) => [
                            ...A.map((c: string) => Number.parseInt(c, 16))(
                                color
                            ),
                            opacity,
                        ]
                    )
                )
        )
    );

export const createSquareIcon = ({
    size = 64,
    color,
    stroke,
    borderWidth = 10,
}: {
    size?: number;
    color?: Partial<MapShapeColorType>;
    stroke?: Partial<MapShapeColorType>;
    borderWidth?: number;
}) => {
    const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
    const imageData = new Uint8ClampedArray(size * size * bytesPerPixel);
    const [red, green, blue, opacity] = hexToRgba({
        hex: color?.color,
        opacity: color?.opacity,
    });
    const [bRed, bGreen, bBlue, bOpacity] = hexToRgba({
        hex: stroke?.color,
        opacity: stroke?.opacity,
    });

    for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            const index = (y * size + x) * bytesPerPixel;
            if (
                x < borderWidth ||
                x >= size - borderWidth ||
                y < borderWidth ||
                y >= size - borderWidth
            ) {
                imageData[index] = bRed;
                imageData[index + 1] = bGreen;
                imageData[index + 2] = bBlue;
                imageData[index + 3] = Math.round(bOpacity * 255);
            } else {
                // Inside the square
                imageData[index] = red;
                imageData[index + 1] = green;
                imageData[index + 2] = blue;
                imageData[index + 3] = Math.round(opacity * 255);
            }
        }
    }
    return { width: size, height: size, data: imageData };
};

export const getLayerHeatmapStyle = (
    value?: ColorSwatchOption,
    color?: string
): {
    steps: number[];
    heatMapColorArray: (string | number)[];
    colorSet: string[];
    colorMap: (string | number)[];
} => {
    if (!value) {
        if (color) {
            const heatmapColorSet = generateColorScale(color, 1.5, 3);

            const colorSet = generateColorScale(color, 3, 20);

            const colorMap = colorSet.flatMap((color, idx) => [
                idx,
                `${color}`,
            ]);

            return {
                steps: [],
                heatMapColorArray: [
                    0,
                    "rgba(255,255,255,0)",
                    0.1,
                    heatmapColorSet[0],
                    0.5,
                    heatmapColorSet[1],
                    1,
                    heatmapColorSet[2],
                ],
                colorSet,
                colorMap,
            };
        }

        return { steps: [], heatMapColorArray: [], colorSet: [], colorMap: [] };
    }

    const steps = generateSteps(value.swatch.length);

    // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
    const heatMapColorArray = steps
        .map((step, i) => [step, value.swatch[i].color])
        .flatMap((c) => c);

    const colorSet = generateColorScale(
        value.swatch[steps.length / 2]?.color || "#ffffff",
        3,
        20
    );

    const colorMap = colorSet.flatMap((color, idx) => [idx, `${color}`]);

    return { steps, heatMapColorArray, colorSet, colorMap };
};

export const generateHeatMapInitialValues = (
    datasetId: string,
    color: string
): HeatMapColorSwatchOption => {
    const heatmapColorSet = generateColorScale(color, 1.5, 3);

    return {
        datasetId,
        swatch: {
            id: "custom",
            swatch: [
                {
                    range: 0,
                    color: "rgba(255,255,255,0)",
                },
                {
                    range: 100,
                    color: heatmapColorSet[0],
                },
                {
                    range: 400,
                    color: heatmapColorSet[1],
                },
                {
                    range: 900,
                    color: heatmapColorSet[2],
                },
            ],
        },
    };
};
