import {
    DbColumnType,
    SchemaRow,
    SnowflakeColumn,
    UserSnowflakeTableData,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    AutoComplete,
    Button,
    ColorPicker,
    CompanyAvatar,
    Divider,
    Grid,
    Radio,
    Stack,
    ThumbnailAvatar,
    colorToHexString,
} from "@biggeo/bg-ui/lab";
import {
    CompareArrowsOutline,
    CropSquare,
    SearchOutline,
} from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { Typography } from "@mui/material";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { match as tsMatch } from "ts-pattern";

export type MappingModalProps = Readonly<{
    tableColumns: ReadonlyArray<SchemaRow>;
    tableId: string;
    onChange: (field: string, val: string) => void;
    values: Readonly<{
        geoSpatialColumn: string;
        lat: string;
        long: string;
        color: string;
    }>;
}>;

export const NewMappingModal = ({
    tableColumns,
    tableId,
    values,
    onChange,
}: MappingModalProps) => {
    const [columnCount, setColumnCount] = useState<1 | 2 | undefined>(
        undefined
    );

    return (
        <Stack
            width="100%"
            sx={{
                backgroundColor: (theme) => theme.palette.surface.container,
                padding: 4,
                gap: 4,
                borderRadius: (theme) => theme.radius.xs2,
            }}
        >
            <Stack gap={4} alignItems="flex-start">
                <Stack
                    flexDirection={"row"}
                    gap={1}
                    alignItems="center"
                    sx={{
                        padding: 2,
                        backgroundColor: (theme) =>
                            theme.palette.surface.onMain,
                        color: (theme) => theme.palette.disabled.main,
                        borderRadius: (theme) => theme.radius.xs3,
                    }}
                >
                    <ThumbnailAvatar
                        color="background"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        src="https://biggeo.blob.core.windows.net/media/sf.png"
                        size="md"
                    />

                    <CompareArrowsOutline color={"disabled"} />
                    <CompanyAvatar
                        color="primary"
                        sx={{
                            borderRadius: (theme) => theme.radius.default,
                        }}
                        src="https://biggeo.blob.core.windows.net/media/bg-background.png"
                        size="md"
                    />
                </Stack>
                <Stack>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        Map Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        We need you to select your geospatial columns.
                    </Typography>
                </Stack>
                <Stack gap={4} width="100%">
                    <Button
                        variant="minimal"
                        sx={{
                            color: (theme) => theme.palette.info.main,
                        }}
                    >
                        Learn More
                    </Button>
                    <Divider />
                </Stack>
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        1. Select Primary Key
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        Specify primary key column for your dataset here
                    </Typography>
                </Stack>
                <AutoComplete
                    value={tableId}
                    options={pipe(
                        tableColumns,
                        toNonReadonlyArray,
                        A.filter(
                            (d) =>
                                d.dataType === DbColumnType.String ||
                                d.dataType === DbColumnType.Number
                        ),
                        A.filter(
                            (d) =>
                                d.columnName !== values.geoSpatialColumn &&
                                d.columnName !== values.lat &&
                                d.columnName !== values.long
                        ),
                        A.map((data) => ({
                            label: data.columnName,
                        }))
                    )}
                    required
                    label="Primary key"
                    placeholder="Select"
                    startNode={<SearchOutline />}
                    helperText="Select your primary key"
                    fullWidth
                    onChange={(_, value) => {
                        onChange("tableId", value || "");
                        const tableIdType = pipe(
                            tableColumns,
                            toNonReadonlyArray,
                            A.findFirst((c) => c.columnName === value),
                            O.foldW(
                                () => "",
                                ({ dataType }) => dataType
                            ),
                            (type) => {
                                if (type === DbColumnType.String) {
                                    return "TEXT";
                                }
                                return "";
                            }
                        );

                        onChange("tableIdType", tableIdType);
                    }}
                />

                <Divider />
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        2. Choose Number Of Geospatial Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        How many geospatial columns does your dataset have?
                    </Typography>
                </Stack>
                <Grid container gap={1}>
                    <Grid item>
                        <Radio
                            checked={isEqual(columnCount, 1)}
                            onChange={() => {
                                setColumnCount(1);
                                onChange("lat", "");
                                onChange("long", "");
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body3">1 Column</Typography>
                    </Grid>
                </Grid>
                <Grid container gap={1}>
                    <Grid item>
                        <Radio
                            checked={isEqual(columnCount, 2)}
                            onChange={() => {
                                setColumnCount(2);
                                onChange("geoSpatialColumn", "");
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body3">2 Columns</Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Stack>
            <Stack gap={4}>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        color={(theme) => theme.palette.background.onMain}
                    >
                        3. Map Your Geospatial Columns
                    </Typography>
                    <Typography
                        variant="body3"
                        fontWeight="regular"
                        color={(theme) => theme.palette.disabled.onContainer}
                    >
                        Select the columns from your dataset that represent
                        their geospatial attribute
                    </Typography>
                </Stack>
                <Stack>
                    {tsMatch(columnCount)
                        .with(1, () => (
                            <AutoComplete
                                options={pipe(
                                    tableColumns,
                                    toNonReadonlyArray,
                                    A.filter(
                                        (d) =>
                                            d.dataType ===
                                            DbColumnType.Geospatial
                                    ),
                                    A.filter((d) => d.columnName !== tableId),
                                    A.map((data) => ({
                                        label: data.columnName,
                                    }))
                                )}
                                required
                                label="Geospatial Column"
                                placeholder="Select"
                                startNode={<SearchOutline />}
                                fullWidth
                                onChange={(_, value) => {
                                    onChange("geoSpatialColumn", value || "");
                                }}
                            />
                        ))
                        .with(2, () => (
                            <Grid
                                container
                                gap={4}
                                sx={{
                                    breakpoints: {
                                        cmd: {
                                            flexDirection: "row",
                                        },
                                        cxs: {
                                            flexDirection: "column",
                                        },
                                    },
                                }}
                            >
                                <Grid item md xs={12}>
                                    <AutoComplete
                                        options={pipe(
                                            tableColumns,
                                            toNonReadonlyArray,
                                            A.filter(
                                                (d) =>
                                                    d.dataType ===
                                                    DbColumnType.Number
                                            ),
                                            A.filter(
                                                (d) =>
                                                    d.columnName !== tableId &&
                                                    d.columnName !== values.long
                                            ),
                                            A.map((data) => ({
                                                label: data.columnName,
                                            }))
                                        )}
                                        required
                                        label="Latitude"
                                        placeholder="Search latitude"
                                        startNode={<SearchOutline />}
                                        fullWidth
                                        onChange={(_, value) => {
                                            onChange("lat", value || "");
                                        }}
                                    />
                                </Grid>
                                <Grid item md xs={12}>
                                    <AutoComplete
                                        options={pipe(
                                            tableColumns,
                                            toNonReadonlyArray,
                                            A.filter(
                                                (d) =>
                                                    d.dataType ===
                                                    DbColumnType.Number
                                            ),
                                            A.filter(
                                                (d) =>
                                                    d.columnName !== tableId &&
                                                    d.columnName !== values.lat
                                            ),
                                            A.map((data) => ({
                                                label: data.columnName,
                                            }))
                                        )}
                                        required
                                        label="Longitude"
                                        placeholder="Search longitude"
                                        startNode={<SearchOutline />}
                                        fullWidth
                                        onChange={(_, value) => {
                                            onChange("long", value || "");
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ))
                        .with(undefined, () => undefined)
                        .exhaustive()}
                </Stack>
                {columnCount && (
                    <Stack gap={4}>
                        <Stack>
                            <Typography
                                variant="title2"
                                fontWeight="bold"
                                color={(theme) =>
                                    theme.palette.background.onMain
                                }
                            >
                                4. Select Your Dataset Color{" "}
                            </Typography>
                            <Typography
                                variant="body3"
                                fontWeight="regular"
                                color={(theme) =>
                                    theme.palette.disabled.onContainer
                                }
                            >
                                Pick the default color your want for this
                                dataset on the map
                            </Typography>
                        </Stack>
                        <Stack gap={0.5}>
                            <Grid
                                container
                                gap={2}
                                alignItems="center"
                                sx={{ padding: 1 }}
                            >
                                <Grid item>
                                    <CropSquare
                                        sx={{
                                            color: values.color || "#fffff",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        variant="body3"
                                        fontWeight="regular"
                                    >
                                        {values.color || "#fffff"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item maxWidth={"400"}>
                                <ColorPicker
                                    initialColor={values.color}
                                    onApply={(color) => {
                                        onChange(
                                            "color",
                                            colorToHexString(color.hex)
                                        );
                                    }}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
