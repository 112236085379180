import { ActionType, action } from "typesafe-actions";
export enum Routes {
    home = "/",
    tutorial = "/tutorial",

    mapView = "/map-view",

    data = "/data",
    dataManage = "/data/manage",
    mapTemplateData = "/data/map-template",

    listView = "/marketplace/listView",
    marketplaceMapView = "/marketplace/mapView",
    upgrade = "/upgrade",
    datasets = "/datasets",
    datasources = "/datasources",
    configuration = "/configuration",

    availableAreas = "/available-areas",
    savedViews = "/saved-views",
    savedView = "savedViewId",

    marketplace = "/marketplace",
    unlocked = "/unlocked",
    accessKeys = "/access-keys",

    mapTemplates = "/map-templates",
    mapTemplatesCreate = "/map-templates/create",
    mapTemplatesManage = "/map-templates/manage",

    mapTemplateSavedViews = "/map-templates/saved-views",

    recipes = "/recipes",
    team = "/team",

    featureRequestAndIssues = "https://datascape.featurebase.app",
    feedback = "https://datascape.featurebase.app/?b=66aabcb6f611e8ff2d09cbe1",
    roadmap = "https://datascape.featurebase.app/roadmap",
    changeLog = "https://datascape.featurebase.app/changelog",
    support = "https://datascape.featurebase.app/help",
    installationGuide = "https://datascape.featurebase.app/help/collections/6943641-install-in-snowflake",
    joinBigGeoSlack = "https://join.slack.com/t/biggeocommunity/shared_invite/zt-2rdvooqsl-JrfuBv6DiopCsSVgKHr~0Q",

    bigGeoProductsDatalab = "https://www.biggeo.com/products/datalab",
    bigGeoProductsVelocity = "https://biggeo.com/products/velocity",
    savedAreas = "https://datascape.featurebase.app/help/articles/6177512-what-are-saved-areas",
}

export type AllRoutes = Routes;

export const actions = {
    toRoute: (p: `${AllRoutes}/${string}` | AllRoutes | string) =>
        action("TO_ROUTE", p),
};

export const navigationActions = actions;

export type NavigationActionType = ActionType<typeof actions>;
