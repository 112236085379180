import { DataGridProProps, GridColDef, GridValidRowModel } from "@biggeo/bg-ui";

import { LogicOperator } from "@biggeo/bg-server-lib/datascape-ai";
import {
    ColFilterItem,
    DataGridView,
    IFilterSearchPaginate,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import concat from "lodash/concat";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDebouncedSearch } from "../../common/redux/hooks";
import { convertFilterItem } from "./utils";

export interface DataGridContainerProps<T extends GridValidRowModel>
    extends Omit<DataGridProProps<T>, "columns"> {
    readonly columns: readonly GridColDef[];
    readonly filterSearchPaginateProps: IFilterSearchPaginate;
    readonly title: string;
    readonly disableSearch?: boolean;
    readonly filterableField?: readonly GridColDef["field"][];
    readonly enableColumnResize?: boolean;
    readonly hideFilterChips?: boolean;
}

export const DataGridContainer = <T extends GridValidRowModel>({
    title,
    rows,
    columns,
    filterSearchPaginateProps,
    ...props
}: DataGridContainerProps<T>) => {
    const { setSearchText, setFilterObject } = filterSearchPaginateProps;

    const debounceSearch = useDebouncedSearch(1000);
    const handleDebounceOnSearch = (str?: string) => {
        debounceSearch(setSearchText, str);
    };

    const [colFilterItems, setColFilterItems] = useState<ColFilterItem[]>([]);

    const [colLogicOperator, setColLogicOperator] = useState<LogicOperator>(
        LogicOperator.and
    );

    const handleFilterItemChange = (item: ColFilterItem, type?: "delete") => {
        setColFilterItems((prev) => {
            const isExist = pipe(
                prev,
                A.some((fi) => fi.id === item.id)
            );
            if (isExist && type === "delete") {
                return pipe(
                    prev,
                    A.filter((p) => p.id !== item.id)
                );
            }
            if (isExist) {
                return pipe(
                    prev,
                    A.map((p) => (p.id === item.id ? item : p))
                );
            }
            return concat(prev, item);
        });
    };

    const handleLogicOperatorChange = (logic: LogicOperator) => {
        if (colFilterItems.length) setColLogicOperator(logic);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setFilterObject(
            !isEmpty(colFilterItems)
                ? {
                      filters: colFilterItems.map(convertFilterItem),
                      logicOperator: colLogicOperator,
                  }
                : undefined
        );
    }, [colFilterItems, colLogicOperator]);

    return (
        <DataGridView
            title={title}
            columns={columns}
            filterSearchPaginateProps={filterSearchPaginateProps}
            handleDebounceOnSearch={handleDebounceOnSearch}
            handleFilterItemChange={handleFilterItemChange}
            handleLogicOperatorChange={handleLogicOperatorChange}
            colLogicOperator={colLogicOperator}
            colFilterItems={colFilterItems}
            rows={rows}
            {...props}
        />
    );
};
