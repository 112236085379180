import { SubscriptionResponse } from "@biggeo/bg-server-lib/datascape-ai";
import { Box, Stack } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { MapContextDataset } from "../context";
import MapLegendInfo from "./MapLegendInfo";

interface IMapInfoElements {
    readonly recentResponse?: SubscriptionResponse;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;
    readonly datasets: MapContextDataset[];
}

const MapInfoElements = ({
    responses,
    map,
    isLoaded,
    datasets,
}: IMapInfoElements) => {
    const datasetLegends = pipe(
        datasets,
        A.filter((f) => isEqual(f.isLegendOpen, true))
    );

    return (
        <Stack
            gap={2}
            justifyContent="flex-end"
            sx={{
                position: "absolute",
                left: 4,
                top: 4,
                display: "none",
                breakpoints: {
                    lg: {
                        width: 60,
                    },
                    md: {
                        display: "flex",
                    },
                    xs: {
                        width: 40,
                    },
                },
            }}
        >
            <Box
                id="geocoder"
                sx={{
                    "& >.mapboxgl-ctrl-geocoder.mapboxgl-ctrl": {
                        minWidth: "100%",
                    },
                }}
            />
            {/* <MapInfoView
                recentResponse={recentResponse}
                responses={responses}
                selectedDataset={selectedDataset}
            />
             */}
            {!isEmpty(datasetLegends) && (
                <MapLegendInfo
                    responses={responses}
                    map={map}
                    datasets={datasetLegends}
                    isLoaded={isLoaded}
                />
            )}
        </Stack>
    );
};

export default MapInfoElements;
