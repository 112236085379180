import type {
    FilterObject,
    ReqOptions,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useEffect } from "react";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import {
    FunctionType,
    SetSavedViewPolygonsType,
    getInputPolygon,
} from "../../utils/utils";
import { SavedPolygonType } from "./saved-polygon-hooks";

export const useRadius = ({
    functionType,
    map,
    handleMultiPolygons,
    deleteShape,
    updateSavedPolygon,
    deleteSavedPolygon,
    savedPolygons,
}: {
    functionType: FunctionType;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    multiFilters: FilterObject[];
    handleMultiPolygons: ({ polygon }: { polygon: InputPolygonWithId }) => void;
    options: ReqOptions;
    deleteShape: (id: string) => void;
    savedPolygons: SavedPolygonType;
} & SetSavedViewPolygonsType) => {
    const current = map.current;

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleRadius = (e: any) => {
        const feature = e?.features[0];
        const isCircle: boolean = feature.properties.isCircle || false;

        if (isCircle) {
            const id = feature.id;

            if (e.type === "draw.delete") {
                // Delete shape by keyboard
                deleteShape(id);
                deleteSavedPolygon(id, savedPolygons);
            } else {
                const polygon: InputPolygonWithId = getInputPolygon(feature);

                onRadiusChange(polygon);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (current) {
            current.on("draw.create", handleRadius);
            current.on("draw.delete", handleRadius);
            current.on("draw.update", handleRadius);
        }

        return () => {
            if (current) {
                current.off("draw.create", handleRadius);
                current.off("draw.delete", handleRadius);
                current.off("draw.update", handleRadius);
            }
        };
    }, [functionType, current]);

    const onRadiusChange = (r: InputPolygonWithId) => {
        handleMultiPolygons({ polygon: r });

        updateSavedPolygon(r, savedPolygons);
    };

    return { handleRadius };
};
