import { ApolloQueryResult } from "@apollo/client";
import {
    Exact,
    FetchUnPreviewedMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import MarketplaceDataView, {
    DatasetInfo,
} from "../../map/mapbox/views/MarketplaceDataView";

const MarketplaceDataViewContainer = ({
    onClose,
    setDataClicked,
    setOpenRightSlot,
    previewInfo,
    clickedDS,
    previewDataset,
    mpDatasetsData,
    mpDatasetsLoading,
}: {
    readonly onClose: () => void;
    readonly setDataClicked?: React.Dispatch<
        React.SetStateAction<DatasetInfo | undefined>
    >;
    readonly setOpenRightSlot: React.Dispatch<React.SetStateAction<boolean>>;
    readonly previewInfo: (data: DatasetInfo) => void;
    readonly clickedDS: string;
    readonly mpDatasetsLoading: boolean;
    readonly mpDatasetsData:
        | FetchUnPreviewedMarketplaceDatasetsQuery
        | undefined;
    readonly refetch: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchUnPreviewedMarketplaceDatasetsQuery>>;
    readonly previewDataset: (datasetId: string) => void;
}) => {
    return mpDatasetsLoading ? (
        <LoadingBar />
    ) : (
        <MarketplaceDataView
            onClose={onClose}
            setDataClicked={setDataClicked}
            setOpenRightSlot={setOpenRightSlot}
            marketplaceDatasets={
                mpDatasetsData?.fetchUnPreviewedMarketplaceDatasets
                    .marketplaceDatasets || []
            }
            previewDataset={previewDataset}
            previewInfo={previewInfo}
            clickedDS={clickedDS}
        />
    );
};

export default MarketplaceDataViewContainer;
