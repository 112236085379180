import {
    FilterObject,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as turf from "@turf/turf";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect } from "react";
import { heatmapSwatchOptions } from "../../../common/components/HeatMapColorMapper";
import { MapFilterCriteriaStyle } from "../../filter-criteria/utils/utils";

import { MapContextDataset, MapContextFilter } from "../context";
import {
    isShapeStyleLayer,
    setDatasetVisibility,
} from "../utils/data-layers-utils";
import {
    getFiltersLayers,
    setFilterVisibility,
} from "../utils/filtered-data-layers-utils";
import { CustomShapeSource } from "./style-hooks";

export type FilteredDataHookProps = {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly filters: MapContextFilter[];
    readonly multifilters: FilterObject[];
    readonly recentResponse?: SubscriptionResponse;
    readonly style?: mapboxgl.Style;
    readonly isLoaded: boolean;
    readonly isFilterCriteriaOpen: boolean;
    readonly selectedDatasets: MapContextDataset[];
};

export const useFilteredData = ({
    map,
    filters,
    recentResponse,
    style,
    isLoaded,
    isFilterCriteriaOpen,
    multifilters,
    selectedDatasets,
}: FilteredDataHookProps) => {
    const isFiltering = multifilters.some((d) => d.filters.length > 0);

    const datasetsWithFilters = pipe(
        multifilters,
        A.filter((m) => !isEmpty(m.filters)),
        A.map((m) => m.databaseId),
        A.concat(
            pipe(
                selectedDatasets,
                A.map((d) => d.dataSource.id)
            )
        ),
        uniq
    );

    const visibleFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.visible, true))
    );

    const selectedFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.selected, true))
    );

    const onStyleLoad = (map: mapboxgl.Map) => {
        // For when they're filling the filter criteria form
        map.addSource(`${CustomShapeSource.filtering}-aggregate-preview`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
        map.addSource(`${CustomShapeSource.filtering}-points-preview`, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
        });
    };

    const setFiltersFromResponse = (input: {
        suffix: string;
        response: SubscriptionResponse;
        map: mapboxgl.Map;
        sources: {
            points: string;
            aggregate: string;
        };
        currentStyles?: Partial<MapFilterCriteriaStyle>;
        addedStyles?: Partial<MapFilterCriteriaStyle>;
        isLoaded: boolean;
        hierarchy?: Partial<{
            previousFilterId: string;
            nextFilterId: string;
        }>;
    }) => {
        const {
            suffix,
            response,
            map,
            sources,
            addedStyles,
            currentStyles,
            isLoaded,
            hierarchy,
        } = input;

        const data = JSON.parse(response.data);
        const datasetId = response.databaseId;
        const dataset = selectedDatasets.find((d) =>
            isEqual(d.dataSource.id, datasetId)
        );

        const levelSets = response.geometry?.levelSets || [];

        const layers = getFiltersLayers({
            sources,
            dataset,
            addedStyles,
            currentStyles,
            suffix,
        });

        datasetsWithFilters.map((dataSourceId) =>
            setDatasetVisibility({
                map,
                prefix: dataSourceId,
                levelSets,
                visibility: "none",
                isLoaded,
                points: {
                    shape: undefined,
                    customMarker: undefined,
                },
            })
        );

        mapValues(layers, (value) => {
            if (isShapeStyleLayer(value)) {
                map.addLayer(value.square);
                map.addLayer(value.oval);

                if (value && hierarchy?.previousFilterId) {
                    map.moveLayer(
                        value.square.id.replace(
                            suffix,
                            hierarchy.previousFilterId
                        )
                    );
                    map.moveLayer(
                        value.oval.id.replace(
                            suffix,
                            hierarchy.previousFilterId
                        )
                    );
                }
            } else {
                map.addLayer(value);
                const id = value.id;

                if (id && hierarchy?.previousFilterId) {
                    map.moveLayer(
                        id.replace(suffix, hierarchy.previousFilterId)
                    );
                }
            }
        });

        const aggregateSource = map.getSource(
            sources.aggregate
        ) as GeoJSONSource;

        const pointsSource = map.getSource(sources.points) as GeoJSONSource;

        if (!isNil(data?.filtered) || !isNil(data?.filteredPoints)) {
            const filteredAvgPoints = data.filtered
                ? data.filtered.map(
                      (d: { LON: number; LAT: number; COUNT: number }) => {
                          const point = [d.LON, d.LAT];
                          return turf.point(point, {
                              count: d.COUNT,
                              databaseId: response.databaseId,
                              label: "Filtered Aggregate",
                          });
                      }
                  )
                : [];

            const filteredPoints = data.filteredPoints
                ? data.filteredPoints.map(
                      (d: { LON: number; LAT: number; COUNT: number }) => {
                          const point = [d.LON, d.LAT];
                          return turf.point(point, {
                              count: d.COUNT,
                              databaseId: response.databaseId,
                              label: "Filtered Aggregate",
                          });
                      }
                  )
                : [];

            // biome-ignore lint/suspicious/noExplicitAny: allow any
            const filteredData: any = {
                type: "FeatureCollection",
                features: [...filteredAvgPoints, ...filteredPoints],
            } as const;

            if (aggregateSource) {
                aggregateSource.setData(filteredData);
            }
        }

        if (!isNil(data?.filteredPoints)) {
            const filteredPoints = data.filteredPoints.map(
                (d: { LON: number; LAT: number; ID: number }) => {
                    const point = [d.LON, d.LAT];
                    return turf.point(point, {
                        id: d.ID,
                        databaseId: response.databaseId,
                        label: `Filtered Point ${d.ID}`,
                        count: 1,
                    });
                }
            );

            // biome-ignore lint/suspicious/noExplicitAny: allow any
            const filteredPointsGeoJson: any = {
                type: "FeatureCollection",
                features: [...filteredPoints],
            } as const;

            if (pointsSource) {
                pointsSource.setData(filteredPointsGeoJson);
            }
        }

        if (isNil(data?.filtered) && isNil(data?.filteredPoints)) {
            setDatasetVisibility({
                map,
                prefix: datasetId,
                levelSets,
                visibility: "visible",
                isLoaded,
                points: {
                    shape: dataset?.dataSource.icon
                        ? undefined
                        : dataset?.mapTemplateDataset?.shape || undefined,
                    customMarker: dataset?.dataSource.icon || undefined,
                },
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            recentResponse?.data &&
            !isEmpty(filters) &&
            map.current &&
            isLoaded &&
            !isEmpty(selectedDatasets)
        ) {
            const dataset = selectedDatasets.find(
                (i) => i.dataSource?.id === recentResponse.databaseId
            );

            // For when they create a new filter or they toggle its visibility
            for (const [index, filter] of filters.entries()) {
                const suffix = filter.id;
                const isVisible = isEqual(filter.visible, true);
                const isSelected = isEqual(filter.selected, true);

                const pointsSource = map.current.getSource(
                    `${CustomShapeSource.filtering}-points-${suffix}`
                );

                const aggregateSource = map.current.getSource(
                    `${CustomShapeSource.filtering}-aggregate-${suffix}`
                );

                if (isVisible || isSelected) {
                    if (!pointsSource) {
                        map.current.addSource(
                            `${CustomShapeSource.filtering}-points-${suffix}`,
                            {
                                type: "geojson",
                                data: {
                                    type: "FeatureCollection",
                                    features: [],
                                },
                            }
                        );
                    }

                    if (!aggregateSource) {
                        map.current.addSource(
                            `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                            {
                                type: "geojson",
                                data: {
                                    type: "FeatureCollection",
                                    features: [],
                                },
                            }
                        );
                    }

                    const isFirst = isEqual(index, 0);
                    const isLast = isEqual(index, filters.length - 1);

                    setFiltersFromResponse({
                        suffix,
                        response: recentResponse,
                        map: map.current,
                        sources: {
                            points: `${CustomShapeSource.filtering}-points-${suffix}`,
                            aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                        },
                        addedStyles: filter.styles,
                        currentStyles: filter.styles,
                        isLoaded,
                        hierarchy: {
                            previousFilterId: isFirst
                                ? undefined
                                : filters[index - 1].id,
                            nextFilterId: isLast
                                ? undefined
                                : filters[index + 1].id,
                        },
                    });
                } else {
                    setFilterVisibility({
                        visibility: "none",
                        map,
                        isLoaded,
                        suffix,
                        dataset,
                    });
                }
            }
        }
    }, [
        filters,
        map.current,
        isLoaded,
        recentResponse?.uniqueId,
        isFilterCriteriaOpen,
        multifilters,
        visibleFilters,
        selectedDatasets,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependency needed
    useEffect(() => {
        if (
            recentResponse?.data &&
            map.current &&
            isLoaded &&
            isEqual(isFilterCriteriaOpen, true) &&
            isEmpty(selectedFilters) &&
            isEqual(isFiltering, true)
        ) {
            const suffix = "preview";

            setFiltersFromResponse({
                suffix,
                response: recentResponse,
                map: map.current,
                sources: {
                    points: `${CustomShapeSource.filtering}-points-${suffix}`,
                    aggregate: `${CustomShapeSource.filtering}-aggregate-${suffix}`,
                },
                isLoaded,
                addedStyles: {
                    dataAggregation: {
                        heatmap: heatmapSwatchOptions[1],
                    },
                },
            });
        }

        if (
            isEmpty(visibleFilters) &&
            map.current &&
            isLoaded &&
            isEqual(isFiltering, false)
        ) {
            for (const filter of filters) {
                setFilterVisibility({
                    map,
                    visibility: "none",
                    isLoaded,
                    suffix: filter.id,
                });
            }
        }
    }, [
        recentResponse?.uniqueId,
        style,
        filters,
        isFilterCriteriaOpen,
        multifilters,
        visibleFilters,
        isFiltering,
        isLoaded,
        selectedFilters,
        selectedDatasets,
    ]);

    return { onStyleLoad };
};
