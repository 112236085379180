import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    Divider,
    Grid,
    HorizontalScroller,
    Stack,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { DashedChip } from "../../../common/components/DashedChip";
import { FilterCriteriaChip } from "../../../common/components/FilterCriteriaChip";
import { getLinearGradient } from "../../../common/utils/gradient";
import { MapContextFilter } from "../../mapbox/context";

interface IMapTopbar {
    readonly onAddFilter: () => void;
    readonly filters: MapContextFilter[];
    readonly clearFilters: () => void;
    readonly removeFilter: (id: string) => void;
    readonly editFilter: (id: string) => void;
    readonly viewFilterTable: (id: string, isViewed: boolean) => void;
    readonly toggleFilterVisibility: (id: string, visible: boolean) => void;
}

const MapTopbar = ({
    onAddFilter,
    filters,
    clearFilters,
    removeFilter,
    editFilter,
    viewFilterTable,
    toggleFilterVisibility,
}: IMapTopbar) => {
    const hasFilters = !isEmpty(filters);
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );

    return (
        <Grid
            container
            justifyContent="space-between"
            width="100%"
            sx={{
                padding: 2,
            }}
        >
            <Grid item xs minWidth={0}>
                <HorizontalScroller>
                    <Stack flexDirection="row" gap={2}>
                        {pipe(
                            filters,
                            A.map((filter) => (
                                <FilterCriteriaChip
                                    key={filter.id}
                                    id={filter.id}
                                    label={filter.details.name}
                                    description={filter.details.description}
                                    color={filter.styles.fill}
                                    gradient={
                                        filter.styles.dataAggregation.heatmap
                                            ? getLinearGradient(
                                                  filter.styles.dataAggregation
                                                      .heatmap
                                              )
                                            : undefined
                                    }
                                    borderColor={filter.styles.stroke}
                                    visible={filter.visible}
                                    disabled={filter.disabled}
                                    selected={filter.selected}
                                    onVisibilityClick={(id) => {
                                        toggleFilterVisibility(
                                            id,
                                            !filter.visible
                                        );
                                    }}
                                    onDeleteClick={(id) => {
                                        removeFilter(id);
                                    }}
                                    onEditClick={(id) => editFilter(id)}
                                    onViewTable={(id) =>
                                        viewFilterTable(
                                            id,
                                            filter.viewed || false
                                        )
                                    }
                                />
                            ))
                        )}
                    </Stack>
                </HorizontalScroller>
            </Grid>
            <Grid item>
                <Stack
                    alignItems="center"
                    gap={2}
                    width="100%"
                    flexWrap="nowrap"
                    flexDirection="row"
                >
                    {hasFilters && (
                        <Divider
                            orientation="vertical"
                            color={200}
                            sx={{
                                height: (theme) => theme.spacing(4),
                                marginBlockStart: isMobile
                                    ? undefined
                                    : "revert",
                                ...(isMobile && {
                                    display: "flex",
                                    alignSelf: "center",
                                }),
                            }}
                        />
                    )}
                    <Stack
                        alignItems="center"
                        gap={2}
                        flexDirection={isMobile ? "column-reverse" : "row"}
                    >
                        <Button
                            variant="ghost"
                            density="dense"
                            disabled={!hasFilters}
                            onClick={clearFilters}
                        >
                            Clear Filters
                        </Button>
                        <DashedChip text="Add Filter" onClick={onAddFilter} />
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default MapTopbar;
