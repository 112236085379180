import { AnyResponse, SchemaRow } from "@biggeo/bg-server-lib/datascape-ai";
import { IFilterSearchPaginate, Stack } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import { DataGridContainer } from "../../../components/DataGrid/DataGridContainer";
import { getSnowflakeTableColumns } from "../utils/utils";

interface IFilterCriteriaDatasetTableView {
    readonly columns: SchemaRow[];
    readonly data: AnyResponse;
    readonly title: string;
    readonly loading: boolean;
    readonly filteredDataPaginationProps: IFilterSearchPaginate;
}

const FilterCriteriaDatasetTableView = ({
    columns: c,
    data,
    title,
    loading,
    filteredDataPaginationProps,
}: IFilterCriteriaDatasetTableView) => {
    const columns = getSnowflakeTableColumns(c);

    return (
        <Stack
            width={"100%"}
            height={"100%"}
            sx={{
                border: 1,
                borderRadius: (theme) => theme.radius.xs,
                borderColor: (theme) => theme.palette.stroke[100],
            }}
        >
            <DataGridContainer
                hideFilterChips
                disableSearch
                columns={columns}
                rows={pipe(
                    data.data,
                    A.mapWithIndex((index, d) => ({ ...d, id: index }))
                )}
                rowCount={data.count}
                loading={loading}
                title={title}
                filterSearchPaginateProps={filteredDataPaginationProps}
                enableColumnResize
            />
        </Stack>
    );
};

export default FilterCriteriaDatasetTableView;
