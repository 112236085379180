import {
    MapTemplateDataset,
    ShapeStyle,
    SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Accordion,
    Divider,
    Grid,
    OverflowingTypography,
    Stack,
} from "@biggeo/bg-ui/lab";
import { ExpandContentOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import pickBy from "lodash/pickBy";
import { match } from "ts-pattern";
import { ShapeIcon } from "../../../common/components/ShapeIcon";
import {
    useResponseParseTimes,
    useSearchTimestamps,
} from "../../../common/redux/hooks";
import { SearchTime } from "../../../common/redux/model";
import { getLinearGradient } from "../../../common/utils/gradient";
import MapInterfaceLegend, {
    IconLegendProps,
} from "../../../components/MapInterfaceLegend/MapInterfaceLegend";
import {
    isAggregationNonEmpty,
    isNonPointCountPositive,
    isNonPointsLengthPositive,
    sumCounts,
} from "../../utils/utils";
import { MapContextDataset } from "../context";
import { DEFAULT_SHAPE_COLOR } from "../hooks/style-hooks";

export type DatasetResultType = {
    readonly databaseId: string;
    readonly color?: string;
    readonly label?: string;
    readonly found: number;
    readonly rendered: number;
    readonly timeTaken: IconLegendProps[];
} & MapContextDataset;

enum DatasetMetric {
    velocity = "velocity",
    latency = "latency",
    parsing = "parsing",
}

interface IMapLegendInfo {
    readonly datasets: MapContextDataset[];
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;
}

const MapLegendInfo = ({
    responses,
    map,
    datasets,
    isLoaded,
}: IMapLegendInfo) => {
    const responseParseTimes = useResponseParseTimes();
    const bgsearchTimestamps = useSearchTimestamps();

    const searchResults: SubscriptionResponse[] = Object.values(
        pickBy(responses, (_, key) =>
            datasets.map((d) => d.dataSource.id).includes(key)
        )
    ) as SubscriptionResponse[];

    const getMetricColorAndLabel = (metric: DatasetMetric) => {
        return match<DatasetMetric>(metric)
            .with(DatasetMetric.velocity, () => ({
                label: DatasetMetric.velocity,
                color: "#122159",
            }))
            .with(DatasetMetric.latency, () => ({
                label: DatasetMetric.latency,
                color: "#597BF3",
            }))
            .with(DatasetMetric.parsing, () => ({
                label: DatasetMetric.parsing,
                color: "#829CF6",
            }))
            .exhaustive();
    };

    const getDatasetFoundAndRendered = (
        databaseId: string
    ): { found: number; rendered: number } => {
        const dataset = searchResults.filter((result) =>
            isEqual(result.databaseId, databaseId)
        );

        const sumOfDatasetNonPointLengths: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.nonPoints.length
                    : acc,
            0
        );

        const sumOfDatasetPointCounts: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.pointCount
                    : acc,
            0
        );

        const sumOfDatasetNonPointCounts: number = dataset.reduce(
            (acc, currentValue: SubscriptionResponse) =>
                currentValue.geometry
                    ? acc + currentValue.geometry.nonPointCount
                    : acc,
            0
        );

        const sumOfDatasetAggregationCounts: number = dataset.reduce(
            (acc, curr) =>
                curr.geometry
                    ? acc + sumCounts(curr.geometry.aggregation)
                    : acc,
            0
        );

        const sumOfDatasetPoints = isNonPointsLengthPositive(dataset)
            ? sumOfDatasetNonPointLengths || 0
            : sumOfDatasetPointCounts || 0;

        const found =
            sumOfDatasetPoints +
            (isNonPointCountPositive(dataset)
                ? sumOfDatasetNonPointCounts
                : isAggregationNonEmpty(dataset)
                  ? sumOfDatasetAggregationCounts
                  : 0);

        const rendered = sumOfDatasetNonPointLengths
            ? sumOfDatasetNonPointLengths || 0
            : sumOfDatasetPointCounts || 0;

        return { found, rendered };
    };

    const getDatasetTimesTaken = (databaseId: string): IconLegendProps[] => {
        const dataset = searchResults.find((result) =>
            isEqual(result.databaseId, databaseId)
        );
        const velocity = dataset?.geometry?.timeMs || 0;
        const datasetBGSearchTimes: SearchTime = pipe(
            bgsearchTimestamps,
            toNonReadonlyArray,
            A.findFirst((item) => isEqual(item.datasetId, databaseId)),
            O.foldW(
                () => ({ datasetId: databaseId, startTime: 0, endTime: 0 }),
                (data) => data
            )
        );

        const roundTripTime =
            (datasetBGSearchTimes.endTime || 0) -
            (datasetBGSearchTimes.startTime || 0);

        const latency = roundTripTime - velocity;
        const datasetParseTimes = responseParseTimes.find((item) =>
            isEqual(databaseId, item.databaseId)
        );
        const parsing =
            (datasetParseTimes?.endTime || 0) -
            (datasetParseTimes?.startTime || 0);

        const metricValues = {
            velocity,
            response: roundTripTime,
            latency,
            parsing,
        };

        return pipe(
            Object.values(DatasetMetric),
            A.map((v) => ({
                ...getMetricColorAndLabel(v),
            })),
            A.map((item) => {
                return { ...item, time: metricValues[item.label] };
            })
        );
    };

    const datasetSearchResults: DatasetResultType[] = pipe(
        searchResults,
        A.map((result) => {
            const foundAndRendered = getDatasetFoundAndRendered(
                result.databaseId
            );
            const timeTaken = getDatasetTimesTaken(result.databaseId);

            const dataset = datasets.find(
                (d) => d.dataSource.id === result.databaseId
            );

            return dataset
                ? {
                      ...foundAndRendered,
                      timeTaken,
                      color:
                          dataset.mapTemplateDataset?.color ||
                          dataset.dataSource?.color ||
                          undefined,
                      label: dataset.dataSource?.label || "",
                      databaseId: result.databaseId,
                      ...dataset,
                  }
                : undefined;
        }),
        compact
    );

    return (
        <Stack
            width={56}
            sx={{
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs5,
            }}
        >
            <Accordion
                density="dense"
                label="Speed Legend"
                slots={{
                    expandedIcon: ExpandContentOutline,
                    expandIcon: ExpandContentOutline,
                }}
                slotProps={{
                    expandedIcon: {
                        sx: {
                            transition: "none",
                        },
                    },
                    expandIcon: {
                        sx: {
                            transition: "none",
                        },
                    },
                    wrapper: {
                        sx: { padding: 0 },
                    },
                }}
            >
                <Stack
                    flexDirection={"column"}
                    maxHeight={80}
                    gap={2}
                    sx={{ overflow: "auto", padding: 2 }}
                >
                    {pipe(
                        datasetSearchResults,
                        A.mapWithIndex((i, result) => {
                            const heatmap = result.mapTemplateDataset
                                ?.heatmapColor
                                ? getLinearGradient(
                                      JSON.parse(
                                          result.mapTemplateDataset
                                              ?.heatmapColor
                                      )
                                  )
                                : undefined;

                            return (
                                <Accordion
                                    key={i}
                                    density={"dense"}
                                    label={
                                        <OverflowingTypography
                                            variant={"body3"}
                                        >
                                            {result.label}
                                        </OverflowingTypography>
                                    }
                                    startNode={
                                        <ShapeIcon
                                            oval
                                            color={{
                                                color:
                                                    result.color ||
                                                    DEFAULT_SHAPE_COLOR,
                                                opacity: 0.9,
                                            }}
                                            stroke={
                                                result.mapTemplateDataset
                                                    ?.stroke
                                                    ? {
                                                          color:
                                                              result
                                                                  .mapTemplateDataset
                                                                  ?.stroke
                                                                  .color ||
                                                              DEFAULT_SHAPE_COLOR,
                                                          opacity:
                                                              result
                                                                  .mapTemplateDataset
                                                                  ?.stroke
                                                                  .opacity ||
                                                              0.9,
                                                      }
                                                    : undefined
                                            }
                                        />
                                    }
                                    sx={{
                                        gap: 2,
                                    }}
                                    slotProps={{
                                        root: {
                                            sx: {
                                                borderRadius: (theme) =>
                                                    theme.radius.default,
                                                border: (theme) =>
                                                    `1px solid ${theme.palette.stroke[100]}`,
                                                "&:before": {
                                                    content: "''",
                                                    borderTopLeftRadius:
                                                        "inherit",
                                                    borderTopRightRadius:
                                                        "inherit",
                                                    height: 2,
                                                    width: "100%",
                                                    background: heatmap
                                                        ? `${heatmap}`
                                                        : `linear-gradient(90deg, #EBEFFE 0%, ${result.color} 100%)`,
                                                },
                                            },
                                        },
                                        wrapper: {
                                            sx: {
                                                paddingLeft: 3,
                                                paddingBottom: 0,
                                            },
                                        },
                                    }}
                                >
                                    <Grid container gap={2}>
                                        <Divider orientation="vertical" />
                                        <Grid
                                            item
                                            xs
                                            minWidth={0}
                                            sx={{ paddingBottom: 2 }}
                                        >
                                            <MapInterfaceLegend
                                                map={map}
                                                result={result}
                                                isLoaded={isLoaded}
                                            />
                                        </Grid>
                                    </Grid>
                                </Accordion>
                            );
                        })
                    )}
                </Stack>
            </Accordion>
        </Stack>
    );
};

export default MapLegendInfo;
