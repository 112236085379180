import {
    InputUpdateSavedView,
    SavedView,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DescriptionTextField } from "@biggeo/bg-ui";
import {
    BreadcrumbsButton,
    BreadcrumbsGroup,
    Button,
    Image,
    InfoCell,
    Stack,
    StickyFabPlacementHelper,
    SubmittingButton,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CenteredNestedLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { Formik } from "formik";
import isEqual from "lodash/isEqual";
import { useDispatch } from "react-redux";
import Zod from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { DeleteModal } from "../../common/components/DeleteModal.tsx";
import { OnPageLeave } from "../../common/components/OnPageLeave.tsx";
import { modalActions } from "../../modal/redux/model.tsx";
import { convertDateToTimePeriod } from "../../utils/dates.ts";
import { CallBacksType } from "../../utils/types";

interface ISavedViewForm {
    readonly savedView: SavedView;
    readonly navigate: (to?: string) => void;
    readonly save: (
        input: InputUpdateSavedView,
        callbacks?: CallBacksType<SavedView>
    ) => void;
    readonly updateLoading?: boolean;
    readonly deleteLoading: boolean;
    readonly onRemove: (
        id: number,
        callbacks?: CallBacksType<SavedView>
    ) => void;
}

const ManageSavedViewFormView = ({
    savedView,
    navigate,
    save,
    updateLoading,
    onRemove,
}: ISavedViewForm) => {
    const dispatch = useDispatch();

    const initialValues = {
        id: savedView.id,
        name: savedView.name,
        description: savedView.description || undefined,
    };

    const openModal = (view: SavedView) => {
        dispatch(
            modalActions.openModal({
                modalType: "new-dialog",
                dialogProps: {
                    variant: "centered-medium",
                },
                component: (
                    <DeleteSavedViewModal
                        onRemove={(callbacks) => onRemove(view.id, callbacks)}
                        savedView={view}
                    />
                ),
            })
        );
    };

    return (
        <Formik<InputUpdateSavedView>
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                save(values, {
                    onSuccess: () => {
                        actions.setSubmitting(false);
                        actions.resetForm();
                    },
                });
            }}
            validationSchema={toFormikValidationSchema(
                Zod.object({
                    id: Zod.number(),
                    name: Zod.string(),
                    description: Zod.string().optional(),
                })
            )}
            validateOnMount
            enableReinitialize
        >
            {({
                values,
                setValues,
                handleSubmit,
                dirty,
                isValid,
                isSubmitting,
            }) => {
                const onChange = (i: Partial<InputUpdateSavedView>) => {
                    setValues((p) => ({ ...p, ...i }));
                };

                return (
                    <OnPageLeave
                        trigger={!isEqual(values, initialValues)}
                        save={({ navigate }) =>
                            save(values, { onSuccess: () => navigate() })
                        }
                    >
                        <CenteredNestedLayoutWithHeader
                            title={
                                <BreadcrumbsGroup value={"Manage Saved View"}>
                                    <BreadcrumbsButton
                                        value="map-templates"
                                        onClick={() => navigate()}
                                    >
                                        Map templates
                                    </BreadcrumbsButton>
                                    <BreadcrumbsButton
                                        value={"Manage Saved View"}
                                        onClick={() => navigate()}
                                        hideSeparator
                                    >
                                        Manage Saved View
                                    </BreadcrumbsButton>
                                </BreadcrumbsGroup>
                            }
                        >
                            <Stack gap={4}>
                                <Stack gap={0.5}>
                                    <Typography variant="h6" fontWeight="bold">
                                        Manage Saved View
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.disabled
                                                    .onContainer,
                                        }}
                                    >
                                        Edit saved view details
                                    </Typography>
                                </Stack>
                                <TextField
                                    fullWidth
                                    required
                                    name="name"
                                    label="Saved view name"
                                    value={values.name || ""}
                                    placeholder="Enter name"
                                    onChange={(_, v) =>
                                        onChange({
                                            name: v === "" ? undefined : v,
                                        })
                                    }
                                    disabled={isSubmitting}
                                />
                                <DescriptionTextField
                                    value={values.description || undefined}
                                    onChange={(_, v) =>
                                        onChange({
                                            description:
                                                v === "" ? undefined : v,
                                        })
                                    }
                                    disabled={isSubmitting}
                                />
                                <StickyFabPlacementHelper
                                    placement="right"
                                    sx={{
                                        paddingY: 4,
                                        backgroundColor: (theme) =>
                                            theme.palette.background.container,
                                    }}
                                >
                                    <Stack
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                        gap={4}
                                    >
                                        <Stack flexDirection="row" gap={4}>
                                            <Button
                                                type="submit"
                                                variant={"outlined"}
                                                color={"error"}
                                                disabled={isSubmitting}
                                                onClick={() =>
                                                    openModal(savedView)
                                                }
                                            >
                                                Remove
                                            </Button>
                                            <SubmittingButton
                                                type="saving"
                                                loading={updateLoading}
                                                disabled={!isValid || !dirty}
                                                onClick={() => handleSubmit()}
                                            >
                                                Save Changes
                                            </SubmittingButton>
                                        </Stack>
                                    </Stack>
                                </StickyFabPlacementHelper>
                            </Stack>
                        </CenteredNestedLayoutWithHeader>
                    </OnPageLeave>
                );
            }}
        </Formik>
    );
};

const DeleteSavedViewModal = ({
    savedView,
    onRemove,
}: {
    savedView: SavedView;
    onRemove: (callbacks?: CallBacksType<SavedView>) => void;
}) => {
    return (
        <DeleteModal
            subTitle={
                "This saved view will be removed permanently from your organization"
            }
            body={
                <InfoCell
                    startNode={
                        <Image
                            src={savedView.image || ""}
                            alt={savedView.name}
                            height="100%"
                            width={100}
                            sx={{ objectFit: "cover", layout: "fill" }}
                        />
                    }
                    title={savedView.name}
                    subtitle={`Last update: ${convertDateToTimePeriod(savedView.updatedAt)}`}
                    density={"none"}
                    slotProps={{
                        title: {
                            fontWeight: "bold",
                            variant: "body2",
                        },
                        subtitle: {
                            variant: "body4",
                            sx: {
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            },
                        },
                    }}
                    sx={{ padding: 2 }}
                    variant="outlined"
                />
            }
            description={savedView.description || ""}
            onRemove={onRemove}
        />
    );
};

export default ManageSavedViewFormView;
