import { Grid, Tab, TabGroup } from "@biggeo/bg-ui/lab";
import { useNavigate } from "react-router";
import { Routes } from "../navigation/redux/model";

interface IMarketplaceMapHeader {
    readonly currentTab: "mapView" | "listView";
}
export const MarketplaceMapHeader = ({ currentTab }: IMarketplaceMapHeader) => {
    const toPage = useNavigate();

    return (
        <Grid
            container
            sx={{
                paddingY: 4,
                paddingX: 2,
                borderBottom: 1,
                borderColor: (theme) => theme.palette.stroke[100],
            }}
        >
            <TabGroup variant="tonal" value={currentTab}>
                <Tab
                    value="mapView"
                    onClick={() => toPage(`${Routes.marketplace}/mapView`)}
                >
                    Map View
                </Tab>
                <Tab
                    value="listView"
                    onClick={() => toPage(`${Routes.listView}/datasets`)}
                >
                    List View
                </Tab>
            </TabGroup>
        </Grid>
    );
};
