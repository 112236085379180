import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { FunctionType, SavedPolygonSource } from "../../utils/utils";

import mapboxgl from "mapbox-gl";
import { match } from "ts-pattern";

import { Routes } from "../../../navigation/redux/model.ts";
import {
    MAP_TOOLTIP_HEIGHT,
    MAP_TOOLTIP_WIDTH,
    MapPopupType,
} from "../views/MapPromptPopup.tsx";
import {
    OUTSIDE_AREA_LAYER_ID,
    SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID,
    SavedPolygonType,
} from "./saved-polygon-hooks.ts";

export type MapStylePromptType = "Shape" | "Editing shape";

export type MapPromptType =
    | "Select starting point"
    | "Select to add point"
    | "Double-click to create shape or click to add point"
    | "Press enter or double-click to create shape"
    | "Press enter or click to create shape"
    | "You can't create shape outside of your enabled area boundaries"
    | "Enable this area to view available data"
    | undefined;

export type OnMapClickType = { x: number; y: number; counter: number };

export const usePopup = ({
    map,
    draw,
    isDrawMode,
    functionType,
    isLoaded,
    savedPolygons,
    isSelectMode,
    actions,
}: {
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly draw: React.MutableRefObject<MapboxDraw | null>;
    readonly isDrawMode: boolean;
    readonly functionType: FunctionType;
    readonly isLoaded: boolean;
    readonly savedPolygons: SavedPolygonType;
    readonly isSelectMode: boolean;
    readonly actions: Record<"isResizing" | "isDragging", boolean>;
}): { isDoneDrawing: boolean } => {
    const current = map.current;
    const dispatch = useDispatch();
    const onClickInitialState: OnMapClickType = {
        x: 0,
        y: 0,
        counter: 0,
    };
    const [onClick, setOnClick] = useState<OnMapClickType>(onClickInitialState);

    const isSavedAreaMode =
        !isEmpty(savedPolygons.polygons) &&
        isEqual(savedPolygons.source, SavedPolygonSource.savedArea);

    const isInitialState = isEqual(onClick, onClickInitialState);

    const currentShape =
        draw.current && isLoaded ? draw.current.getSelected() : undefined;
    const isDoneDrawing = !!currentShape && !isEmpty(currentShape.features);

    const hide = () =>
        dispatch(
            toasterActions.openMapPopup({
                tooltipArrow: false,
                sx: {
                    display: "none",
                },
            })
        );

    const show = (
        input: { left: number; top: number; prompt: MapPromptType } & Pick<
            MapPopupType,
            "tooltipArrow" | "button" | "sx"
        >
    ) => {
        const { left, top, prompt, tooltipArrow = false, button, sx } = input;

        dispatch(
            toasterActions.openMapPopup({
                message: prompt,
                sx: {
                    display: "block",
                    left: `${left}px`,
                    top: `${top}px`,
                    height: (theme) =>
                        tooltipArrow
                            ? theme.spacing(21)
                            : theme.spacing(MAP_TOOLTIP_HEIGHT / 4),
                    width: tooltipArrow
                        ? (theme) => theme.spacing(MAP_TOOLTIP_WIDTH / 4)
                        : undefined,
                    ...sx,
                },
                tooltipArrow,
                button,
            })
        );
    };
    const isCursorOnMap = (
        x: number,
        y: number,
        bounds: { left: number; right: number; top: number; bottom: number }
    ): boolean => {
        const { left, right, top, bottom } = bounds;

        // If x is between left and right, and y between bottom and top, the cursor is on the map.
        return x > left && x < right && y > top && y < bottom;
    };

    const getMapBounds = (map: mapboxgl.Map) => {
        const canvas = map.getCanvas();

        // The cursor event is for the whole screen.
        // We need to deduct the left and top of the map
        // container to get the cursor's position on the map.
        const mapBounds = canvas.getBoundingClientRect();

        const right = mapBounds ? mapBounds.right : 0;
        const left = mapBounds ? mapBounds.left : 0;
        const top = mapBounds ? mapBounds.top : 0;
        const bottom = mapBounds ? mapBounds.bottom : 0;

        return { right, left, top, bottom };
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (isLoaded) {
            if (isDoneDrawing) {
                setOnClick(onClickInitialState);
            } else {
                hide();
            }

            // Handle cases where shape was created with `Enter`
            // or deleted with `Escape`.
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Escape" || event.key === "Enter") {
                    setOnClick(onClickInitialState);
                }
            };

            if (isDrawMode && !isDoneDrawing) {
                document.addEventListener("keydown", handleKeyDown);
            }

            return () => {
                if (isDrawMode && !isDoneDrawing) {
                    document.removeEventListener("keydown", handleKeyDown);
                }
            };
        }
    }, [isDoneDrawing, draw.current, currentShape, isLoaded, isDrawMode]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            current &&
            isEqual(isDrawMode, true) &&
            isEqual(isSelectMode, false)
        ) {
            // When you click "Enter" on the map to create a shape, it shows a border around it.
            // This code is to remove that border.
            const canvas = current.getCanvas();
            canvas.style.outline = "none";

            const { right, left, top, bottom } = getMapBounds(current);

            const onMouseEnter = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const canvas = current.getCanvas();
                canvas.style.cursor = "crosshair";

                const popup = document.getElementById("map-popup");
                const popupBounds = popup?.getBoundingClientRect();
                const width = popupBounds ? popupBounds.width : 0;
                const middle = width / 2;

                const x = event.originalEvent.clientX - left - middle;
                const y = event.originalEvent.clientY - top + 20;

                show({
                    left: x,
                    top: y,
                    prompt: "Select starting point",
                    button: undefined,
                });

                const hasMovedAfterClick = x !== onClick.x || y !== onClick.y;

                if (!isInitialState && !hasMovedAfterClick) {
                    hide();
                }

                if (!isInitialState && hasMovedAfterClick) {
                    const prompt = match<FunctionType, MapPromptType>(
                        functionType
                    )
                        .with(FunctionType.polygon, () => {
                            const isThirdVertex = onClick.counter === 2;

                            if (isThirdVertex) {
                                return "Double-click to create shape or click to add point";
                            }

                            return onClick.counter >= 3
                                ? "Press enter or double-click to create shape"
                                : "Select to add point";
                        })
                        .otherwise(
                            () => "Press enter or click to create shape"
                        );

                    show({ left: x, top: y, prompt, button: undefined });
                    return;
                }

                if (isDrawMode || isDoneDrawing) {
                    show({
                        left: x,
                        top: y,
                        prompt: "Select starting point",
                        button: undefined,
                    });
                }
            };

            const onMouseLeave = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                e.preventDefault();

                const canvas = current.getCanvas();
                canvas.style.cursor = "";

                const isOnMap = isCursorOnMap(
                    e.originalEvent.clientX,
                    e.originalEvent.clientY,
                    { left, right, top, bottom }
                );

                const elementCursorIsOn = document.elementFromPoint(
                    e.originalEvent.clientX,
                    e.originalEvent.clientY
                );

                const elementsOnTopOfMap = pipe(
                    elementCursorIsOn,
                    O.fromNullable,
                    O.fold(
                        () => [],
                        (element) =>
                            Array.prototype.filter.call(
                                element.children,
                                (child) => child.className
                            )
                    )
                );

                if (isEqual(isOnMap, false) || !isEmpty(elementsOnTopOfMap)) {
                    hide();
                }
            };

            const onMapClick = (
                e: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const features = map.current?.queryRenderedFeatures(e.point, {
                    layers: [OUTSIDE_AREA_LAYER_ID],
                });

                const isOnOutsideArea = !isEmpty(features);

                e.preventDefault();

                if (isEqual(isOnOutsideArea, false)) {
                    const x = e.originalEvent.clientX - left;
                    const y = e.originalEvent.clientY - top;

                    setOnClick({
                        x,
                        y,
                        counter: onClick.counter + 1,
                    });

                    hide();
                }
            };

            current.on("mousemove", onMouseEnter);
            current.on("mouseout", onMouseLeave);
            current.on("click", onMapClick);

            return () => {
                if (current) {
                    current.off("mousemove", onMouseEnter);
                    current.off("mouseout", onMouseLeave);
                    current.off("click", onMapClick);
                }
            };
        }
    }, [current, isDrawMode, onClick, functionType, isSelectMode]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (current && isEqual(isSavedAreaMode, true)) {
            const { left, top } = getMapBounds(current);

            const onMouseMove = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                const popup = document.getElementById("map-popup");
                const popupBounds = popup?.getBoundingClientRect();
                const width = popupBounds ? popupBounds.width : 0;
                const middle = width / 2;

                const x = event.originalEvent.clientX - left - middle;
                const y = event.originalEvent.clientY - top - 110;

                const isDrawing = onClick.counter > 0;

                // Check if cursor is close to a saved area
                // and the user is not drawing.
                const isCloseToSavedArea =
                    !isEmpty(
                        map.current?.queryRenderedFeatures(event.point, {
                            layers: [SAVED_AREAS_ADDITIONAL_OUTLINE_LAYER_ID],
                        })
                    ) && !isDrawMode;

                if (
                    !isDrawing &&
                    !actions.isResizing &&
                    !actions.isDragging &&
                    !isCloseToSavedArea
                ) {
                    current.getCanvas().style.cursor = "not-allowed";

                    const prompt = isDrawMode
                        ? "You can't create shape outside of your enabled area boundaries"
                        : "Enable this area to view available data";

                    const button = isDrawMode
                        ? undefined
                        : { children: "Learn More", to: Routes.savedAreas };

                    show({
                        left: x,
                        top: isDrawMode ? y : y - 15,
                        prompt,
                        button,
                        tooltipArrow: true,
                        sx: isDrawMode
                            ? undefined
                            : { height: (theme) => theme.spacing(25) },
                    });
                }
            };

            const onMouseLeave = (
                event: mapboxgl.MapMouseEvent & {
                    features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
                } & mapboxgl.EventData
            ) => {
                event.preventDefault();

                const canvas = current.getCanvas();
                canvas.style.cursor = "";

                hide();
            };

            current.on("mousemove", OUTSIDE_AREA_LAYER_ID, onMouseMove);
            current.on("mouseleave", OUTSIDE_AREA_LAYER_ID, onMouseLeave);

            return () => {
                if (current) {
                    current.off(
                        "mousemove",
                        OUTSIDE_AREA_LAYER_ID,
                        onMouseMove
                    );
                    current.off(
                        "mouseleave",
                        OUTSIDE_AREA_LAYER_ID,
                        onMouseLeave
                    );
                }
            };
        }
    }, [
        current,
        draw.current,
        onClick,
        functionType,
        isSavedAreaMode,
        actions,
        savedPolygons,
        isSelectMode,
        isDrawMode,
    ]);

    return { isDoneDrawing };
};
