import {
    InputDataSource,
    useFetchDataSourcesLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { equals, match } from "@vividtheory/remotedata";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDataGridOptions } from "../../common/redux/hooks";
import { RootState } from "../../redux/reducer";
import { databaseMetaDataActions } from "./model";
import { useDataSourcesQuery } from "./requests";

export const usePreviewDatasets = () => {
    const dispatch = useDispatch();

    const { executeQuery } = useFetchDataSourcesLazyQuery();
    const previewRemoteData = useSelector(
        (state: RootState) => state.dataSources.previewDatasets,
        equals
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        match(previewRemoteData, {
            Initialized: () => {
                executeQuery({
                    variables: { input: { isPreview: true } },
                    onCompleted: (d) => {
                        dispatch(
                            databaseMetaDataActions.fetchPreviewDataSources.success(
                                d.fetchDataSources
                            )
                        );
                    },
                    onError: (e) => {
                        dispatch(
                            databaseMetaDataActions.fetchPreviewDataSources.failure(
                                e
                            )
                        );
                    },
                });
            },

            _: () => {
                return null;
            },
        });
    }, []);

    return previewRemoteData;
};

export const useDataSources = (i?: InputDataSource) => {
    const dispatch = useDispatch();
    const { dataGridFetchInputProps } = useDataGridOptions();
    const input: InputDataSource = i
        ? {
              ...i,
              dataGridFetchInput: i.dataGridFetchInput
                  ? i.dataGridFetchInput
                  : dataGridFetchInputProps,
          }
        : {
              dataGridFetchInput: dataGridFetchInputProps,
          };

    const execute = useDataSourcesQuery(input);

    const dataSourcesRemoteData = useSelector(
        (state: RootState) => state.dataSources.dataSourcesRemoteData,
        equals
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        match(dataSourcesRemoteData, {
            Initialized: () => {
                execute({ variables: { input } }).then(({ data }) => {
                    if (data) {
                        dispatch(
                            databaseMetaDataActions.fetchDataSources.success(
                                data.fetchDataSources
                            )
                        );
                    } else {
                        dispatch(
                            databaseMetaDataActions.fetchDataSources.failure(
                                "Something went wrong"
                            )
                        );
                    }
                });
            },

            _: () => {
                return null;
            },
        });
    }, [
        input.dataGridFetchInput?.searchText,
        input.isConnected,
        input.dataGridFetchInput?.filterObject,
    ]);

    return dataSourcesRemoteData;
};

export const useDatabaseMetaDataArray = (input?: InputDataSource) => {
    const rd = useDataSources(input);

    return match(rd, {
        _: () => [],
        Success: (d) => d.dataSources,
    });
};
