import { ShapeStyle } from "@biggeo/bg-server-lib/datascape-ai";
import { ColorPicker, Stack, colorToHexString } from "@biggeo/bg-ui/lab";
import { AccordionWithDivider } from "../../common/components/AccordionWithDivider";
import { HeatMapClusterColorMapper } from "../../common/components/HeatMapClusterColorMapper";
import FilterCriteriaStylesShape from "../../map/filter-criteria/views/FilterCriteriaStylesShape";
import { DEFAULT_SHAPE_COLOR } from "../../map/mapbox/hooks/style-hooks";
import { getHeatMapValue } from "../../map/mapbox/utils/heatmap";
import DatasetCustomMarker from "../../map/mapbox/views/DatasetCustomMarker";
import { IDatasetMenu } from "../../map/views/DatasetMenu";

type DatasetCardMapItemProps = Omit<IDatasetMenu, "onBack">;

export const DatasetCardMapItem = ({
    dataset,
    heatMapValue,
    updateHeatMapColor,
    extraOptions,
    updateShape,
    updateColor,
    updateCustomMarker,
}: DatasetCardMapItemProps) => {
    return (
        <Stack gap={2} width="100%">
            <AccordionWithDivider label="Shape">
                <FilterCriteriaStylesShape
                    currentShape={
                        dataset.mapTemplateDataset?.shape || ShapeStyle.oval
                    }
                    currentCustomMarker={dataset.dataSource.icon || undefined}
                    color={{
                        color:
                            dataset.mapTemplateDataset?.color ||
                            dataset.dataSource.color ||
                            DEFAULT_SHAPE_COLOR,
                        opacity: dataset.dataSource.opacity || 0.9,
                    }}
                    stroke={{
                        color:
                            dataset.mapTemplateDataset?.stroke?.color ||
                            dataset.mapTemplateDataset?.color ||
                            dataset.dataSource.color ||
                            DEFAULT_SHAPE_COLOR,
                        opacity:
                            dataset.mapTemplateDataset?.stroke?.opacity || 0.9,
                    }}
                    onChange={(shape) => {
                        updateShape({
                            shape,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Fill">
                <ColorPicker
                    initialColor={
                        dataset.mapTemplateDataset?.color ||
                        dataset.dataSource.color ||
                        DEFAULT_SHAPE_COLOR
                    }
                    color={
                        dataset.mapTemplateDataset?.color ||
                        dataset.dataSource.color ||
                        DEFAULT_SHAPE_COLOR
                    }
                    onChange={(color) => {
                        updateColor({
                            type: "fill",
                            color: colorToHexString(color.rgb),
                            opacity: color.rgb.a,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Stroke">
                <ColorPicker
                    initialColor={
                        dataset.mapTemplateDataset?.stroke?.color ||
                        dataset.mapTemplateDataset?.color ||
                        dataset.dataSource.color ||
                        DEFAULT_SHAPE_COLOR
                    }
                    color={
                        dataset.mapTemplateDataset?.stroke?.color ||
                        dataset.mapTemplateDataset?.color ||
                        dataset.dataSource.color ||
                        DEFAULT_SHAPE_COLOR
                    }
                    onChange={(color) => {
                        updateColor({
                            type: "stroke",
                            color: colorToHexString(color.rgb),
                            opacity: color.rgb.a,
                            dataset,
                        });
                    }}
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Custom Marker">
                <DatasetCustomMarker
                    value={dataset.dataSource.icon || undefined}
                    onChange={(v) => {
                        if (v) {
                            updateCustomMarker(v, dataset);
                        }
                    }}
                    color={
                        dataset.mapTemplateDataset?.color ||
                        dataset.dataSource.color ||
                        DEFAULT_SHAPE_COLOR
                    }
                />
            </AccordionWithDivider>
            <AccordionWithDivider label="Data Aggregation">
                <HeatMapClusterColorMapper
                    heatMapValue={getHeatMapValue(
                        heatMapValue,
                        dataset.dataSource.id
                    )}
                    onClickHeatMapValue={(value) =>
                        updateHeatMapColor(value, dataset)
                    }
                    extraOptions={extraOptions}
                />
            </AccordionWithDivider>
        </Stack>
    );
};
