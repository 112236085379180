import {
    InputUpdateSavedArea,
    SlimSavedArea,
    useFetchSavedAreasByAreaQuery,
    useUpdateSavedAreaMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    CellItem,
    ColFilterType,
    Grid,
    IconButton,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    CropFreeOutline,
    Info,
    OpenInNewOutline,
} from "@biggeo/bg-ui/lab/icons";
import * as turf from "@turf/turf";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import concat from "lodash/concat";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import lowerCase from "lodash/lowerCase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAppRunningOnSF, useDataGridOptions } from "../../common/redux/hooks";
import { DataGridContainer } from "../../components/DataGrid/DataGridContainer";
import { Routes } from "../../navigation/redux/model";
import { convertSqmToSkm } from "../../utils/utils";
import MapUseView from "../Views/MapUseView";
interface ISavedAreasTableContainer {
    areaId: number;
    mapTemplateId?: number;
    selectedAreaName?: string;
}

const SavedAreasTableContainer = ({
    areaId,
    mapTemplateId,
    selectedAreaName,
}: Readonly<ISavedAreasTableContainer>) => {
    const isRunningOnSF = isAppRunningOnSF();
    const toPage = useNavigate();

    const { dataGridFetchInputProps, filterSearchPaginateProps } =
        useDataGridOptions();

    const {
        queryReturn: { loading: savedAreasLoading, refetch, data },
    } = useFetchSavedAreasByAreaQuery({
        variables: {
            input: {
                areaId,
                ...(isEqual(lowerCase(selectedAreaName), "saved areas") && {
                    fkMapTemplateId: mapTemplateId,
                }),
                dataGridFetchInput: dataGridFetchInputProps,
                isSavedViewArea: false,
            },
        },
    });

    const {
        executeRemoteMutation,
        mutationReturn: [_, { loading: _updateAreaLoading }],
    } = useUpdateSavedAreaMutation();

    const _toggleShowOnMap = async (
        value: boolean,
        savedArea: SlimSavedArea
    ) => {
        setSelectedRows((prev) =>
            prev.filter((rowId) => !isEqual(rowId, savedArea.id))
        );
        await executeRemoteMutation({
            variables: {
                input: {
                    id: savedArea.id,
                    isEnabled: value,
                    name: savedArea.name,
                },
            },
            onCompleted: () => {
                refetch();
            },
        });
    };
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const toggleMapUse = async (
        value: boolean,
        savedArea: InputUpdateSavedArea
    ) => {
        setSelectedRows((prev) => [...prev, savedArea.id]);
        await executeRemoteMutation({
            variables: {
                input: {
                    id: savedArea.id,
                    mapUse: value,
                },
            },
            onCompleted: ({ updateSavedArea }) => {
                const savedAreaId = updateSavedArea.id;
                refetch().then(() => {
                    setSelectedRows((prev) =>
                        prev.filter((rowId) => !isEqual(rowId, savedAreaId))
                    );
                });
            },
        });
    };
    const cols: ReadonlyArray<
        GridColDef<{
            id: number;
            name: string;
            totalArea: string;
            isEnabled: boolean;
            mapUse: boolean;
            areaId: number;
        }>
    > = [
        {
            field: "src",
            headerName: "Src",
            flex: 1,
            width: 200,
            minWidth: 75,
            sortable: false,
            renderCell: () => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={8}
                    sx={{
                        padding: 2,
                    }}
                >
                    <IconButton variant="tonal" density="dense" color="primary">
                        <CropFreeOutline size="sm" />
                    </IconButton>
                </Grid>
            ),
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            minWidth: 200,
            sortable: false,
            type: ColFilterType.string,
            filterable: true,
        },
        {
            field: "area",
            renderHeader: () => (
                <CellItem
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                    title="Area"
                />
            ),
            minWidth: 175,
            sortable: false,
            type: ColFilterType.number,
            renderCell: (params) => (
                <Typography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{ padding: 4 }}
                >
                    {`${params.row.totalArea} km`}
                    <sup>2</sup>
                </Typography>
            ),
        },
        {
            field: "mapUse",
            renderHeader: () => (
                <CellItem
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                    endNode={<Info color={"primary"} />}
                    title="Map use"
                />
            ),
            minWidth: 175,
            sortable: false,
            type: ColFilterType.boolean,
            renderCell: (params) => (
                <MapUseView
                    savedAreaId={params.row.id}
                    mapUse={params.row.mapUse}
                    selectedRows={selectedRows}
                    toggleMapUse={toggleMapUse}
                />
            ),
        },
        {
            field: "",
            minWidth: 175,
            sortable: false,
            filterable: false,
            renderHeader: () => (
                <CellItem
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                    endNode={<Info color={"primary"} />}
                    title="View"
                />
            ),
            renderCell: (params) => (
                <Grid container justifyContent="center">
                    <IconButton
                        variant="filled"
                        density="dense"
                        disabled={
                            includes(selectedRows, params.row.id) ||
                            !params.row.mapUse
                        }
                        sx={{ flexShrink: 0 }}
                        onClick={() => {
                            toPage(
                                `${Routes.mapView}/${mapTemplateId}/map?mapTab=areas&savedAreaId=${params.row.id}`
                            );
                        }}
                    >
                        <Typography variant={"button"} fontWeight={"semibold"}>
                            Open
                        </Typography>
                        <OpenInNewOutline size="sm" />
                    </IconButton>
                </Grid>
            ),
        },
    ];

    const manageColData: GridColDef = {
        field: "actions",
        minWidth: 125,
        sortable: false,
        filterable: false,
        headerName: "",
        type: "actions",
        renderCell: (params) => (
            <Grid
                container
                justifyContent="center"
                sx={{ paddingLeft: 4, paddingRight: 4 }}
            >
                <IconButton
                    variant="outlined"
                    density="comfortable"
                    disabled={
                        includes(selectedRows, params.row.id) ||
                        !params.row.isEnabled
                    }
                    sx={{ flexShrink: 0 }}
                    onClick={() => {
                        toPage(
                            `${Routes.mapView}/${mapTemplateId}/configuration/${params.row.id}`
                        );
                    }}
                >
                    <Typography variant={"button"} fontWeight={"semibold"}>
                        Manage
                    </Typography>
                </IconButton>
            </Grid>
        ),
    };

    return (
        <DataGridContainer
            columnVisibilityModel={{
                id: false,
                showOnMap: isRunningOnSF,
            }}
            disableColumnResize
            loading={savedAreasLoading}
            columns={
                isEqual(lowerCase(selectedAreaName), "saved areas")
                    ? concat(cols, manageColData)
                    : cols
            }
            initialState={{ pinnedColumns: { right: ["actions"] } }}
            rows={pipe(
                data?.fetchSavedAreasByArea.savedAreas,
                O.fromNullable,
                O.fold(
                    () => [],
                    A.map((poly) => ({
                        id: poly.id,
                        name: poly.name,
                        totalArea: pipe(
                            poly.geometries,
                            A.reduce(0, (acc, val) =>
                                pipe(
                                    val,
                                    turf.area,
                                    convertSqmToSkm,
                                    (sq) => acc + sq
                                )
                            ),
                            (totalArea) => totalArea.toFixed(4)
                        ),
                        isEnabled: poly.isEnabled,
                        areaId: poly.areaId,
                        mapUse: poly.mapUse,
                    }))
                )
            )}
            rowCount={data?.fetchSavedAreasByArea.total ?? 0}
            filterSearchPaginateProps={filterSearchPaginateProps}
            title={selectedAreaName || ""}
        />
    );
};

export default SavedAreasTableContainer;
