import {
    DataSource,
    MapTemplateDatasetExtended,
    MapTemplateExtended,
    useCreateMapTemplateDatasetsMutation,
    useFetchDataSourcesQuery,
    useFetchMapTemplateExtendedQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BasicDataWithSelect,
    Button,
    LoadingBar,
    Menu,
} from "@biggeo/bg-ui/lab";
import { AddOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray, updateSelected } from "@biggeo/bg-utils";
import { match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ErrorPage } from "../../common/components/ErrorPage";
import AddDatasetWithSubMenu from "../../components/AddDatasetWithSubMenu/AddDatasetWithSubMenu";

export const AddDatasetSubMenuContainer = ({
    existingDatasets,
    mapTemplateId,
    setRemote,
    localDataList,
    setLocalDataList,
}: {
    readonly existingDatasets: readonly DataSource[];
    readonly mapTemplateId: number;
    readonly setRemote: (v: MapTemplateDatasetExtended[]) => void;
    readonly localDataList: string[];
    readonly setLocalDataList: (v: string[]) => void;
}) => {
    const { remote } = useFetchDataSourcesQuery({
        variables: {
            input: {},
        },
    });

    const isInExistingDatasets = (dataset: DataSource) => {
        return existingDatasets.some((existing) => existing.id === dataset.id);
    };

    const toPage = useNavigate();

    const [map, setMap] = useState<MapTemplateExtended>();
    const [open, setOpen] = useState(false);

    useFetchMapTemplateExtendedQuery({
        variables: {
            id: mapTemplateId,
        },
        onCompleted: (data) => {
            setMap(data.fetchMapTemplateExtended);
        },
    });

    const {
        executeMutation: createMapTemplateDatasets,
        mutationReturn: [_, { loading }],
    } = useCreateMapTemplateDatasetsMutation();
    const save = (localDataList: string[]) => {
        const newDataSourceIds = pipe(
            map?.datasets.map((d) => d.fkDataSourceId),
            O.fromNullable,
            O.fold(
                () => localDataList,
                (fkDataSourceId) => {
                    return fkDataSourceId.concat(localDataList);
                }
            )
        );

        createMapTemplateDatasets({
            variables: {
                input: {
                    fkDataSourceId: newDataSourceIds,
                    fkMapTemplateId: mapTemplateId,
                    enable: true,
                    mapUse: true,
                },
            },
            onCompleted: (data) => {
                setLocalDataList([]);
                setOpen(false);
                setRemote(data.createMapTemplateDatasets.mapTemplateDatasets);
            },
        });
    };

    return (
        <Menu
            placement="bottom-start"
            sx={{
                maxWidth: 72,
                width: "100%",
                gap: 0,
                padding: 0,
            }}
            open={open}
            onOpenChange={setOpen}
            content={match(remote, {
                _: () => <LoadingBar />,
                Success: (data) => {
                    const filteredExistingDataset: BasicDataWithSelect[] = pipe(
                        data.fetchDataSources.dataSources,
                        A.filter((dataset) => !isInExistingDatasets(dataset)),
                        A.filter((dataset) => dataset.isConnected),
                        A.map((item) => ({
                            id: item.id,
                            image: item.src || "",
                            title: item.label || item.tableName,
                            select: includes(localDataList, item.id),
                        }))
                    );

                    const handleOnCheck = (
                        selectedData: BasicDataWithSelect
                    ) => {
                        setLocalDataList(
                            updateSelected(
                                selectedData.id,
                                pipe(localDataList, toNonReadonlyArray)
                            )
                        );
                    };

                    return (
                        <AddDatasetWithSubMenu
                            filteredExistingDataset={filteredExistingDataset}
                            localDataList={localDataList}
                            handleOnCheck={handleOnCheck}
                            loading={loading}
                            save={save}
                            toPage={toPage}
                        />
                    );
                },
                Failure: (err) => <ErrorPage subtitle={err.message} />,
            })}
        >
            <Button
                variant="outlined"
                density="dense"
                startNode={<AddOutline size="xs" />}
            >
                Add Dataset
            </Button>
        </Menu>
    );
};
