import {
    type FilterObject,
    type FilterObjectType,
    type InputPolygon,
    type InputViewBox,
    MapStyleType,
    type Point,
    type ReqOptions,
    type SavedArea,
    type SubscriptionResponse,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui";

import { toNonReadonlyArray } from "@biggeo/bg-utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import uniqWith from "lodash/uniqWith";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import type { DatasetPointShape } from "../../../components/DatapointShape/types";
import { Routes } from "../../../navigation/redux/model";
import type { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import {
    DirectSelectModeOverride,
    DrawCircleMode,
    DrawPolygonModeOverride,
    DrawSquareMode,
    SimpleSelectModeOverride,
} from "../../utils/draw-modes";
import {
    FunctionType,
    LastFunctionType,
    SavedPolygonSource,
    coordinatesGeocoder,
    getVisiblePartOfPolygons,
    setSavedViewPolygons,
} from "../../utils/utils";
import { DEFAULT_MAP_STYLE, mapStyles } from "../../views/MapStyles";
import { onMapReset, useMap, useMapPreviousState } from "../context";
import { useConflictAreas } from "./conflict-areas-hooks";
import { useData } from "./data-hooks";
import { useFilteredData } from "./filtered-data-hooks";
import { usePolygon } from "./polygon-hooks";
import { usePopup } from "./popup-hooks";
import { useRadius } from "./radius-hooks";
import { SavedPolygonType, useSavedPolygon } from "./saved-polygon-hooks";
import { useSelectMode } from "./select-hooks";
import { useSquare } from "./square-hooks";
import { useShapeStyle } from "./style-hooks";
import { useViewport } from "./viewport-hooks";

export const startingLatLng: Point = { latitude: 51, longitude: -114 };

export type InitializeMapProps = {
    readonly functionType: FunctionType;
    readonly setFunctionType: React.Dispatch<
        React.SetStateAction<FunctionType>
    >;
    readonly multiFilters: FilterObject[];
    readonly setMultiFilters: (f: FilterObject[]) => void;
    readonly addMultipleDatasets: (v: readonly FilterObjectType[]) => void;
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly viewport: InputViewBox;
    readonly handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    readonly showTriangles: boolean;
    readonly showPoints: boolean;
    readonly showFiltered: boolean;
    readonly savedPolygons: SavedPolygonType;
    readonly options: ReqOptions;
    readonly polygons: InputPolygonWithId[];
    readonly handleMultiPolygons: ({
        polygon,
    }: {
        polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    readonly deleteShape: (id: string) => void;
    readonly handleSavedPolygons: (p: InputPolygonWithId[]) => void;
    readonly handlePolygonsOnZoom: (p: InputPolygon[]) => void;
    readonly handleSelectedSavedPolygons: (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
        isConflict?: boolean;
    }) => void;
    readonly selectableItems: SelectableTreeMenuItem[];
    readonly handleSelectedShapes: (
        i:
            | GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >
            | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    ) => void;
    readonly isFilterCriteriaOpen: boolean;
    readonly setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
};

export type InitializeMapReturnType = {
    isLoaded: boolean;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    draw: React.MutableRefObject<MapboxDraw | null>;
    changeProjection: (proj: "globe" | "mercator") => void;
    projection: "globe" | "mercator";
    zoomIn: () => void;
    zoomOut: () => void;
    selectMode: (mode: boolean) => void;
    isSelectMode: boolean;
    showAiPrompt: boolean;
    setShowAiPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawMode: boolean;
};

export const useInitializeMap = ({
    functionType,
    multiFilters,
    recentResponse,
    handleViewportChange,
    viewport,
    showTriangles,
    showPoints,
    showFiltered,
    savedPolygons,
    options,
    deleteShape,
    handleSavedPolygons,
    handleMultiPolygons,
    polygons,
    setFunctionType,
    handlePolygonsOnZoom,
    setMultiFilters,
    addMultipleDatasets,
    handleSelectedSavedPolygons,
    selectableItems,
    handleSelectedShapes,
    isFilterCriteriaOpen,
    setSavedPolygons,
}: InitializeMapProps): InitializeMapReturnType => {
    const {
        map,
        draw,
        isLoaded,
        dispatch,
        previousMapState,
        filters,
        mapStyle,
        datasets,
    } = useMap();

    const style = isLoaded && map.current ? map.current.getStyle() : undefined;
    const geocode = useRef<MapboxGeocoder | null>(null);

    const [showAiPrompt, setShowAiPrompt] = useState(false);
    const [projection, setProjection] = useState<"globe" | "mercator">(
        "mercator"
    );

    const location = useLocation();
    const isSavedViewPage = location.search.includes(Routes.savedView);

    const { deleteSavedPolygon, updateSavedPolygon } = setSavedViewPolygons({
        setSavedPolygons,
    });

    const isDrawMode =
        isEqual(functionType, FunctionType.polygon) ||
        isEqual(functionType, FunctionType.radius) ||
        isEqual(functionType, FunctionType.square);

    const { onShapeStyleLoad, styles, loadCustomShapeSources } = useShapeStyle({
        map,
        isLoaded,
    });

    const { select, selectMode } = useSelectMode({
        handleSelectedShapes,
        draw,
        map,
        onShapeStyleLoad,
        isLoaded,
        isDrawMode,
    });

    const {
        onStyleLoad: onStyleLoadViewport,
        bounds,
        viewport: updatedViewport,
    } = useViewport({
        functionType,
        map,
        multiFilters,
        viewport,
        handleViewportChange,
        options,
    });

    const { onStyleLoad: onStyleLoadSavedPolygon } = useSavedPolygon({
        map,
        savedPolygons,
        handleSavedPolygons,
        draw,
        isLoaded,
    });

    const { onConflictAreaStyleLoad, actions } = useConflictAreas({
        map,
        savedPolygons,
        draw,
        isLoaded,
        isSelectMode: select,
        handleSelectedShapes,
        deleteShape,
        mapDispatch: dispatch,

        handleSavedAreas: ({ unselect }) => {
            if (isEqual(unselect, true)) {
                handleSelectedSavedPolygons({
                    isConflict: false,
                    savedAreas: [],
                    selectableItems: pipe(
                        selectableItems,
                        A.map((item) => ({
                            ...item,
                            selected: false,
                            subItems: item.subItems
                                ? pipe(
                                      item.subItems,
                                      toNonReadonlyArray,
                                      A.map((subItem) => ({
                                          ...subItem,
                                          selected: false,
                                      }))
                                  )
                                : item.subItems,
                        }))
                    ),
                });
            } else {
                handleSelectedSavedPolygons({
                    isConflict: false,
                    savedAreas: toNonReadonlyArray(savedPolygons.polygons),
                    selectableItems,
                });
            }
        },
    });

    usePopup({
        map,
        draw,
        isDrawMode,
        functionType,
        isLoaded,
        savedPolygons,
        isSelectMode: select,
        actions,
    });

    useRadius({
        functionType,
        map,
        multiFilters,
        handleMultiPolygons,
        options,
        deleteShape,
        updateSavedPolygon,
        deleteSavedPolygon,
        savedPolygons,
    });

    usePolygon({
        functionType,
        map,
        multiFilters,
        handleMultiPolygons,
        options,
        deleteShape,
        updateSavedPolygon,
        deleteSavedPolygon,
        savedPolygons,
    });

    useSquare({
        functionType,
        map,
        multiFilters,
        handleMultiPolygons,
        options,
        deleteShape,
        updateSavedPolygon,
        deleteSavedPolygon,
        savedPolygons,
    });

    const { onStyleLoad: onStyleLoadData } = useData({
        map,
        multifilters: multiFilters,
        recentResponse,
        showTriangles,
        showPoints,
        showFiltered,
        style,
        isLoaded,
        filters,
    });

    const { onStyleLoad: onStyleLoadFilteredData } = useFilteredData({
        map,
        multifilters: multiFilters,
        recentResponse,
        style,
        isLoaded,
        filters,
        selectedDatasets: pipe(
            datasets,
            A.filter((d) => isEqual(d.isSelected, true))
        ),
        isFilterCriteriaOpen,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const initMapStyle = isSavedViewPage ? mapStyle : MapStyleType.light;

        if (!initMapStyle) return;

        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: "map",
                style: initMapStyle ? mapStyles(initMapStyle) : undefined,
                doubleClickZoom: false,
                center: [startingLatLng.longitude, startingLatLng.latitude],
                zoom: 5,
                projection: projection,
                refreshExpiredTiles: false,
                maxTileCacheSize: 10000000,
                renderWorldCopies: false,
                accessToken:
                    "pk.eyJ1IjoiY2NhcnRoIiwiYSI6ImNsb3E1NTlweDBkbHMyaW9iaXRrNTZ1ZmIifQ.J95n-vaI-pyGcO8Hd082xQ",
                preserveDrawingBuffer: true,
            });

            if (draw.current) {
                const hasDrawControl = map.current.hasControl(draw.current);

                if (!hasDrawControl) {
                    map.current.addControl(draw.current);
                }
            } else {
                draw.current = new MapboxDraw({
                    displayControlsDefault: false,
                    keybindings: true,
                    userProperties: true,
                    modes: {
                        ...MapboxDraw.modes,
                        draw_polygon: DrawPolygonModeOverride,
                        draw_square: DrawSquareMode,
                        draw_circle: DrawCircleMode,
                        direct_select: DirectSelectModeOverride,
                        simple_select: SimpleSelectModeOverride,
                    },
                    controls: {
                        trash: true,
                    },
                    styles,
                });

                map.current.addControl(draw.current);
            }

            geocode.current = new MapboxGeocoder({
                accessToken:
                    "pk.eyJ1IjoiY2NhcnRoIiwiYSI6ImNsb3E1NTlweDBkbHMyaW9iaXRrNTZ1ZmIifQ.J95n-vaI-pyGcO8Hd082xQ",
                mapboxgl: mapboxgl,
                localGeocoder: coordinatesGeocoder,
                zoom: 4,
                placeholder: "Search name or coordinates",
                reverseGeocode: true,
            });

            const geocoderElement = document.getElementById("geocoder");
            geocoderElement?.appendChild(geocode.current.onAdd(map.current));

            const isConfigSavedAreas =
                includes(location.search, "savedAreaId") &&
                includes(location.search, "mapTab");

            if (previousMapState && !isConfigSavedAreas) {
                onMapReset({
                    map: map.current,
                    draw: draw.current,
                    state: previousMapState,
                    polygons,
                    setState: {
                        setPolygons: (p) =>
                            handleSavedPolygons(
                                uniqBy(pipe(polygons, A.concat(p)), "id")
                            ),
                        setFunctionType: (f) => setFunctionType(f),
                        setMode: (m) => selectMode(m),
                        setMultiFilters: (f) => {
                            setMultiFilters(f);
                            addMultipleDatasets(
                                pipe(
                                    f,
                                    A.map((c) => ({
                                        databaseId: c.databaseId,
                                        collection: c.collection,
                                        databaseType: c.databaseType,
                                        filters: c.filters,
                                        logicOperator: c.logicOperator,
                                    })),
                                    compact
                                )
                            );
                        },
                        setViewport: (v) => {
                            if (v.view) {
                                handleViewportChange({ viewport: v.view });
                            }

                            if (v.center && map.current) {
                                map.current.setCenter(v.center);
                            }
                        },
                        setSavedAreas: (a) => {
                            handleSelectedSavedPolygons(a);
                        },
                        setStyle: (s) => map.current?.setStyle(s),
                        setFilters: (f) => {
                            dispatch?.({ type: "SET_FILTERS", values: f });
                        },
                    },
                });
            }

            map.current.on("style.load", () => {
                dispatch?.({
                    type: "SET_MAP",
                    values: {
                        isLoaded: true,
                        draw,
                        map,
                    },
                });

                if (map.current) {
                    loadCustomShapeSources(map.current);

                    onStyleLoadData(map.current);
                    onStyleLoadFilteredData(map.current);
                    onStyleLoadViewport();
                    onStyleLoadSavedPolygon(map.current);
                    onConflictAreaStyleLoad(map.current);

                    // remove mapbox default control container
                    const container = map.current.getContainer();
                    const controlContainer = container.querySelector(
                        ".mapboxgl-control-container"
                    );
                    if (controlContainer) controlContainer.remove();
                }
            });
        }

        return () => {
            if (map.current) {
                if (draw.current) {
                    // Cleanup the context when you leave the map page.
                    map.current.removeControl(draw.current);

                    const hasDrawControl = map.current.hasControl(draw.current);

                    if (!hasDrawControl) {
                        dispatch?.({
                            type: "SET_MAP",
                            values: {
                                draw: { current: null },
                            },
                        });
                    }
                }

                map.current.remove();

                dispatch?.({
                    type: "SET_MAP",
                    values: {
                        map: { current: null },
                        isLoaded: false,
                        mapStates: [],
                    },
                });
            }
        };
    }, [map, draw, mapStyle]);

    useMapPreviousState({
        isLoaded,
        state: {
            map,
            draw,
            datasets,
            modes: {
                draw: isDrawMode,
                select,
            },
            featureCollection: previousMapState?.featureCollection,
            functionType,
            multiFilters,
            filters,
            selectedSavedAreas: {
                savedAreas: isEqual(
                    savedPolygons.source,
                    SavedPolygonSource.savedArea
                )
                    ? uniqWith(savedPolygons.polygons, isEqual)
                    : [],
                selectableItems,
                isConflict: savedPolygons.isConflict || false,
            },
            viewport: {
                bounds,
                center: bounds ? bounds.getCenter() : undefined,
                view: updatedViewport,
            },
            styleUrl: style?.sprite ?? DEFAULT_MAP_STYLE.sprite,
        },
        dispatch,
        callbacks: {
            deleteShape,
        },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isEmpty(polygons)) {
            handleMultiPolygons({
                polygon: polygons.map((p) => ({
                    id: p.id,
                    inners: p.inners,
                    outer: p.outer,
                })),
            });
        }
    }, [functionType, multiFilters]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isSavedViewPage) {
            const current = map.current;

            const handleMove = () => {
                if (!isEmpty(polygons) && current) {
                    const intersections = getVisiblePartOfPolygons(
                        polygons,
                        current
                    );

                    if (!isEmpty(intersections)) {
                        handlePolygonsOnZoom(intersections);
                    }
                }
            };

            if (current) {
                current.on("moveend", handleMove);
            }

            return () => {
                if (current) {
                    current.off("moveend", handleMove);
                }
            };
        }
    }, [map.current, polygons, functionType, isSavedViewPage]);

    const changeProjection = (proj: "globe" | "mercator") => {
        if (map.current) {
            map.current.setProjection(proj);
            setProjection(proj);
        }
    };

    const zoomIn = () => {
        if (map.current) map.current?.zoomIn();
    };

    const zoomOut = () => {
        if (map.current) map.current?.zoomOut();
    };

    return {
        isLoaded,
        map,
        draw,
        changeProjection,
        projection,
        zoomIn,
        zoomOut,
        selectMode,
        isSelectMode: select,
        showAiPrompt,
        setShowAiPrompt,
        isDrawMode,
    };
};
