import { SelectableTreeMenu, SelectableTreeMenuItem } from "@biggeo/bg-ui";
import { LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";

import { pipe } from "fp-ts/lib/function";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useEffect, useState } from "react";
import { handleFilterSelect } from "../utils/utils";

export const SavedAreasSubmenu = ({
    filterItems,
    selectSavedArea,
}: {
    readonly filterItems: readonly SelectableTreeMenuItem[];
    readonly selectSavedArea: (
        items: readonly SelectableTreeMenuItem[]
    ) => void;
}) => {
    const [localFilterItems, setLocalFilterItems] = useState<
        readonly SelectableTreeMenuItem[]
    >([]);

    useEffect(() => setLocalFilterItems(filterItems), [filterItems]);

    return isEmpty(localFilterItems) ? (
        <LoadingBar />
    ) : (
        <Stack width="100%">
            {pipe(
                localFilterItems,
                toNonReadonlyArray,
                A.map((item) => {
                    if (!item.subItems) return [];

                    const title =
                        typeof item.title === "string" &&
                        isEqual(
                            item.title.toLowerCase(),
                            "Saved Areas".toLowerCase()
                        )
                            ? startCase(item.title.toLowerCase())
                            : item.title;

                    return (
                        <SelectableTreeMenu
                            showDivider
                            key={item.id}
                            menuItem={{
                                ...item,
                                title,
                            }}
                            onClick={(id) => {
                                const filters = handleFilterSelect(
                                    id,
                                    filterItems
                                );
                                selectSavedArea(filters);
                            }}
                        />
                    );
                })
            )}
        </Stack>
    );
};
