import {
    Divider,
    Grid,
    HorizontalScroller,
    IconButton,
    InfoCell,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    Circle,
    VisibilityOffOutline,
    VisibilityOutline,
} from "@biggeo/bg-ui/lab/icons";
import { useState } from "react";
import { setDatasetVisibility } from "../../map/mapbox/utils/data-layers-utils";
import { DatasetResultType } from "../../map/mapbox/views/MapLegendInfo";

export type IconLegendProps = {
    time: number;
    color: string;
    label: string;
};

const MapInterfaceLegend = ({
    map,
    isLoaded,
    result,
}: {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    result: DatasetResultType;
}) => {
    const found = result.found;
    const rendered = result.rendered;
    const values = result.timeTaken;
    const allPointsColor = result.color;

    const totalMs = values.reduce((acc, data) => acc + data.time, 0);

    const [pointsVisible, setPointsVisible] = useState<boolean>(true);

    const handleVisibility = (value: boolean) => {
        setPointsVisible(value);

        const current = map.current;

        if (current && isLoaded) {
            const visibility = value ? "visible" : "none";

            setDatasetVisibility({
                map: current,
                prefix: result.dataSource.id,
                levelSets: [],
                visibility,
                isLoaded,
                points: {
                    shape: result.dataSource.icon
                        ? undefined
                        : result.mapTemplateDataset?.shape || undefined,
                    customMarker: result.dataSource.icon || undefined,
                },
            });
        }
    };

    return (
        <Stack gap={2} width="100%">
            <Stack>
                <Typography variant="body4" fontWeight="semibold">
                    Speed Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    See below a breakdown of last query
                </Typography>
            </Stack>

            <Stack gap={2} width="100%">
                <Grid container flexWrap="nowrap">
                    {values.map((data) => {
                        const calculatedWidth = (
                            (data.time / totalMs) *
                            100
                        ).toFixed(2);
                        return (
                            <Grid
                                key={`${data.time}-${data.color}`}
                                item
                                width={`${calculatedWidth}%`}
                                height={1}
                                sx={{
                                    backgroundColor: data.color,
                                }}
                            />
                        );
                    })}
                </Grid>

                <HorizontalScroller
                    slotProps={{
                        scrollContainer: {
                            gap: 1,
                            justifyContent: "space-between",
                        },
                        leftButton: { density: "none" },
                        rightButton: { density: "none" },
                    }}
                >
                    {values.map((data) => {
                        return (
                            <Grid
                                container
                                key={`${data.time}-${data.color}`}
                                gap={1}
                                flexWrap="nowrap"
                            >
                                <Stack gap={1}>
                                    <Grid
                                        container
                                        gap={1}
                                        alignItems="center"
                                        flexWrap="nowrap"
                                    >
                                        <Circle
                                            size="xxs"
                                            sx={{
                                                color: `${data.color}`,
                                            }}
                                        />

                                        <Typography
                                            variant="body4"
                                            fontWeight="regular"
                                        >
                                            {data.time > 0 ? data.time : 0}ms
                                        </Typography>
                                    </Grid>
                                    <Typography
                                        variant="body4"
                                        fontWeight="regular"
                                        textTransform="capitalize"
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.disabled
                                                    .onContainer,
                                        }}
                                    >
                                        {data.label}
                                    </Typography>
                                </Stack>
                            </Grid>
                        );
                    })}
                </HorizontalScroller>
            </Stack>
            <Stack>
                <Grid
                    container
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                >
                    <Typography variant="body4" fontWeight="regular">
                        Found
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {found.toLocaleString()}
                    </Typography>
                </Grid>
                <Grid
                    container
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="nowrap"
                >
                    <Typography variant="body4" fontWeight="regular" truncate>
                        Rendered
                    </Typography>

                    <Typography variant="body3" fontWeight="bold">
                        {rendered.toLocaleString()}
                    </Typography>
                </Grid>
            </Stack>

            <Divider />

            <Stack>
                <Typography variant="body4" fontWeight="semibold">
                    Dataset Legend
                </Typography>
                <Typography
                    variant="body4"
                    fontWeight="regular"
                    sx={{
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                >
                    Dataset points breakdown
                </Typography>
            </Stack>

            <InfoCell
                density="none"
                title="All Points"
                startNode={<Circle size="xxs" sx={{ color: allPointsColor }} />}
                endNode={
                    <IconButton
                        variant="minimal"
                        onClick={() => {
                            handleVisibility(!pointsVisible);
                        }}
                    >
                        {pointsVisible ? (
                            <VisibilityOutline size="xs" />
                        ) : (
                            <VisibilityOffOutline size="xs" />
                        )}
                    </IconButton>
                }
                slotProps={{
                    title: {
                        fontWeight: "regular",
                    },
                }}
            />
        </Stack>
    );
};

export default MapInterfaceLegend;
