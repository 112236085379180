import {
    Box,
    Button,
    IconAvatar,
    OverflowingTypography,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { ReactNode } from "react";

type ResourceCardProps = {
    readonly logo: ReactNode;
    readonly title: string;
    readonly description: string;
    readonly onClick: (id: string) => void;
    readonly cta?: ReactNode;
};

export const ResourceCard = ({
    onClick,
    logo,
    title,
    description,
    cta,
}: ResourceCardProps) => {
    return (
        <Stack
            gap={4}
            sx={{
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
                padding: 4,
                justifyContent: "space-between",
            }}
        >
            <IconAvatar square>{logo}</IconAvatar>
            <Stack>
                <Typography variant={"body3"} fontWeight={"semibold"}>
                    {title}
                </Typography>
                <OverflowingTypography
                    sx={{
                        minHeight: (theme) => theme.spacing(9),
                        color: (theme) => theme.palette.disabled.onContainer,
                    }}
                    variant={"body3"}
                    lineClamp={2}
                >
                    {description}
                </OverflowingTypography>
            </Stack>

            <Box
                sx={{
                    display: "flex",
                    cursor: "pointer",
                    justifyContent: "flex-end",
                }}
            >
                {cta ? (
                    cta
                ) : (
                    <Button
                        density={"dense"}
                        variant={"outlined"}
                        onClick={() => onClick}
                    >
                        View
                    </Button>
                )}
            </Box>
        </Stack>
    );
};
