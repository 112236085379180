import { Box, useTheme } from "@biggeo/bg-ui/lab";
import { convertToHex8String } from "../../utils/color";

export type ShapeIconProps = {
    readonly oval?: boolean;
    readonly color?: {
        color: string;
        opacity: number;
    };
    readonly stroke?: {
        color: string;
        opacity: number;
    };
};

export const ShapeIcon = ({ oval = false, color, stroke }: ShapeIconProps) => {
    const theme = useTheme();
    const defaultOpacity = 0.9;
    const defaultColor = theme.palette.primary.main;

    return (
        <Box
            sx={{
                borderRadius: oval ? (theme) => theme.radius.full : undefined,
                height: 2,
                width: 2,
                backgroundColor: convertToHex8String(
                    color ? color.color : defaultColor,
                    color ? color.opacity : defaultOpacity
                ),
                border: stroke ? 0.5 : undefined,
                borderColor: stroke
                    ? convertToHex8String(stroke.color, stroke.opacity)
                    : undefined,
            }}
        />
    );
};
