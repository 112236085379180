import {
    DataSource,
    FetchDataSourceQuery,
    FetchMapTemplateDatasetByDatasourceIdQuery,
    FetchMapTemplatesQuery,
    InputRefreshData,
    useFetchDataSourceQuery,
    useFetchMapTemplateDatasetByDatasourceIdQuery,
    useFetchMapTemplatesQuery,
    useRefreshDataSetMutation,
    useRemoveDataSetMutation,
    useUpdateDataSourceMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    BreadcrumbsButton,
    BreadcrumbsGroup,
    CellItem,
    LoadingBar,
    Severity,
    Stack,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { SplitLayoutWithHeader } from "@biggeo/bg-ui/lab/layouts";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { map3, match as vtRdMatch } from "@vividtheory/remotedata";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { compose } from "redux";
import { DeleteModal } from "../../common/components/DeleteModal";
import { ErrorPage } from "../../common/components/ErrorPage";
import { DataManagementTab } from "../../data/containers/DataManagementContainer";
import { modalActions } from "../../modal/redux/model";
import { Routes } from "../../navigation/redux/model";
import { useOpenToast } from "../../toaster/containers/redux/hooks";
import { CallBacksType } from "../../utils/types";
import { DatasourceInfo, ManageDatasetInfo } from "../views/ManageDatasetInfo";
import { ManageDatasetMappedColumns } from "../views/ManageDatasetMappedColumns";

export const ManageDatasetContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const openModal = compose(dispatch, modalActions.openModal);
    const toPage = useNavigate();
    const openToast = useOpenToast();

    const [skip, setSkip] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);

    const { remote: datasourceRd } = useFetchDataSourceQuery({
        variables: {
            id: id || "",
        },
        skip,
    });

    const { remote: mapTemplatesRd } = useFetchMapTemplatesQuery({
        variables: { input: {} },
        skip,
    });

    const { remote: mapTemplateDatasetsRd } =
        useFetchMapTemplateDatasetByDatasourceIdQuery({
            variables: {
                id: id || "",
            },
            skip,
        });

    const { executeMutation: removeDatasetMutation } =
        useRemoveDataSetMutation();

    const { executeMutation: updateDatasourceMutation } =
        useUpdateDataSourceMutation();

    const { executeMutation: refreshDatasetMutation } =
        useRefreshDataSetMutation();

    const mappedData = map3(
        (datasourceRd: FetchDataSourceQuery) =>
            (mapTemplatesRd: FetchMapTemplatesQuery) =>
            (
                mapTemplateDatasetsRd: FetchMapTemplateDatasetByDatasourceIdQuery
            ) => ({
                fetchDataSource: datasourceRd.fetchDataSource,
                fetchMapTemplates: mapTemplatesRd.fetchMapTemplates,
                fetchMapTemplateDatasetByDatasourceId:
                    mapTemplateDatasetsRd.fetchMapTemplateDatasetByDatasourceId,
            }),
        datasourceRd,
        mapTemplatesRd,
        mapTemplateDatasetsRd
    );

    const location = useLocation();

    const returnUrl = location.state?.returnUrl;
    const truncatedUrl = returnUrl ? new URL(returnUrl).pathname : Routes.data;

    const handleUpdateDatasource = (
        input: DatasourceInfo,
        callbacks?: CallBacksType<DataSource>
    ) => {
        setSaveLoading(true);
        updateDatasourceMutation({
            variables: {
                input: {
                    id: input.id,
                    collectionName: input.originalName,
                    label: input.displayName,
                    description: input.description,
                    mapTemplateIds: pipe(
                        input.mapTemplateIds,
                        toNonReadonlyArray,
                        A.map(Number)
                    ),
                },
            },
            onCompleted: (data) => {
                openToast({
                    title: "Data updated successfully",
                    severity: Severity.success,
                });
                callbacks?.onSuccess?.(data.updateDataSource);

                toPage(truncatedUrl);
            },
            onError: () => {
                setSaveLoading(false);
            },
        });
    };

    const handleOpenModal = ({
        collectionName,
        description,
        image,
    }: {
        readonly collectionName: string;
        readonly image?: string;
        readonly description?: string;
    }) => {
        openModal({
            modalType: "new-dialog",
            component: (
                <DeleteModal
                    title="Are you sure?"
                    subTitle="This dataset will be removed permanently from your
                        organization"
                    description={description}
                    body={
                        <CellItem
                            density="none"
                            disableHoverEffect
                            startNode={
                                <ThumbnailAvatar
                                    src={image}
                                    alt={collectionName}
                                    square
                                />
                            }
                            title={
                                <Typography variant="body2" fontWeight="bold">
                                    {collectionName}
                                </Typography>
                            }
                        />
                    }
                    onRemove={(callbacks) => {
                        setRemoveLoading(true);
                        removeDatasetMutation({
                            variables: {
                                collectionName,
                            },
                            onCompleted: (data) => {
                                openToast({
                                    title: "Data deleted successfully",
                                    severity: Severity.success,
                                });
                                toPage(Routes.data);
                                callbacks?.onSuccess?.(data);
                            },
                        });
                    }}
                />
            ),
        });
    };

    const handleOnRefreshDataset = (input: InputRefreshData) => {
        refreshDatasetMutation({
            variables: {
                input,
            },
        });
        toPage(`${Routes.data}/${DataManagementTab.processing}`);
    };

    useEffect(() => {
        if (id) {
            setSkip(false);
        }
        return () => setSkip(true);
    }, [id]);

    return vtRdMatch(mappedData, {
        _: () => <LoadingBar />,
        Success: ({
            fetchDataSource,
            fetchMapTemplates,
            fetchMapTemplateDatasetByDatasourceId,
        }) => {
            return (
                <Stack width="100%" height="100%">
                    <SplitLayoutWithHeader
                        gap={8}
                        header={
                            <BreadcrumbsGroup value="manage-dataset">
                                <BreadcrumbsButton
                                    value="data"
                                    onClick={() => toPage(-1)}
                                >
                                    Data
                                </BreadcrumbsButton>
                                <BreadcrumbsButton
                                    value="manage-dataset"
                                    hideSeparator
                                >
                                    Manage dataset
                                </BreadcrumbsButton>
                            </BreadcrumbsGroup>
                        }
                        left={
                            <Box
                                height="100%"
                                width="100%"
                                sx={{
                                    breakpoints: {
                                        cmd: { padding: 4 },
                                    },
                                }}
                            >
                                <ManageDatasetInfo
                                    initialState={{
                                        id: fetchDataSource.id,
                                        originalName:
                                            fetchDataSource.collectionName,
                                        displayName:
                                            fetchDataSource.label || "",
                                        description:
                                            fetchDataSource.description ||
                                            undefined,
                                    }}
                                    onRemove={() => {
                                        handleOpenModal({
                                            collectionName:
                                                fetchDataSource.collectionName,
                                            description:
                                                fetchDataSource.description ||
                                                undefined,
                                            image: "https://biggeo.blob.core.windows.net/media/sf.png",
                                        });
                                    }}
                                    onSubmit={handleUpdateDatasource}
                                    mapTemplates={
                                        fetchMapTemplates.mapTemplates
                                    }
                                    mapTemplateDatasets={
                                        fetchMapTemplateDatasetByDatasourceId
                                    }
                                    saveLoading={saveLoading}
                                    removeLoading={removeLoading}
                                />
                            </Box>
                        }
                        right={
                            <ManageDatasetMappedColumns
                                geospatialColumns={
                                    fetchDataSource.geographyColumn || ""
                                }
                                tableId={fetchDataSource.tableId || ""}
                                onLearnMore={() => {
                                    // TODO: Redirect to learn more
                                }}
                                onRefresh={() =>
                                    handleOnRefreshDataset({
                                        id: fetchDataSource.id,
                                        collectionName:
                                            fetchDataSource.collectionName,
                                        tableId: fetchDataSource.tableId || "",
                                        tableIdType: "", //TODO: NOT SURE
                                    })
                                }
                            />
                        }
                    />
                </Stack>
            );
        },
        Failure: (err) => <ErrorPage subtitle={err.message} />,
    });
};
