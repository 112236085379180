import {
    Button,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";

import { WithLoading } from "@biggeo/bg-ui";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { modalActions } from "../../modal/redux/model";
import { CallBacksType } from "../../utils/types";

export const DeleteModal = <T,>({
    title = "Are you Sure?",
    subTitle,
    body,
    description,
    onRemove,
}: {
    title?: string;
    subTitle: string;
    body: React.ReactNode;
    description?: string;
    onRemove: (callbacks?: CallBacksType<T>) => void;
}) => {
    const dispatch = useDispatch();
    const closeModal = compose(dispatch, modalActions.closeModal);

    const [loading, setLoading] = useState(false);

    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea
                sx={{ flexDirection: "column", padding: 4 }}
                gap={4}
            >
                <Stack>
                    {title && (
                        <Typography variant="h6" fontWeight="bold">
                            {title}
                        </Typography>
                    )}
                    <Typography
                        variant="body2"
                        sx={{
                            color: (theme) =>
                                theme.palette.disabled.onContainer,
                        }}
                    >
                        {subTitle}
                    </Typography>
                </Stack>
                {body}
                {description && (
                    <Stack gap={1}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            }}
                        >
                            Description
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: (theme) =>
                                    theme.palette.background.onMain,
                            }}
                        >
                            {description}
                        </Typography>
                    </Stack>
                )}
            </FlexScrollArea>
            <Stack sx={{ padding: 4 }} gap={4}>
                <WithLoading loading={loading} text="Removing...">
                    <Button
                        fullWidth
                        color="error"
                        onClick={() => {
                            setLoading(true);
                            onRemove({
                                onSuccess: () => closeModal(),
                            });
                        }}
                    >
                        Yes, Remove
                    </Button>
                </WithLoading>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => closeModal()}
                    disabled={loading}
                >
                    Nevermind
                </Button>
            </Stack>
        </FlexScrollAreaContainer>
    );
};
