import { MarketplaceDataset } from "@biggeo/bg-server-lib/datascape-ai";
import { EmptyState } from "@biggeo/bg-ui";
import { Grid, IconButton, Stack, Typography } from "@biggeo/bg-ui/lab";
import { DatabaseOutline, ExpandMoreOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { LinearGradientWithId } from "../../../marketplace/views/ExploreDatasetsView";
import MarketplaceDatasetPreviewCard from "../../../marketplace/views/MarketplaceDatasetPreviewCard";

const breakpoints = {
    cxs: 12,
    csm: 6,
    cmd: 6,
    clg: 4,
    cxl: 3,
};

export type DatasetInfo = {
    readonly id: string;
    readonly size: number;
    readonly price: number;
    readonly name: string;
    readonly image: string;
    readonly progress: number;
    readonly processing: boolean;
    readonly isPreview?: boolean;
    readonly gradient: LinearGradientWithId[];
    readonly preview: (datasetId: number) => void;
};

interface IMarketplaceDataView {
    readonly onClose: () => void;
    readonly setDataClicked?: React.Dispatch<
        React.SetStateAction<DatasetInfo | undefined>
    >;
    readonly setOpenRightSlot: React.Dispatch<React.SetStateAction<boolean>>;
    readonly marketplaceDatasets: MarketplaceDataset[];
    readonly previewDataset: (datasetId: string) => void;
    readonly clickedDS: string;
    readonly previewInfo: (data: DatasetInfo) => void;
}

const MarketplaceDataView = ({
    onClose,
    setOpenRightSlot,
    marketplaceDatasets,
    previewDataset,
    clickedDS,
    previewInfo,
}: IMarketplaceDataView) => {
    // TODO: uncomment when onClick is known
    // const filters = [
    //     "Name",
    //     "Source",
    //     "Size",
    //     "Price",
    //     "Industry",
    //     "More Filters",
    // ];
    return (
        <Stack height={"100%"}>
            <Grid
                container
                gap={2}
                sx={{
                    padding: 2,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <Grid item xs minWidth={0} alignSelf="center">
                    <Typography
                        variant="title3"
                        fontWeight="bold"
                        color="background"
                        colorSet="main"
                        invertColors
                        truncate
                    >
                        Data Marketplace
                    </Typography>
                </Grid>
                <IconButton
                    variant="ghost"
                    onClick={onClose}
                    sx={{ padding: 2, borderRadius: "default" }}
                >
                    <ExpandMoreOutline size="xs" />
                </IconButton>
            </Grid>
            <Stack
                gap={4}
                flexDirection="column"
                sx={{
                    padding: 3,
                    overflow: "auto",
                    containerType: "inline-size",
                }}
            >
                {pipe(
                    marketplaceDatasets,
                    O.fromPredicate((x) => !A.isEmpty(x)),
                    O.fold(
                        () => (
                            <EmptyState
                                icon={<DatabaseOutline />}
                                title={"No Datasets Enabled"}
                                subtitle={
                                    "Datasets available to purchase from data providers will appear here."
                                }
                                border={false}
                            />
                        ),
                        (datasets) => (
                            <>
                                {/* // TODO: uncomment when onClick is known */}
                                {/* <Grid container gap={2}>
                                    {pipe(
                                        filters,
                                        A.map((filter) => {
                                            return (
                                                <Grid item key={`${filter}`}>
                                                    <Chip
                                                        startNode={
                                                            <AddCircleOutline size="xs" />
                                                        }
                                                        density="dense"
                                                        sx={{
                                                            borderRadius: (
                                                                theme
                                                            ) =>
                                                                theme.radius
                                                                    .full,
                                                            border: "1px dashed",
                                                            borderColor: (
                                                                theme
                                                            ) =>
                                                                theme.palette
                                                                    .stroke[200],
                                                            ":hover": {
                                                                border: "none",
                                                            },
                                                            [`&.${chipClasses.outlined}`]:
                                                                {
                                                                    boxShadow:
                                                                        "none",
                                                                },
                                                        }}
                                                    >
                                                        {filter}
                                                    </Chip>
                                                </Grid>
                                            );
                                        })
                                    )}
                                </Grid> */}
                                <Grid container spacing={4}>
                                    {datasets.map((dataset) => {
                                        return (
                                            <Grid
                                                item
                                                key={dataset.id}
                                                onClick={() => {
                                                    setOpenRightSlot(true);
                                                }}
                                                {...breakpoints}
                                            >
                                                <MarketplaceDatasetPreviewCard
                                                    price={dataset.price}
                                                    name={dataset.name}
                                                    preview={() =>
                                                        previewDataset(
                                                            dataset.id
                                                        )
                                                    }
                                                    previewInfo={previewInfo}
                                                    datasetId={dataset.id}
                                                    image={dataset.img || ""}
                                                    size={dataset.size}
                                                    isSelected={isEqual(
                                                        clickedDS,
                                                        dataset.id
                                                    )}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        )
                    )
                )}
            </Stack>
        </Stack>
    );
};

export default MarketplaceDataView;
