import {
    DataSource,
    MapTemplate,
    MapTemplateDataset,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DescriptionTextField } from "@biggeo/bg-ui";
import {
    MultiSelectorAccordion,
    Stack,
    StickyFabPlacementHelper,
    SubmittingButton,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import { Formik } from "formik";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { OnPageLeave } from "../../common/components/OnPageLeave";
import { CallBacksType } from "../../utils/types";

export type DatasourceInfo = {
    readonly id: string;
    readonly originalName: string;
    readonly displayName?: string;
    readonly description?: string;
    readonly mapTemplateIds: readonly string[];
};

export type ManageDatasetInfoProps = {
    readonly initialState: Omit<DatasourceInfo, "mapTemplateIds">;
    readonly onRemove: () => void;
    readonly onSubmit: (
        input: DatasourceInfo,
        callbacks?: CallBacksType<DataSource>
    ) => void;
    readonly mapTemplates: readonly MapTemplate[];
    readonly mapTemplateDatasets: readonly MapTemplateDataset[];
    readonly saveLoading?: boolean;
    readonly removeLoading?: boolean;
};

export const ManageDatasetInfo = ({
    initialState,
    onRemove,
    onSubmit,
    saveLoading,
    removeLoading,
    mapTemplates,
    mapTemplateDatasets,
}: ManageDatasetInfoProps) => {
    const [datasourceMapTemplatesIds, setDatasourceMapTemplatesIds] = useState(
        pipe(
            mapTemplateDatasets,
            toNonReadonlyArray,
            A.map((mtd) => mtd.fkMapTemplateId)
        )
    );

    const initialValues = {
        ...initialState,
        mapTemplateIds: pipe(
            mapTemplates,
            toNonReadonlyArray,
            A.map((d) => `${d.id}`)
        ),
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values, {
                    onSuccess: () => resetForm(),
                });
            }}
            validationSchema={toFormikValidationSchema(
                z.object({
                    id: z.string(),
                    displayName: z.string().optional(),
                    description: z.string().optional(),
                    mapTemplateIds: z.string().array(),
                })
            )}
            validateOnMount
            enableReinitialize
        >
            {({ values, dirty, isValid, setFieldValue, handleSubmit }) => {
                const handleCheckUnCheck = (ids: string[]) => {
                    setFieldValue("mapTemplateIds", [...ids]);
                    setDatasourceMapTemplatesIds(ids.map(Number));
                };
                return (
                    <OnPageLeave
                        trigger={!isEqual(values, initialValues)}
                        save={({ navigate }) =>
                            onSubmit(values, {
                                onSuccess: () => navigate(),
                            })
                        }
                    >
                        <Stack gap={4} width={"100%"}>
                            <ThumbnailAvatar
                                src="https://biggeo.blob.core.windows.net/media/sf.png"
                                sx={{
                                    borderRadius: (theme) =>
                                        theme.radius.default,
                                    border: (theme) =>
                                        `1px solid ${theme.palette.background.main}`,
                                    backgroundColor: (theme) =>
                                        theme.palette.primary.container,
                                }}
                                size="lg"
                                square
                            />
                            <Stack>
                                <Typography
                                    variant="body3"
                                    fontWeight="regular"
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.disabled.onContainer,
                                    }}
                                >
                                    Original name
                                </Typography>
                                <Typography variant="body2" fontWeight="bold">
                                    {initialState.originalName}
                                </Typography>
                            </Stack>
                            <TextField
                                name="name"
                                placeholder="Display name"
                                label="Display name"
                                subLabel="(Optional)"
                                fullWidth
                                value={values.displayName}
                                onChange={(_, v) =>
                                    setFieldValue("displayName", v)
                                }
                                disabled={
                                    saveLoading === true ||
                                    removeLoading === true
                                }
                            />
                            <DescriptionTextField
                                disabled={
                                    saveLoading === true ||
                                    removeLoading === true
                                }
                                value={values.description}
                                onChange={(_, v) =>
                                    setFieldValue("description", v)
                                }
                            />
                            <Stack>
                                <Typography variant="body2" fontWeight="bold">
                                    Make Available On Map Templates
                                </Typography>
                                <Typography
                                    variant="body3"
                                    color="disabled"
                                    colorSet="container"
                                    invertColors
                                >
                                    Would you like any of these map templates to
                                    use this dataset?
                                </Typography>
                            </Stack>
                            <MultiSelectorAccordion
                                expanded
                                accordionLabel="Map Templates"
                                disabled={removeLoading}
                                dataList={pipe(
                                    mapTemplates,
                                    toNonReadonlyArray,
                                    A.map((mt) => ({
                                        id: `${mt.id}`,
                                        title: mt.name,
                                        image: mt.thumbnail || "",
                                        select: includes(
                                            datasourceMapTemplatesIds,
                                            mt.id
                                        ),
                                    }))
                                )}
                                onChange={(selectedIds) => {
                                    handleCheckUnCheck(selectedIds);
                                }}
                                orderItems={{ title: "Name" }}
                                avatarType="thumbnail"
                            />

                            <StickyFabPlacementHelper
                                sx={{
                                    gap: 4,
                                    paddingY: 4,
                                    backgroundColor: (theme) =>
                                        theme.palette.background.container,
                                }}
                            >
                                <SubmittingButton
                                    variant="outlined"
                                    color="error"
                                    onClick={onRemove}
                                    disabled={saveLoading}
                                    loading={removeLoading}
                                    type="removing"
                                >
                                    Remove
                                </SubmittingButton>
                                <SubmittingButton
                                    type="saving"
                                    loading={saveLoading}
                                    disabled={
                                        !isValid || !dirty || removeLoading
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    Save Changes
                                </SubmittingButton>
                            </StickyFabPlacementHelper>
                        </Stack>
                    </OnPageLeave>
                );
            }}
        </Formik>
    );
};
