import {
    DatabaseType,
    DbColumnType,
    FilterData,
    FilterObject,
    FilterType,
    LogicOperator,
    SavedViewFilters,
    SchemaRow,
    ShapeStyle,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui/lab";
import {
    WithPartialValues,
    WithRequiredProperty,
    areValuesEmpty,
    isObjectNotEmpty,
} from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import every from "lodash/every";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import some from "lodash/some";
import uniqWith from "lodash/uniqWith";
import { useState } from "react";
import uuid from "react-uuid";
import { match } from "ts-pattern";
import Zod from "zod";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { heatmapSwatchOptions } from "../../../common/components/HeatMapColorMapper";
import {
    MapAction,
    MapContextDataset,
    MapContextFilter,
} from "../../mapbox/context";
import { DEFAULT_SHAPE_COLOR } from "../../mapbox/hooks/style-hooks";
import {
    getHeatmapFormattedValue,
    setDatasetVisibility,
} from "../../mapbox/utils/data-layers-utils";
import { setFilterVisibility } from "../../mapbox/utils/filtered-data-layers-utils";
import { MapShapeColorType } from "../../views/MapShapeLayerStyles";

// ███████╗███╗   ██╗██╗   ██╗███╗   ███╗
// ██╔════╝████╗  ██║██║   ██║████╗ ████║
// █████╗  ██╔██╗ ██║██║   ██║██╔████╔██║
// ██╔══╝  ██║╚██╗██║██║   ██║██║╚██╔╝██║
// ███████╗██║ ╚████║╚██████╔╝██║ ╚═╝ ██║
// ╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝     ╚═╝

export enum MapFilterCriteriaSection {
    details = "details",
    filterCriteria = "filterCriteria",
    styles = "styles",
}

export enum FilterCriteriaDataAggregationDensity {
    heatmap = "heatmap",
    cluster = "cluster",
}

//  ██████╗ ██████╗ ███╗   ██╗███████╗████████╗ █████╗ ███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ███████║██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██║██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝

export const FILTERED_DATA_TABLE_LIMIT = 4;
export const VIEW_DATASET_TABLE_LIMIT = 20;

export const DEFAULT_LOGIC_OPERATOR = LogicOperator.and;

export const mapFilterCriteriaOperators: MapFilterCriteriaOperators = {
    [FilterType.BooleanType]: [
        WhereOperator.equals,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.StringType]: [
        WhereOperator.equals,
        WhereOperator.contains,
        WhereOperator.startsWith,
        WhereOperator.endsWith,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.NumberType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.DateType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
};

// ████████╗██╗   ██╗██████╗ ███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝
//    ██║      ██║   ██║     ███████╗
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝

export type MapFilterCriteriaForm = {
    details: MapFilterCriteriaDetails;
    filterCriteria: MapFilterCriteriaDataset[];
    styles: MapFilterCriteriaStyle;
};

export type MapFilterCriteriaDetails = {
    readonly name: string;
    readonly description?: string;
};

export type MapFilterCriteriaDataset = {
    readonly mapTemplateDatasetId: number;
    readonly dataSourceId: string;
    readonly label: string;
    readonly collection: string;
    readonly filters: Partial<MapFilterCriteriaDatasetItem>[];
    readonly logicOperator?: LogicOperator;
};

export type MapFilterCriteriaDatasetItem = {
    readonly column: string;
    readonly type: FilterType;
    readonly operator: WhereOperator;
    readonly value?: string;
};

export type MapFilterCriteriaStyle = {
    shape: ShapeStyle;
    fill: MapShapeColorType;
    stroke: MapShapeColorType;
    customMarker: string;
    dataAggregation: MapFilterCriteriaStyleDataAggregation;
};

export type MapFilterCriteriaStyleDataAggregation = Partial<{
    heatmap: ColorSwatchOption;
    cluster: ColorSwatchOption; // TODO
}>;

export type MapFilterCriteriaOperators = {
    [T in FilterType]: Array<WhereOperator>;
};

// ███████╗ ██████╗██╗  ██╗███████╗███╗   ███╗ █████╗
// ██╔════╝██╔════╝██║  ██║██╔════╝████╗ ████║██╔══██╗
// ███████╗██║     ███████║█████╗  ██╔████╔██║███████║
// ╚════██║██║     ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══██║
// ███████║╚██████╗██║  ██║███████╗██║ ╚═╝ ██║██║  ██║
// ╚══════╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝

export const mapFilterCriteriaSchema = Zod.object({
    details: Zod.object({
        name: Zod.string(),
        description: Zod.string().optional(),
    }),
    filterCriteria: Zod.array(
        Zod.object({
            mapTemplateDatasetId: Zod.number(),
            dataSourceId: Zod.string(),
            label: Zod.string(),
            collection: Zod.string(),
            logicOperator: Zod.nativeEnum(LogicOperator).optional(),
            filters: Zod.array(
                Zod.object({
                    column: Zod.string(),
                    type: Zod.nativeEnum(FilterType),
                    operator: Zod.nativeEnum(WhereOperator),
                    value: Zod.string(),
                })
            ),
        })
    ).nonempty(),
    styles: Zod.object({
        shape: Zod.nativeEnum(ShapeStyle).optional(),
        fill: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        stroke: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        customMarker: Zod.string().optional(),
        dataAggregation: Zod.object({
            heatmap: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
            cluster: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
        }).optional(),
    }).optional(),
});

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝

export const mapFilterCriteriaSign = (operator: WhereOperator): string =>
    match(operator)
        .with(WhereOperator.eq, () => "=")
        .with(WhereOperator.neq, () => "!=")
        .with(WhereOperator.gt, () => ">")
        .with(WhereOperator.gte, () => ">=")
        .with(WhereOperator.lt, () => "<")
        .with(WhereOperator.lte, () => "<=")
        .with(WhereOperator.isEmpty, () => "Is Empty")
        .with(WhereOperator.isNotEmpty, () => "Is Not Empty")
        .with(WhereOperator.startsWith, () => "Starts With")
        .with(WhereOperator.endsWith, () => "Ends With")
        .with(WhereOperator.equals, () => "Equals")
        .otherwise(() => "Contains");

export const getFilterCriteriaOperators = (type: FilterType) =>
    mapFilterCriteriaOperators[type];

export const mapColumnType = (type: DbColumnType): FilterType =>
    match(type)
        .with(DbColumnType.Boolean, () => FilterType.BooleanType)
        .with(DbColumnType.Date, () => FilterType.DateType)
        .with(DbColumnType.Number, () => FilterType.NumberType)
        .otherwise(() => FilterType.StringType);

export const mapFilterData = (type: FilterType, value?: string): FilterData => {
    return omitBy(
        {
            booleanData:
                isEqual(type, FilterType.BooleanType) && value
                    ? value === "true"
                    : undefined,
            dateData:
                isEqual(type, FilterType.DateType) && value
                    ? new Date(value)
                    : undefined,
            numberData:
                isEqual(type, FilterType.NumberType) && value
                    ? Number.parseInt(value)
                    : undefined,
            stringData:
                isEqual(type, FilterType.StringType) && value
                    ? value
                    : undefined,
        },
        isNil
    );
};

export const mapfilterCriteriaToMultifilter = (
    filterCriteria: MapFilterCriteriaDataset
): FilterObject => {
    const filters = pipe(
        filterCriteria.filters,
        compact,
        A.map((item) => {
            const e = item as MapFilterCriteriaDatasetItem;

            return {
                column: e.column,
                operator: e.operator,
                type: e.type,
                data: mapFilterData(e.type, e.value),
            };
        }),
        A.map((item) => (areValuesEmpty(item) ? undefined : item)),
        compact
    );

    return {
        collection: filterCriteria.collection,
        databaseId: filterCriteria.dataSourceId,
        databaseType: DatabaseType.point,
        filters,
        logicOperator: filterCriteria.logicOperator ?? DEFAULT_LOGIC_OPERATOR,
    };
};

export const mapToMultifilterType = (
    filter: MapFilterCriteriaForm
): FilterObject[] => {
    return pipe(filter.filterCriteria, A.map(mapfilterCriteriaToMultifilter));
};

export const mapFilterCriteriaInitialValues = (
    filter?: MapContextFilter
): WithPartialValues<MapFilterCriteriaForm> => {
    // Defaults to primary
    const color = DEFAULT_SHAPE_COLOR;
    const heatmap = heatmapSwatchOptions[1];

    return {
        details: {
            name: filter ? filter.details.name : undefined,
            description: filter ? filter.details.description : undefined,
        },
        filterCriteria: filter?.filterCriteria || [],
        styles: {
            shape: filter ? filter.styles.shape : ShapeStyle.oval,
            fill: filter
                ? filter.styles.fill
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            stroke: filter
                ? filter.styles.stroke
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            customMarker: filter ? filter.styles.customMarker : undefined,
            dataAggregation: {
                heatmap: filter?.styles.dataAggregation.heatmap ?? heatmap,
                cluster: filter
                    ? filter.styles.dataAggregation.cluster
                    : undefined,
            },
        },
    };
};

export const clearMultifilters = (
    selectedDatasets: MapContextDataset[],
    setMultiFilters: (mf: FilterObject[]) => void,
    map: React.MutableRefObject<mapboxgl.Map | null>,
    isLoaded: boolean
) => {
    setMultiFilters(
        pipe(
            selectedDatasets,
            A.map((f) => ({
                collection: f.dataSource.collectionName,
                databaseId: f.dataSource.id,
                databaseType: DatabaseType.point,
                filters: [],
                logicOperator: LogicOperator.and,
            }))
        )
    );

    updateDatasetsVisibility({ map, isLoaded, selectedDatasets });
};

export const getUpdatedMultifiters = (input: {
    filter: MapContextFilter;
    selectedDatasets: MapContextDataset[];
    multiFilters: FilterObject[];
}) => {
    const { filter, selectedDatasets, multiFilters } = input;

    const datasets = pipe(
        filter.filterCriteria,
        A.map((f) => f.dataSourceId)
    );

    return pipe(
        selectedDatasets,
        A.map((f) => {
            const found = multiFilters.find(
                (m) => m.databaseId === f.dataSource.id
            );
            const defaultFilters = found?.filters || [];

            return {
                collection: f.dataSource.collectionName,
                databaseId: f.dataSource.id,
                databaseType: DatabaseType.point,
                filters: datasets.includes(f.dataSource.id)
                    ? []
                    : defaultFilters,
                logicOperator: LogicOperator.and,
            };
        })
    );
};

export const getSnowflakeTableColumns = (
    columns: SchemaRow[]
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): GridColDef<any>[] => {
    return columns.reduce((acc, c) => {
        if (acc.some((col) => col.field === c.columnName)) return acc;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const v: GridColDef<any> = {
            type: match(c.dataType)
                .with(DbColumnType.String, () => "string")
                .with(DbColumnType.Boolean, () => "boolean")
                .with(DbColumnType.Number, () => "number")
                .with(DbColumnType.Date, () => "string")
                .otherwise(() => "string"),
            field: c.columnName,
            headerName: c.columnName,
            filterable: c.columnName !== "ID",
            valueGetter: (params) => {
                if (c.dataType === DbColumnType.Date) {
                    return `${params.value}`;
                }
                return params.value;
            },
            sortable: false,
            headerAlign: "left",
            align: "left",
            minWidth: match(c.columnName.length * 13)
                .when(
                    (v) => v > 200,
                    () => 200
                )
                .when(
                    (v) => v < 50,
                    () => 50
                )
                .otherwise((v) => v),
        };
        return acc.concat(v);
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    }, [] as GridColDef<any>[]);
};

const updateDatasetsVisibility = (input: {
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    selectedDatasets: MapContextDataset[];
}) => {
    const { selectedDatasets, map, isLoaded } = input;

    pipe(
        selectedDatasets,
        A.map((dataset) => {
            if (map.current) {
                setDatasetVisibility({
                    map: map.current,
                    prefix: dataset.dataSource.id,
                    levelSets: [],
                    visibility: "visible",
                    isLoaded,
                    points: {
                        shape: dataset?.dataSource.icon
                            ? undefined
                            : dataset?.mapTemplateDataset?.shape || undefined,
                        customMarker: dataset?.dataSource.icon || undefined,
                    },
                });
            }
        })
    );
};

export const setFilterCriteria = ({
    dispatch,
    multiFilters,
    setMultiFilters,
    filters,
    selectedDatasets,
    setOpenRightSlot,
}: {
    dispatch?: React.Dispatch<MapAction>;
    setMultiFilters: (mf: FilterObject[]) => void;
    multiFilters: FilterObject[];
    filters: MapContextFilter[];
    selectedDatasets: MapContextDataset[];
    setOpenRightSlot: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [openFilterCriteria, setOpenFilterCriteria] =
        useState<boolean>(false);
    const canAddFilterCriteria = !isEmpty(selectedDatasets);
    const canViewFilteredDataTable = Boolean(
        filters.find((f) => isEqual(f.viewed, true))
    );

    const handleFilterCriteriaSidebar = (e?: boolean) => {
        isBoolean(e)
            ? setOpenFilterCriteria(e)
            : setOpenFilterCriteria(!openFilterCriteria);

        setOpenRightSlot(false);
    };

    const setFilters = (f: MapContextFilter[]) => {
        dispatch?.({
            type: "SET_FILTERS",
            values: f,
        });
    };

    const addFilter = (filter: MapFilterCriteriaForm) => {
        dispatch?.({
            type: "ADD_FILTER",
            values: {
                ...filter,
                id: uuid(),
                visible: true,
                selected: false,
                disabled: false,
            },
        });
    };

    const updateFilter = (
        filter: WithRequiredProperty<Partial<MapContextFilter>, "id">
    ) => {
        dispatch?.({
            type: "UPDATE_FILTER",
            values: {
                id: filter.id,
                ...omitBy(filter, isNil),
                viewed: filter.viewed,
            },
        });
    };

    const toggleFilterVisibility = (
        id: string,
        visible: boolean,
        map: React.MutableRefObject<mapboxgl.Map | null>,
        isLoaded: boolean
    ) => {
        updateFilter({ id, visible });

        const filter = filters.find((f) => f.id === id);

        if (filter && isEqual(visible, true)) {
            setMultiFilters(
                uniqWith(
                    pipe(
                        multiFilters,
                        A.concat(mapToMultifilterType(filter)),
                        A.filter((f) => !isEmpty(f.filters))
                    ),
                    isEqual
                )
            );
        }

        if (filter && isEqual(visible, false)) {
            const hasOtherVisibleFilters = !pipe(
                filters,
                A.filter((f) => !isEqual(f.id, id)),
                A.filter((f) => isEqual(f.visible, true)),
                isEmpty
            );

            if (!hasOtherVisibleFilters) {
                // Reset to initial default where only the dataset was selected with no filters.
                clearMultifilters(
                    selectedDatasets,
                    setMultiFilters,
                    map,
                    isLoaded
                );
            } else {
                setMultiFilters(
                    uniqWith(
                        pipe(
                            multiFilters,
                            A.filter(
                                (f) =>
                                    !isEqual(f, mapToMultifilterType(filter)[0])
                            )
                        ),
                        isEqual
                    )
                );
            }
        }
    };

    const removeFilter = (input: {
        id: string;
        map: React.MutableRefObject<mapboxgl.Map | null>;
        isLoaded: boolean;
    }) => {
        const { id, map, isLoaded } = input;
        const filter = filters.find((f) => f.id === id);
        const updatedFilters = filters.filter((f) => f.id !== id);

        if (filter) {
            setMultiFilters(
                getUpdatedMultifiters({
                    multiFilters,
                    selectedDatasets,
                    filter,
                })
            );
        }

        setFilterVisibility({
            visibility: "none",
            map,
            isLoaded,
            suffix: id,
        });

        dispatch?.({
            type: "REMOVE_FILTER",
            values: id,
        });

        handleFilterCriteriaSidebar(false);

        if (isEmpty(updatedFilters)) {
            updateDatasetsVisibility({ map, isLoaded, selectedDatasets });
        }
    };

    const clearFilters = (
        map: React.MutableRefObject<mapboxgl.Map | null>,
        isLoaded: boolean
    ) => {
        dispatch?.({
            type: "CLEAR_FILTERS",
        });

        clearMultifilters(selectedDatasets, setMultiFilters, map, isLoaded);

        filters.map((filter) =>
            setFilterVisibility({
                visibility: "none",
                map,
                isLoaded,
                suffix: filter.id,
            })
        );
    };

    const editFilter = (id: string) => {
        const filter = filters.find((f) => f.id === id);

        dispatch?.({
            type: "EDIT_FILTER",
            values: id,
        });
        handleFilterCriteriaSidebar(true);

        if (filter) {
            setMultiFilters(mapToMultifilterType(filter));
        }
    };

    const saveFilter = (id: string) => {
        dispatch?.({
            type: "SAVE_FILTER",
            values: id,
        });
        handleFilterCriteriaSidebar(false);
    };

    const onAddFilter = (
        map: React.MutableRefObject<mapboxgl.Map | null>,
        isLoaded: boolean
    ) => {
        dispatch?.({
            type: "RESET_FILTERS",
            values: {
                disable: true,
            },
        });
        handleFilterCriteriaSidebar(true);
        clearMultifilters(selectedDatasets, setMultiFilters, map, isLoaded);
    };

    const handleFilterCriteria = (e?: boolean, reset?: boolean) => {
        handleFilterCriteriaSidebar(e);
        if (isEqual(reset, true)) {
            dispatch?.({
                type: "RESET_FILTERS",
                values: {
                    disable: false,
                },
            });

            if (!isEmpty(filters)) {
                setMultiFilters(
                    pipe(
                        filters,
                        A.flatMap((f) => mapToMultifilterType(f))
                    )
                );
            }
        }
    };

    return {
        openFilterCriteria,
        setOpenFilterCriteria,
        canAddFilterCriteria,
        canViewFilteredDataTable,
        handleFilterCriteriaSidebar,
        setFilters,
        addFilter,
        updateFilter,
        toggleFilterVisibility,
        removeFilter,
        clearFilters,
        editFilter,
        saveFilter,
        onAddFilter,
        handleFilterCriteria,
    };
};

export const handleFilteredDatasets = (input: {
    dataset: MapContextDataset;
    filters: MapContextFilter[];
    setMultiFilters: (mf: FilterObject[]) => void;
    multiFilters: FilterObject[];
    selectedDatasets: MapContextDataset[];
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    dispatch?: React.Dispatch<MapAction>;
    openModal: ({
        onClickClearFilters,
        onClickKeepFilters,
        filters,
    }: {
        onClickClearFilters: () => void;
        onClickKeepFilters: () => void;
        filters: string[];
    }) => void;
    closeModal: () => void;
    unselectDataset: () => void;
}) => {
    const {
        dataset,
        filters,
        setMultiFilters,
        multiFilters,
        selectedDatasets,
        map,
        isLoaded,
        dispatch,
        openModal,
        closeModal,
        unselectDataset,
    } = input;

    const linkedFilters = pipe(
        filters,
        A.filter((filter) =>
            some(
                filter.filterCriteria,
                (c) => c.dataSourceId === dataset.dataSource.id
            )
        )
    );

    if (isEmpty(linkedFilters)) {
        unselectDataset();

        setMultiFilters(
            pipe(
                selectedDatasets,
                A.map((f) => {
                    const found = multiFilters.find(
                        (m) => m.databaseId === f.dataSource.id
                    );

                    return found ? found : undefined;
                }),
                compact,
                A.filter((m) => m.databaseId !== dataset.dataSource.id)
            )
        );
    } else {
        openModal({
            filters: pipe(
                linkedFilters,
                A.map((f) => f.details.name)
            ),
            onClickKeepFilters: () => closeModal(),
            onClickClearFilters: () => {
                linkedFilters.map((filter) => {
                    setMultiFilters(
                        pipe(
                            getUpdatedMultifiters({
                                multiFilters,
                                selectedDatasets,
                                filter,
                            }),
                            A.filter(
                                (m) => m.databaseId !== dataset.dataSource.id
                            )
                        )
                    );

                    setFilterVisibility({
                        visibility: "none",
                        map,
                        isLoaded,
                        suffix: filter.id,
                    });

                    dispatch?.({
                        type: "REMOVE_FILTER",
                        values: filter.id,
                    });
                });

                setFilterVisibility({
                    visibility: "none",
                    map,
                    isLoaded,
                    suffix: "preview",
                    dataset,
                });

                closeModal();
            },
        });
    }
};

// ███████╗ █████╗ ██╗   ██╗███████╗██████╗     ██╗   ██╗██╗███████╗██╗    ██╗
// ██╔════╝██╔══██╗██║   ██║██╔════╝██╔══██╗    ██║   ██║██║██╔════╝██║    ██║
// ███████╗███████║██║   ██║█████╗  ██║  ██║    ██║   ██║██║█████╗  ██║ █╗ ██║
// ╚════██║██╔══██║╚██╗ ██╔╝██╔══╝  ██║  ██║    ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
// ███████║██║  ██║ ╚████╔╝ ███████╗██████╔╝     ╚████╔╝ ██║███████╗╚███╔███╔╝
// ╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═════╝       ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

export const getSavedViewFilters = (input: {
    filters: MapContextFilter[];
    multiFilters: FilterObject[];
}): SavedViewFilters[] => {
    const { filters, multiFilters } = input;

    return pipe(
        multiFilters,
        A.map((multifilter) => {
            const linkedFilters = pipe(
                filters,
                A.filter((f) =>
                    every(f.filterCriteria, (c) =>
                        isEqual(c.dataSourceId, multifilter.databaseId)
                    )
                )
            );

            const filterCriteria = pipe(
                linkedFilters,
                O.fromPredicate((f) => !isEmpty(f)),
                O.fold(
                    () => undefined,
                    (f) =>
                        pipe(
                            f,
                            A.map((filter) => ({
                                id: filter.id,
                                name: filter.details.name,
                                description: filter.details.description,
                                visible: filter.visible,
                                selected: filter.selected,
                                disabled: filter.disabled,
                                filters: pipe(
                                    filter.filterCriteria,
                                    A.map((i) => ({
                                        ...i,
                                        filters: pipe(
                                            i.filters,
                                            A.map((item) =>
                                                isObjectNotEmpty(item)
                                                    ? item
                                                    : undefined
                                            ),
                                            compact
                                        ),
                                    }))
                                ),
                                shape: filter.styles.shape,
                                fill: filter.styles.fill,
                                stroke: filter.styles.stroke,
                                customMarker: filter.styles.customMarker,
                                dataAggregation: {
                                    heatmap: filter.styles.dataAggregation
                                        .heatmap
                                        ? {
                                              ...filter.styles.dataAggregation
                                                  .heatmap,
                                              id: filter.styles.dataAggregation.heatmap.id.toString(),
                                          }
                                        : undefined,
                                },
                            }))
                        )
                )
            );

            return {
                ...multifilter,
                filterCriteria,
            };
        })
    );
};

export const getContextFilters = (
    savedViewFilters: SavedViewFilters[],
    datasetsExtended: MapContextDataset[]
): Partial<{
    filters: MapContextFilter[];
    multiFilters: FilterObject[];
    datasets: MapContextDataset[];
}> => {
    const [multiFilters, datasets, filters] = [
        pipe(
            savedViewFilters,
            A.map((filter) => omit(filter, ["filterCriteria"])),
            A.map((m) => ({
                collection: m.collection,
                databaseId: m.databaseId,
                databaseType: m.databaseType,
                filters: m.filters,
                isPreview: m.isPreview ?? false,
                logicOperator: m.logicOperator,
            }))
        ),
        pipe(
            savedViewFilters,
            A.map((filter) => {
                const dataset = datasetsExtended.find(
                    (d) => d.dataSource.id === filter.databaseId
                );

                return dataset
                    ? {
                          dataSource: dataset.dataSource,
                          mapTemplateDataset: dataset.mapTemplateDataset,
                          isSelected: true,
                          isVisible: true,
                          isGettingStyled: false,
                          isTableViewed: false,
                          isLegendOpen: false,
                      }
                    : undefined;
            }),
            compact
        ),
        pipe(
            savedViewFilters,
            A.flatMap((filter) => filter.filterCriteria || []),
            A.map((filterCriteria) => {
                const heatmap = getHeatmapFormattedValue(
                    filterCriteria.dataAggregation?.heatmap || undefined
                );

                return {
                    id: filterCriteria.id,
                    visible: filterCriteria.visible,
                    selected: filterCriteria.selected,
                    disabled: filterCriteria.disabled,
                    viewed: undefined,
                    details: {
                        name: filterCriteria.name,
                        description: filterCriteria.description || undefined,
                    },
                    filterCriteria: pipe(
                        filterCriteria.filters,
                        A.map((i) => ({
                            ...i,
                            filters: pipe(
                                i.filters,
                                A.map((item) => ({
                                    ...item,
                                    value: item.value || undefined,
                                }))
                            ),
                            logicOperator: i.logicOperator || undefined,
                        }))
                    ),
                    styles: {
                        shape: filterCriteria.shape || ShapeStyle.oval,
                        fill: filterCriteria.fill
                            ? {
                                  color: filterCriteria.fill.color || undefined,
                                  opacity:
                                      filterCriteria.fill.opacity || undefined,
                              }
                            : undefined,
                        stroke: filterCriteria.stroke
                            ? {
                                  color:
                                      filterCriteria.stroke.color || undefined,
                                  opacity:
                                      filterCriteria.stroke.opacity ||
                                      undefined,
                              }
                            : undefined,
                        customMarker: filterCriteria.customMarker || undefined,
                        dataAggregation: filterCriteria.dataAggregation
                            ? {
                                  heatmap,
                              }
                            : undefined,
                    } as MapFilterCriteriaStyle,
                };
            })
        ),
    ];

    return {
        multiFilters: isEmpty(multiFilters) ? undefined : multiFilters,
        filters: isEmpty(filters) ? undefined : filters,
        datasets: isEmpty(datasets) ? undefined : datasets,
    };
};
